import { useContext, useEffect, useState } from "react";
import useInView from "react-cool-inview";
import DataContext from "../../contexts/DataContext";
import LayoutContext from "../../contexts/LayoutContext";

const Info = (props) => {
  const commonData = useContext(DataContext)
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [lines, setLines] = useState();
  const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
  const observeAvatar = useInView({
    unobserveOnEnter: true,
  });
  const observeInfo = useInView({
    unobserveOnEnter: true,
  });
  const { isOverlayVisible, setIsOverlayVisible } = useContext(LayoutContext);
  const _toggleReadMore = () => {
    setIsReadMoreVisible(!isReadMoreVisible);
  };
  return (
    <div className="artist-info">
      <div className="row">
        <div
          className={`col-12 col-md-auto d-flex d-md-block align-items-center justify-content-center flex-wrap ${observeAvatar.inView
            ? "animate__animated animate__fadeIn"
            : "no-opacity"
            }`}
          ref={observeAvatar.observe}
        >
          <div className="img-container">
            <img src={props.data.formatted_inner_image} />
          </div>
          <div className="social  w-100">
            <ul className="list-unstyled d-flex m-0 p-0 justify-content-center">
              {props.data.facebook ? (
                <li>
                  <a href={props.data.facebook} target="_blank">
                    <img src="/images/facebook.svg" />
                  </a>
                </li>
              ) : (
                ""
              )}
              {props.data.instagram ? (
                <li>
                  <a href={props.data.instagram} target="_blank">
                    <img src="/images/instagram.svg" />
                  </a>
                </li>
              ) : (
                ""
              )}{" "}
              {props.data.twitter ? (
                <li>
                  <a href={props.data.twitter} target="_blank">
                    <img src="/images/twitter.svg" />
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
        <div
          className={`col ${observeAvatar.inView
            ? "animate__animated animate__fadeIn"
            : "no-opacity"
            }`}
          ref={observeInfo.observe}
        >
          <h3 className="font-weight-medium">{props.data.name}</h3>
          <div className="description-container">
            <div
              className="short-description"
              dangerouslySetInnerHTML={{ __html: props.data.short_description }}
            ></div>
            <div
              className={`long-description ${isReadMoreVisible ? "expand" : ""}`}
              dangerouslySetInnerHTML={{ __html: props.data.long_description }}
            ></div>
            {props.data.long_description && !isReadMoreVisible ? (
              <a
                className="d-inline-block w-100 read-more cursor-pointer"
                onClick={_toggleReadMore}
              >
                {commonData.fixed.artists_titles['read-more'].title} <img src="/images/search-arrow.svg" />
              </a>
            ) : (
              ""
            )}
          </div>
          {/* <div className="d-block d-md-none ">
            <TextTruncate
              line={lines ? lines : "13"}
              element="div"
              text={props.data.text}
              truncateText="..."
              textTruncateChild={
                <div
                  className="d-inline-block w-100 read-more cursor-pointer"
                  onClick={_handleLoadMore}
                >
                  Seguir Leyendo <img src="/images/search-arrow.svg" />
                </div>
              }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Info;

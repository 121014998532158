import Layout from "../components/Accounts/Layout";
import Input from "../components/Input/Input";
import { useState, useContext, useEffect } from "react";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";
// import { Checkbox } from "@material-ui/core";
import Checkbox from "../components/Input/Checkbox";
import Button from "../components/Button";
import { ME } from "../api";

const Profile = () => {
    const commonData = useContext(DataContext);
    const [
        submitResponse,
        submitError,
        submitLoader,
        submitSetHookOptions,
        submitSnackbar,
        submitStatus,
    ] = useAxios();
    const [
        response,
        error,
        loader,
        setHookOptions,
        snackbar,
        status,
    ] = useAxios();
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        newsletter: commonData.fixed.user.subscribed_to_newsletter
    });
    const _handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const _handleCheck = (name) => {
        setFormData({ ...formData, [name]: !formData[name] });
    }
    const _handleSubmit = (e) => {
        e.preventDefault();
        submitSetHookOptions({
            method: "POST",
            data: formData,
            url: ME
        })
    }
    useEffect(() => {
        setHookOptions({
            url: ME
        })
    }, []);
    useEffect(() => {
        if (status == 200) {
            setFormData({
                name: response.user.name,
                company: response.user.company,
                email: response.user.email,
                newsletter: response.user.subscribed_to_newsletter==1
            })
        }
    }, [loader]);
    return <Layout active="information">
        {status == 200 ?
            <form className="row" id="profile" onSubmit={_handleSubmit}>
                <div className="input-container col-md-6">
                    <Input
                        onChange={_handleChange}
                        name="name"
                        placeholder={commonData.fixed.account_titles["name"].title}
                        type="text"
                        error={submitError ? submitError : {}}
                        value={formData.name}
                    />
                </div>
                <div className="input-container col-md-6">
                    <Input
                        onChange={_handleChange}
                        name="company"
                        placeholder={commonData.fixed.account_titles["company"].title}
                        type="text"
                        error={submitError ? submitError : {}}
                        value={formData.company}
                    />
                </div>
                <div className="input-container col-md-6">
                    <Input
                        onChange={_handleChange}
                        name="email"
                        placeholder={commonData.fixed.account_titles["email"].title}
                        type="text"
                        error={submitError ? submitError : {}}
                        value={formData.email}
                    />
                </div>
                <div className="input-container col-12 rich-text-wrapper" dangerouslySetInnerHTML={{ __html: commonData.fixed.account_titles["information-text"].content }}>

                </div>
                <div className="col-12">
                    <Checkbox
                        checked={formData.newsletter}
                        label={

                            commonData.fixed.account_titles["subscribe"].title
                        }
                        name="newsletter"
                        error={error}
                        onCheck={() => {
                            _handleCheck("newsletter");
                        }}
                    />
                </div>
                <div className="col-12 button-container">
                    <Button loading={submitLoader} type="submit" className="btn-outline-secondary" text={commonData.fixed.account_titles["save"].title}></Button>
                </div>
            </form> :
            <div className="text-center">{loader}</div>
        }
    </Layout >
}
export default Profile;
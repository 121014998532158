import { useRef } from "react";
import useInView from "react-cool-inview";
import { useHistory } from "react-router-dom";

const Button = (props) => {
  const observableLoadMore = useInView({
    unobserveOnEnter: true,
  });
  const history = useHistory();
  const ref = useRef();
  const _handleClick = (e) => {
    if (props.to) {
      history.push(props.to);
    } else if (props.onClick) {
      e.preventDefault();
      props.onClick();
    }
  };
  return (
    <div
      className={`text-center button-container  ${observableLoadMore.inView
          ? "animate__animated animate__fadeIn"
          : "no-opacity"
        }`}
      ref={observableLoadMore.observe}
    >
      <button
        className={`${props.className
          } btn btn-outline-primary font-weight-regular  ${props.loading ? "loading" : ""
          }`}
        onClick={_handleClick}
        type={props.type}
        disabled={props.disabled}
      >
        <div
          className={`spinner-border ${props.loading ? "" : "d-none"}`}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        {props.text}
      </button>
      <span ref={ref} onClick={() => null} />
    </div>
  );
};
export default Button;

import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { CART, SINGLE_PRODUCT } from "../api";
import Breadcrumbs from "../components/Breadcrumbs";
import Dropdown from "../components/Products/Dropdown";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";
import Button from "../components/Button";
import GallerySwiper from "../components/Products/GallerySwiper";
import Accordion from "../components/Products/Accordion";
import ProductGridElement from "../components/Products/ProductGridElement";
import useInView from "react-cool-inview";
import { useCookies } from "react-cookie";
import LayoutContext from "../contexts/LayoutContext";
import { useHistory } from "react-router-dom";
import { getCart } from "../helper";

const SingleProduct = (props) => {
  const routeParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const { isOverlayVisible, setIsOverlayVisible, setIsCartOpen } =
    useContext(LayoutContext);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const [
    addToCartResponse,
    addToCartError,
    addToCartLoader,
    addToCartSetHookOptions,
    addToCartSnackbar,
    addToCartStatus,
  ] = useAxios();
  const [priceIndex, setPriceIndex] = useState(0);
  const [selectedDropdownIndexes, setSelectedDropdownIndexes] = useState({
    frame: 0,
    dimension: 0,
  });
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState();
  const params = useParams();
  const commonData = useContext(DataContext);
  const observeDescription = useInView({
    unobserveOnEnter: true,
  });
  const observeButtons = useInView({
    unobserveOnEnter: true,
  });
  const observeSimilarProducts = useInView({
    unobserveOnEnter: true,
  });
  useEffect(() => {
    setHookOptions({
      url: SINGLE_PRODUCT(routeParams['category'], routeParams['subcategory'], routeParams["slug"]),
    });
  }, []);
  useEffect(() => {
    setHookOptions({
      url: SINGLE_PRODUCT(routeParams['category'], routeParams['subcategory'], routeParams["slug"]),
    });
  }, [routeParams["slug"]]);
  useEffect(() => {
    if (status == 200) {
      setPriceIndex(findMinPrice());
      setSelectedDropdownIndexes({
        frame: findMinPrice(),
        dimension: findMinPrice(),
      });
      // setPrice(response.product.price[findMinPrice()].price);
    }
  }, [status]);
  useEffect(() => {
    if (status == 200) {
      setPriceIndex(
        response.product.price.findIndex(
          (el) =>
            el.frame ==
            response.product.price[selectedDropdownIndexes.frame].frame &&
            el.width ==
            response.product.price[selectedDropdownIndexes.dimension].width &&
            el.height ==
            response.product.price[selectedDropdownIndexes.dimension].height
        )
      );
    }
  }, [selectedDropdownIndexes]);
  useEffect(() => {
    setIsOverlayVisible(isLoginPopupVisible);
  }, [isLoginPopupVisible]);
  useEffect(() => {
    if (!isOverlayVisible) setIsLoginPopupVisible(isOverlayVisible);
  }, [isOverlayVisible]);
  useEffect(() => {
    if (addToCartStatus == 200 && !addToCartLoader) {
      //if we added again a product already in the cart remove it from the array then add the updated response
      const cartItems = commonData.cartItems.filter(
        (el) => addToCartResponse.id !== el.id
      );
      commonData.setCartItems(addToCartResponse, ...commonData.cartItems);
      setIsCartOpen(true);
    }
  }, [addToCartLoader]);
  const findMinPrice = () => {
    let minIndex = -1;
    let min = 999999999999999;
    response.product.price.map((price, index) => {
      if (price.price < min) {
        min = price.price;
        minIndex = index;
      }
    });
    return minIndex;
  };

  const _handleCustomize = () => {
    history.push(`${location.pathname}/customize?dimensionIndex=${selectedDropdownIndexes.dimension}`);
  }
  const _handleDropdownChange = (key, index) => {
    setSelectedDropdownIndexes({
      frame: selectedDropdownIndexes.frame,
      dimension: index
    });
  }
  // const _handleAddToCart = () => {
  //   addToCartSetHookOptions({
  //     url: CART,
  //     method: "post",
  //     data: {
  //       product_slug: params.slug,
  //       price_id: response.product.price[priceIndex].id,
  //     },
  //   });
  // else {
  //   // setIsLoginPopupVisible(true);
  //   //if we added again a product already in the cart remove it from the array then add the updated response
  //   const product = { ...response.product };

  //   const item = commonData.cartItems.find((el) => product.id == el.id);
  //   const cartItems = commonData.cartItems.filter(
  //     (el) => product.id !== el.id
  //   );
  //   product.product_price = product.price[0];
  //   // product.product_price.product = product;
  //   product.quantity = item && item.quantity ? item.quantity + 1 : 1;
  //   commonData.setCartItems([product, ...cartItems]);
  //   setIsCartOpen(true);
  // }
  // };
  const _handleClose = () => {
    setIsLoginPopupVisible(false);
  };
  const renderBreadcrumbs = () => {
    if (location.state?.from == 'product')
      return <div className="custom-container">
        <Breadcrumbs
          data={[
            { name: commonData.fixed.breadcrumbs['home'].title, url: "/" },
            { name: commonData.fixed.breadcrumbs['product'].title, url: "/productos" },
            {
              name: response.category.name,
              url: `/productos/${response.category.slug}`,
            },
            {
              name: response.subcategory.name,
              url: `/productos/${response.subcategory.slug}`,
            },
            { name: response.product.name, url: location.pathname },
          ]}
        />
      </div>
    if (location.state?.from == "artist")
      return <div className='custom-container'>
        <Breadcrumbs
          data={[
            { name: commonData.fixed.breadcrumbs['home'].title, url: "/" },
            { name: commonData.fixed.breadcrumbs['artistas'].title, url: "/artistas" },
            {
              name: response.product.artist.name,
              url: `/artistas/${response.product.artist.slug}`,
            },
            { name: response.product.name },
          ]}
        />
      </div>
    else
      return <div className="custom-container">
        <Breadcrumbs
          data={[
            { name: commonData.fixed.breadcrumbs['home'].title, url: "/" },
            { name: commonData.fixed.breadcrumbs['inspiration'].title, url: "/inspiracion" },
            { name: response.product.name },
          ]}
        />
      </div>
  }
  return status == 200 ? (
    <div id="single-product">
      {/* <div className={`p-3 guest-login ${isLoginPopupVisible ? "active" : ""}`}>
        <div>
          <img
            className="close cursor-pointer"
            src="/images/close.svg"
            onClick={_handleClose}
          />
        </div>
        <div className="p-5">
          <h4 className="font-weight-medium">
            You must login to add products to cart
          </h4>{" "}
          <div>
            <Link to="/accesso" className="go-to-login d-inline-block">
              Go to login <img src="/images/search-arrow.svg" />
            </Link>
          </div>
        </div>
      </div> */}
      <div className="custom-container-wrapper">
        {renderBreadcrumbs()}
        <div className="custom-container">
          <div className="row">
            <div className="col-12 order-2 order-lg-1 d-lg-block col-lg-5 info-col">
              <div className="content-wrapper">
                <h2
                  className={`f-24 font-weight-medium ${observeDescription.inView
                    ? "animate__animated animate__fadeIn"
                    : "no-opacity"
                    }`}
                  ref={observeDescription.observe}
                >
                  {response.product.name}
                </h2>
                <div
                  className={`prices ${observeDescription.inView
                    ? "animate__animated animate__fadeIn"
                    : "no-opacity"
                    }`}
                >
                  <span
                    className={`price ${response.product.discount && priceIndex != -1
                      ? "strikethrough"
                      : ""
                      }`}
                  >

                    {priceIndex != -1
                      ? response.product.price[priceIndex].price
                        .toString()
                        .replace(".", ",")
                      : "Out Of Stock"}
                    <span className="font-family-lucida currency">
                      {priceIndex != -1 ? "€" : ""}
                    </span>
                  </span>
                  {response.product.discount && priceIndex != -1 ? (
                    <>
                      <span
                        className={`discounted-price ${observeDescription.inView
                          ? "animate__animated animate__fadeIn"
                          : "no-opacity"
                          }`}
                      >
                        {(
                          response.product.price[priceIndex].price -
                          (response.product.price[priceIndex].price *
                            response.product.discount) /
                          100
                        )
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")}
                        <span className="font-family-lucida currency">€</span>
                      </span>
                      <span
                        className={`discount ${observeDescription.inView
                          ? "animate__animated animate__fadeIn"
                          : "no-opacity"
                          }`}
                      >
                        (-{response.product.discount}
                        <img src="/images/discount-percent-sign.svg" />)
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={` d-md-block rich-text-wrapper ${observeDescription.inView
                    ? "animate__animated animate__fadeIn"
                    : "no-opacity"
                    }`}
                  dangerouslySetInnerHTML={{ __html: response.product.text }}
                ></div>
                <div
                  ref={observeButtons.observe}
                  className={`row ${observeButtons.inView
                    ? "animate__animated animate__fadeIn"
                    : "no-opacity"
                    }`}
                >

                  <div className="col-6">
                    <Dropdown
                      placeholder={commonData.fixed.shop_titles["dimension"]}
                      data={response.product.price}
                      selectedIndex={selectedDropdownIndexes.dimension}
                      render={(el) => {
                        return el.width + " x " + el.height;
                      }}
                      name="dimension"
                      onChange={_handleDropdownChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <Button
                      text={commonData.fixed.shop_titles["add-to-cart"]}
                      className={"btn-secondary w-100 m-auto"}
                      onClick={_handleCustomize}
                    />
                  </div>
                </div>
                {/* <div className="d-block d-md-none accordion-container">
                  <Accordion
                    data={[
                      {
                        title: commonData.fixed.shop_titles["description"],
                        content: response.product.description,
                      },
                      {
                        title: commonData.fixed.shop_titles["information"],
                        content: response.product.information,
                      },
                      {
                        title: commonData.fixed.shop_titles["dimensions-guide"],
                        content: response.product.dimension_guide,
                      },
                    ]}
                  />
                </div> */}
              </div>
            </div>
            <div className="col-12 order-1 order-lg-1 col-lg-7">
              <GallerySwiper data={response.product.formatted_images} />
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="custom-container">
        <div className="similar-products">
          <h2
            className={`font-weight-medium f-24 ${observeSimilarProducts.inView
              ? "animate__animated animate__fadeIn"
              : "no-opacity"
              }`}
            ref={observeSimilarProducts.observe}
          >
            {commonData.fixed.shop_titles["similar-products"]}
          </h2>
          <div className="row">
            {response.similar.map(el => {
              return <div key={el.id} className="col-6 col-lg-3">
                <div>
                  {" "}
                  <ProductGridElement {...el} from={"product"} subcategory={response.subcategory.slug} category={response.category.slug} />
                </div>
              </div>
            })
            }

          </div>
        </div>
      </div>
    </div >
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default SingleProduct;

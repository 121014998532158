import { useContext } from "react";
import DataContext from "../contexts/DataContext";

const AddressBox = (props) => {
    const rand = Math.random();
    const commonData = useContext(DataContext)
    return <div className="address-box p-4 mb-4" >
        <div className="address-header d-flex justify-content-between">
            <span className="title">{props.business}</span>
            <div className="actions">
                {props.onEdit ? <img src="/images/pen.svg" className="mr-4 cursor-pointer" onClick={props.onEdit} /> : ""}
                {props.onDelete ? <img src="/images/trash.svg" onClick={props.onDelete} className="cursor-pointer" /> : ""}
                {/* {props.onCheck ? <img src="/images/trash.svg" onClick={props.onDelete} className="cursor-pointer" /> : ""} */}
                {props.onCheck ?
                    <div className="container">
                        <div className="round">
                            <input type="checkbox" id={`checkbox-${rand}`} onChange={(index) => props.onCheck(index)} checked={props.checked} />
                            <label htmlFor={`checkbox-${rand}`}></label>
                        </div>
                    </div> : ""}
            </div>
        </div>
        <div className="name">{props.name}</div>
        <div className=" address-line">{props.directions}</div>
        <div className=" address-line">{props.province.name}, {props.city} {props.postal_code} {props.country.name}</div>
        <div className=" address-line">{commonData.fixed.account_titles['address-box-phone'].title}: {props.phone_number}</div>
        <div className="primary address-line">{commonData.fixed.account_titles['address-box-default-selected'].title}</div>
    </div >
}
export default AddressBox;

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Pagination } from "swiper/core";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { Link } from "react-router-dom"
import { useContext } from "react";
import DataContext from "../contexts/DataContext";

SwiperCore.use([Pagination, EffectFade]);

const CustomSwiper = (props) => {
  const commonData = useContext(DataContext)
  const isExternalLink = (link) => {
    return link.split('http://').length + link.split('https://').length > 2;
  }
  return (
    <div className="custom-swiper">
      <div
        className={`img-container swiper-slide-content-wrapper
          ? "animate__animated animate__fadeIn"
          `}
      >
        <video poster={props.video.formatted_image} autoPlay={true} muted loop playsInline className='w-100 d-none d-md-block'>
          <source src={props.video.title} />
        </video>
        <video poster={props.mobile_video.formatted_image} autoPlay={true} muted loop playsInline className='w-100 d-block d-md-none'>
          <source src={props.mobile_video.title} />
        </video>
      </div>
      <div className="position-absolute overlay"></div>
      <Swiper slidesPerView={1} pagination={true} updateOnWindowResize={true}>
        {props.data.map((element) => (
          <SwiperSlide key={Math.random()}>
            {({ isActive }) => (
              <>
                <div
                  className={`d-md-block d-none swiper-slide-content-wrapper ${isActive ? "" : ""
                    }`}

                >
                  <div
                    className=" w-100 h-100 d-none d-md-block"
                    style={{ background: props.overlay }}
                  ></div>
                  <div
                    className={`swiper-slide-content ${isActive
                      ? " animate__animated animate__fadeInDown"
                      : "no-opacity"
                      }`}
                  >
                    {element.small_heading ? (
                      <div className="small-heading pl-2">
                        {element.small_heading}
                      </div>
                    ) : (
                      ""
                    )}
                    <h1
                      className="font-weight-medium"
                      dangerouslySetInnerHTML={{ __html: element.title }}
                    ></h1>
                    {element.text ? (
                      <div className="text mb-3 pl-2">{element.text}</div>
                    ) : (
                      ""
                    )}
                    {
                      isExternalLink(element.link) ?
                        <a href={element.link} target="_blank" rel="noreferrer">
                          {element.link_text}
                          <img src="/images/collection-arrow.svg" />
                        </a> :
                        <Link to={element.link}>
                          {element.link_text}
                          <img src="/images/collection-arrow.svg" />
                        </Link>
                    }

                  </div>
                </div>

                <div
                  className={`d-block d-md-none position-relative swiper-slide-content `}
                >
                  {/* <div
                    className={`img-container swiper-slide-content-wrapper ${isActive
                      ? "animate__animated animate__fadeIn"
                      : "no-opacity"
                      }`}
                    style={{
                      background: ` url(${element.formatted_image})`,
                    }}
                  ></div> */}
                  <div
                    className={`
                      mobile-container pb-4
                      ${isActive
                        ? " animate__animated animate__fadeIn"
                        : "no-opacity"
                      }
                    `}
                  >
                    {element.small_heading ? (
                      <div className="small-heading pl-2">
                        {element.small_heading}
                      </div>
                    ) : (
                      ""
                    )}
                    <h1
                      className="font-weight-regular color-primary-faded"
                      dangerouslySetInnerHTML={{ __html: element.title }}
                    ></h1>
                    {element.text ? (
                      <div className="text mb-3 pl-2 font-weight-regular">
                        {element.text}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="pl-2">
                      {isExternalLink(element.link) ?
                        <a href={element.link} target="_blank" rel="noreferrer">
                          {element.link_text}
                          <img src="/images/search-arrow.svg" />
                        </a> :
                        <Link to={element.link}>
                          {element.link_text}
                          <img src="/images/search-arrow.svg" />
                        </Link>}
                    </div>
                  </div>
                </div>
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default CustomSwiper;

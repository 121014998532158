import TermsPolicy from "../components/TermsPolicy";
import Breadcrumbs from "../components/Breadcrumbs";
import { useEffect, useContext } from "react";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "../api";
import useAxios from "../hooks/useAxios";
import DataContext from "../contexts/DataContext";
const TermsConditions = (props) => {
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const commonData = useContext(DataContext);
  useEffect(() => {
    setHookOptions({
      url:
        props.source,
    });
  }, [props.source]);
  return !loader && status == 200 ? (
    <div className="mt-4 mt-md-0">
      <div className="custom-container">
        <Breadcrumbs
          data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs[props.breadcrumbSlug].title }]}
        />
      </div>
      <TermsPolicy
        id={props.source}
        data={response.items}
        intro={
          response.titles.intro
        }
        lastUpdated={
          response.titles['last-updated'].title
        }
        title={
          response.titles.title.title

        }
        contentsTitle={
          response.titles['contents-title'].title
        }
      />
    </div>
  ) : (
    <div className="global-spinner">{loader}</div>
  );
};
export default TermsConditions;

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const useAxios = () => {
  const [cookies, setCookie] = useCookies(["token"]);
  const [guestCookie, setGuestCookie] = useCookies(["guestToken"]);
  const [response, setResponse] = useState({});
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(null);
  const [options, setHookOptions] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [status, setStatus] = useState(null);
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (!config.headers.Authorization) {
        if (options.token) {
          config.headers.Authorization = `Bearer ${options.token}`
        }
        else {
          config.headers.Authorization = cookies.token
            ? `Bearer ${cookies.token}`
            : "";
        }
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const renderErrors = (error) => {
    const errors = error.response.data.errors;
    if (errors) {
      const errorMessages = Object.keys(errors).map((el) => {
        return errors[el][0];
      });
      return errorMessages;
    } else return [];
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoader(
        <div className="loader">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
      try {
        const res = await axios({
          method: options.method || "get",
          url: options.url,
          data:
            options.data &&
              options.method &&
              options.method.toLowerCase() == "post"
              ? options.data
              : "",
          params:
            options.data &&
              (!options.method ||
                (options.method && options.method.toLowerCase() == "get"))
              ? options.data
              : "",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Guest-Token": guestCookie.guestToken,
          },
        });
        setResponse(res.data);
        setStatus(res.status);
        setError({});
      } catch (error) {
        setError({
          error: error,
          messages: renderErrors(error),
          keyed: error.response.data.errors,
        });
        setStatus(error.response.status);
      } finally {
        setLoader(null);
      }
    };
    if (options.url) fetchData();
  }, [options]);
  return [response, error, loader, setHookOptions, snackbar, status];
};
export default useAxios;

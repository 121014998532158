import { useContext, useState, useEffect } from "react";
import DataContext from "../../contexts/DataContext";
import { useHistory } from "react-router-dom";

const SearchBar = (props) => {
  const commonData = useContext(DataContext);
  const [searchValue, setSearchValue] = useState();
  let history = useHistory();

  const _handleClose = () => {
    props.setIsSearchBarOpen(false);
  };
  const _handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue) {
      props.setIsSearchBarOpen(false);
      setSearchValue("");
      history.push("/productos?search=" + searchValue);
    }
  };
  const _handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={`search-bar ${props.isOpen ? "active" : ""}`}>
      <div className="header text-right pt-4 pr-4">
        <a onClick={_handleClose} className="cursor-pointer">
          <img src="/images/close.svg" />
        </a>
      </div>
      <form
        className="search-container p-4 text-center"
        onSubmit={_handleSubmit}
      >
        <input
          placeholder={commonData.fixed.menu_titles.search_placeholder.title}
          tabIndex="-1"
          onChange={_handleChange}
          value={searchValue}
        />
        <img
          className="search-arrow"
          src="/images/search-arrow.svg"
          onClick={_handleSubmit}
        />
      </form>
      <div className="pl-4 suggestions">
        <small className="color-tertiary">
          {commonData.fixed.menu_titles.suggested.title}
        </small>
        <ul className="list-unstyled">
          {commonData.fixed.search_suggestions.map((suggestion) => (
            <li key={Math.random()}>
              <a href={`/productos?search=${suggestion.title}&sortBy=new`}>{suggestion.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default SearchBar;

import useInView from "react-cool-inview";
import Error from "./Error";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const Input = (props) => {
  const classes = useStyles();
  const observable = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div
      ref={observable.observe}
      className={` ${
        observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
      } `}
    >
      <TextField
        error={Boolean(
          Object.keys(props.error).length && props.error.keyed[props.name]
        )}
        id="standard-error"
        label={
          Object.keys(props.error).length && props.error.keyed[props.name]
            ? props.error.keyed[props.name][0]
            : props.placeholder
        }
        name={props.name}
        className="w-100"
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};
export default Input;

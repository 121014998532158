import Breadcrumbs from "../components/Breadcrumbs";
import Input from "../components/Input/Input";
import { useState, useEffect, useContext } from "react";
import useAxios from "../hooks/useAxios";
import Button from "../components/Button";
import { FORGOT_PASSWORD } from "../api";
import DataContext from "../contexts/DataContext";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const commonData = useContext(DataContext);
  const [
    submitResponse,
    submitError,
    submitLoader,
    submitSetHookOptions,
    submitSnackbar,
    status,
  ] = useAxios();
  const _handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const _onSubmit = (e) => {
    e.preventDefault();
    submitSetHookOptions({
      url: FORGOT_PASSWORD,
      data: formData,
      method: "post",
    });
  };
  useEffect(() => {
    if (status == 200)
      setFormData({
        email: "",
      });
  }, [status]);
  return (
    <div className="custom-container mt-3" id="forget-password">
      <Breadcrumbs data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['account'].title }]} />
      <h3 className="font-weight-medium">
        {commonData.fixed.account_titles["forgot-password-title"].title}
      </h3>
      <div>
        {commonData.fixed.account_titles["forgot-password-subtitle"].title}
      </div>
      <form onSubmit={_onSubmit}>
        <Input
          name="email"
          placeholder={commonData.fixed.account_titles["email"].title}
          value={formData.email}
          onChange={_handleChange}
          error={submitError}
        />

        <Button
          loading={submitLoader}
          type="submit"
          label={
            commonData.fixed.account_titles["forgot-password-button"].title
          }
          className="btn-outline-secondary"
          text="Restablecer"
        />
        {status == 200 ? (
          <small className="text-success mt-4 d-inline-block">
            {
              commonData.fixed.account_titles["forgot-password-success-message"]
                .title
            }
          </small>
        ) : (
          ""
        )}
      </form>
      <div
        className="need-help"
        dangerouslySetInnerHTML={{
          __html: commonData.fixed.account_titles["need-help"].content,
        }}
      ></div>
    </div>
  );
};
export default ForgetPassword;

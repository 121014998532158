import Select from "@material-ui/core/Select";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { useState, useEffect } from "react";

const Dropdown = (props) => {
  const renderTitle = (el) => {
    return props.render ? props.render(el) : el.title;
  };
  const [data, setData] = useState(props.data.map((el) => renderTitle(el)));
  useEffect(() => {
    setData(props.data.map((el) => renderTitle(el)));
  }, [props.data]);

  const _handleChange = (e) => {
    props.onChange(props.name, e.target.value);
  };
  return (
    <FormControl
      error={Boolean(
        Object.keys(props.error).length && props.error.keyed[props.name]
      )}
      className="w-100"
    >
      <InputLabel id={props.name}>{props.placeholder}</InputLabel>
      <Select
        labelId={props.name}
        value={props.value ? props.value : ""}
        onChange={_handleChange}
      >
        {props.data
          .filter((el, index) => props.data.indexOf(el) === index)
          // .filter((el, index) => {
          //   return el != renderTitle(props.data[props.selectedIndex]);
          // })
          .map((el, index) => (
            <MenuItem key={"dropdown-menu-" + index} value={el[props.valueKey]}>
              {props.render(el)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export default Dropdown;

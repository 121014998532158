import useAxios from "../../hooks/useAxios";
import { useEffect, useContext } from "react";
import { CART_DELETE } from "../../api";
import DataContext from "../../contexts/DataContext";
import { useCookies } from "react-cookie";
import { getCart, numberWithCommas } from "../../helper";

const ProductRow = (props) => {
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const commonData = useContext(DataContext);
  useEffect(() => {
    if (status == 200 && !loader){
      // commonData.setCartItems([]);
      commonData.setCartItems(
        commonData.cartItems.filter((el) => {
          return el.id !== props.id;
        })
      );
    }
  }, [loader]);
  const _handleDelete = () => {
    setHookOptions({
      method: "DELETE",
      url: CART_DELETE(props.id),
    });
  };
  return (
    <div className="d-flex align-items-center product position-relative">
      <div className="product-img-container d-flex align-items-center">
        <img
          src={
            props.product_price?.product.formatted_image || props.formatted_image || props.image || props.frame.formatted_thumbnail
          }
        />
      </div>
      <div className="product-info-container w-100 d-flex">
        <div>
          <div className="d-flex justify-content-between align-items-end text-left">
            {props.name || props.product_price?.product.name}
          </div>
          <div className="d-flex">
            <label className="m-0">{commonData.fixed.cart_fixed_titles['price'].title}:</label>
            <small>
              {numberWithCommas(props.total_price)}
            </small>{" "}
            <small className="currency font-family-lucida">€</small>
          </div>
          <div className="d-flex">
            <label className="m-0">{commonData.fixed.cart_fixed_titles['frame'].title}:</label>
            <small>{props.frame?.name}</small>{" "}
          </div>
          <div className="d-flex">
            <label className="m-0">{commonData.fixed.cart_fixed_titles['passpartout'].title}:</label>
            <small>{props.passpartout_size.value}mm </small>
          </div>
          <div className="d-flex">
            <label className="m-0">{commonData.fixed.cart_fixed_titles['material'].title}:</label>
            <small>{props.material.value}</small>
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-start flex-wrap">
              <label className="m-0">{commonData.fixed.cart_fixed_titles['dimension'].title}:</label>
              <small>
                {props.width} x {props.height}cm
              </small>{" "}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ width: "100%" }}></div>
        {props.layout != "checkout" ? (
          <div className="position-absolute trash-container">
            {loader ? (
              loader
            ) : (
              <svg
                onClick={_handleDelete}
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="16"
                viewBox="0 0 13 16"
                className="cursor-pointer trash"
              >
                <path
                  id="Shape"
                  d="M3.5,16A2.5,2.5,0,0,1,1,13.5V4H2v9.5A1.5,1.5,0,0,0,3.5,15h6A1.5,1.5,0,0,0,11,13.5V4h1v9.5A2.5,2.5,0,0,1,9.5,16Zm5-2.5V5h1v8.5ZM6,13.5V5H7v8.5Zm-2.5,0V5h1v8.5ZM0,3V2H4V0H9V2h4V3Z"
                />
              </svg>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div >
  );
};
export default ProductRow;

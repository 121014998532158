import { useContext, useState, useEffect } from "react";
import useInView from "react-cool-inview";
import { Link } from "react-router-dom";
import DataContext from "../../contexts/DataContext";
import LazyLoad from "react-lazyload";
const Col = (props) => {
  const observable = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div
      className={`col-6 col-md-3 ${
        observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
      }`}
      ref={observable.observe}
    >
      <Link to={`/artistas/${props.slug}`} className="product">
        <div className="img-container">
          <LazyLoad once offset={100}>
            <img src={props.formatted_image} onLoad={props._handleOnLoad} />
          </LazyLoad>
        </div>
      </Link>
      <Link to={`/artistas/${props.slug}`}>
        <div className="name f-24 font-weight-medium text-center text-md-left">
          {props.name}
        </div>
      </Link>
    </div>
  );
};
const SimilarArtists = (props) => {
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const commonData = useContext(DataContext);
  const [allImagesLoaded, setAllImagesLoaded] = useState(0);
  const _handleOnLoad = () => {
    setAllImagesLoaded(allImagesLoaded + 1);
  };
  useEffect(() => {
    if (allImagesLoaded == props.data.length) props.onAllLoaded();
  }, [allImagesLoaded]);
  return (
    <div className="similar-artists custom-container">
      <h3
        className={`f-24 font-weight-medium ${
          observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
        }`}
        ref={observable.observe}
      >
        {commonData.fixed.artists_titles["similar-artists"].title}
      </h3>
      <div className="row">
        {props.data.map((el) => {
          return <Col {...el} key={el.id} />;
        })}
      </div>
    </div>
  );
};
export default SimilarArtists;

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { CUSTOMIZE, GET_SIZE, SINGLE_PRODUCT } from "../api";
import Breadcrumbs from "../components/Breadcrumbs";
import Bill from "../components/Customize/Bill";
import Customization from "../components/Customize/Customization";
import Painting from "../components/Customize/Painting";
import useAxios from "../hooks/useAxios";
import { PickerOverlay } from "filestack-react";
import { unstable_batchedUpdates } from "react-dom";
import { useContext } from "react";
import DataContext from "../contexts/DataContext";

const CustomizeProduct = (props) => {
    const location = useLocation();
    const routeParams = useParams();
    const commonData = useContext(DataContext)

    const [selectedDropdownIndexes, setSelectedDropdownIndexes] = useState({
        frame: null, //canvas size index when it's a predefined product
        dimension: null,
    });
    const [canvasWidth, setCanvasWidth] = useState();//canvas width in cm
    const [canvasHeight, setCanvasHeight] = useState();  //canvas height in cm
    const [maxWidth, setMaxWidth] = useState();
    const [image, setImage] = useState();
    const [imageHandle, setImageHandle] = useState();
    const [fileName, setFileName] = useState();
    const [selectedPassPartoutColor, setSelectedPassPartoutColor] = useState();
    const [paintingTypeIndex, setPaintingTypeIndex] = useState(0);
    const [passpartoutIndex, setPasspartoutIndex] = useState(0);
    const [materialIndex, setMaterialIndex] = useState();
    const [paintingWidth, setPaintingWidth] = useState(100); //frame width percentage
    const [submitClicked, setSubmitClicked] = useState();
    const [addToCartError, setAddToCartError] = useState();
    const [response, error, loader, setHookOptions, snackbar, status] =
        useAxios();
    const [responseSize, errorSize, loaderSize, setHookOptionsSize, snackbarSize, statusSize] =
        useAxios();
    const [openPicker, setOpenPicker] = useState();
    const pxToCm = (px) => {
        return Math.floor(px * 0.0264583333);
    }
    useEffect(() => {
        const url = (props.custom == 1 || props.custom == 2) ? CUSTOMIZE : SINGLE_PRODUCT(routeParams["category"], routeParams["subcategory"], routeParams["slug"]);
        setHookOptions({
            url: url
        });
    }, []);

    useEffect(() => {
        if (status == 200) {
            setSelectedPassPartoutColor(response.colors[0].id);
            if (props.custom == 1 || props.custom == 2) {
                setMaterialIndex(0);
            }
            else {
                setImage(response.product.formatted_customizable_image);
                setMaxWidth(response.product.price.reduce((prev, current) => (prev.width > current.width) ? prev : current).width)
            }
            let dimIndex = new URLSearchParams(location.search).get('dimensionIndex')
            if (dimIndex)
                setSelectedDropdownIndexes({
                    frame: selectedDropdownIndexes.frame,
                    dimension: dimIndex
                })
        }
    }, [status]);

    useEffect(() => {
        if (selectedDropdownIndexes.dimension !== null) {
            const width = response.product.price[selectedDropdownIndexes.dimension].width;
            const height = response.product.price[selectedDropdownIndexes.dimension].height;
            setCanvasHeight(height);
            setCanvasWidth(width);
            setPaintingWidth((width * 100) / maxWidth);
        }
    }, [selectedDropdownIndexes.dimension]);
    useEffect(() => {
        if (canvasWidth && ((image && props.custom == 1) || props.custom == 2)) {
            setPaintingWidth((canvasWidth * 100) / (maxWidth || 100));
        }
    }, [canvasWidth]);
    useEffect(() => {
        if (props.custom == 1 && image) {
            setHookOptionsSize({
                url: GET_SIZE,
                data: {
                    url:
                        `https://cdn.filestackcontent.com/AcP9T5NsBRHal774RLqz7z/imagesize/${imageHandle}`,
                },
                method: "POST"
            });
        }
    }, [image]);
    useEffect(() => {
        if(statusSize==200)
        setMaxWidth(pxToCm(responseSize.width));
    }, [loaderSize]);
    return status == 200 ? (<div id="customize">
        <div className="custom-container">
            <Breadcrumbs data={
                (props.custom !== 1 && props.custom !== 2) ?
                    [
                        { name: commonData.fixed.breadcrumbs['home'].title, url: "/" },
                        {
                            name: response.category.name,
                            url: `/productos/${response.category.slug}`,
                        },
                        {
                            name: response.subcategory.name,
                            url: `/productos/${response.subcategory.slug}`,
                        },
                        { name: response.product.name, url: location.pathname },
                        { name: commonData.fixed.breadcrumbs['customize'].title },

                    ] :

                    [
                        { name: "Home", url: "/" },
                        { name: commonData.fixed.breadcrumbs['customize'].title, url: "/personalize" },

                    ]} />
        </div>
        <div className="custom-container">
            {openPicker && props.custom == 1 ? (
                <PickerOverlay
                    onSuccess={(result) => {
                        unstable_batchedUpdates(() => {
                            setImageHandle(result.filesUploaded[0].handle)
                            setImage(result.filesUploaded[0].url);
                            setFileName(result.filesUploaded[0].filename)
                        })

                    }}
                    pickerOptions={{
                        onClose: () => {
                            setOpenPicker(false);
                        },
                        maxFiles: 1,
                    }}
                    apikey="AcP9T5NsBRHal774RLqz7z"
                />
            ) : (
                ""
            )}
            <div className="row">
                <div className="col-xl-3">
                    <Customization
                        addToCartError={addToCartError}
                        data={response}
                        materials={response.materials}
                        frames={response.frames}
                        colors={response.colors}
                        product={response.product}
                        image={image}
                        passpartoutIndex={passpartoutIndex}
                        materialIndex={materialIndex}
                        custom={props.custom}
                        selectedDropdownIndexes={selectedDropdownIndexes}
                        selectedPassPartoutColor={selectedPassPartoutColor}
                        setPasspartoutIndex={setPasspartoutIndex}
                        setSelectedDropdownIndexes={setSelectedDropdownIndexes}
                        setSelectedPassPartoutColor={setSelectedPassPartoutColor}
                        setMaterialIndex={setMaterialIndex}
                        setOpenPicker={setOpenPicker}
                        fileName={fileName}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        setCanvasWidth={setCanvasWidth}
                        setCanvasHeight={setCanvasHeight}
                        maxWidth={maxWidth}
                        maxHeight={pxToCm(responseSize?.height)}
                        submitClicked={submitClicked}
                        paintingTypeIndex={paintingTypeIndex}
                        setPaintingTypeIndex={setPaintingTypeIndex}
                    />
                </div>
                <div className="col-xl-6 pt-5">
                    <Painting
                        setOpenPicker={setOpenPicker}
                        openPicker={openPicker}
                        custom={props.custom}
                        maxPasspartoutSize={response.passpartout_sizes[response.passpartout_sizes.length - 1].value}
                        passpartoutSize={response.passpartout_sizes[passpartoutIndex].value}
                        passpartoutColor={response.colors.find(el => el.id == selectedPassPartoutColor)?.value}
                        frame={response.frames[selectedDropdownIndexes.frame]}
                        image={image}
                        width={paintingWidth}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                    />
                </div>
                <div className="col-xl-3">
                    <Bill
                        data={response}
                        custom={props.custom}
                        selectedDropdownIndexes={selectedDropdownIndexes}
                        material={response.materials[materialIndex]}
                        frame={response.frames[selectedDropdownIndexes.frame]}
                        passpartoutIndex={passpartoutIndex}
                        selectedPassPartoutColor={response.colors.find(el => el.id == selectedPassPartoutColor)}
                        tax={response.tax}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        totalWidth={parseFloat(canvasWidth || 0) + parseFloat(response.frames[selectedDropdownIndexes.frame]?.thickness || 0) * 2 + parseFloat(response.passpartout_sizes[passpartoutIndex]?.value || 0) * 2}
                        totalHeight={parseFloat(canvasHeight || 0) + parseFloat(response.frames[selectedDropdownIndexes.frame]?.thickness || 0) * 2 + parseFloat(response.passpartout_sizes[passpartoutIndex]?.value || 0) * 2}
                        image={image}
                        setSubmitClicked={setSubmitClicked}
                        paintingTypeIndex={paintingTypeIndex}
                        setAddToCartError={setAddToCartError}
                    />
                </div>
            </div>
        </div>
    </div>) : (
    <div className="global-spinner">
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>)

}
export default CustomizeProduct;
const baseUrl =
  // process.env.NODE_ENV === "production" ?
     "https://esteoeste-marcos.com/api" 
  //   : "http://localhost/este-o-este-server/public/api";

  // "http://localhost/projects/este-o-este-server/public/api";
export const HOME = baseUrl + "/home";
export const LANDING_PAGE = baseUrl + "/landing-page";
export const WHO_WE_ARE = `${baseUrl}/nosotros`;
export const PRODUCTS = (category) => `${baseUrl}/${category}/products`;
export const PERSONALIZE_REDIRECT = `${baseUrl}/personalization-redirect-page`;
export const ALL_PRODUCTS = `${baseUrl}/products`;
export const SINGLE_PRODUCT = (category, subcategory, slug) => `${baseUrl}/products/${category}/${subcategory}/${slug}`;
export const CUSTOMIZE = `${baseUrl}/customize`;
export const SINGLE_PRODUCT_CUSTOMIZATION = (slug) => `${baseUrl}/products/${slug}/customize`;
export const SUBSCRIBE = baseUrl + "/subscribe";
export const GLOBAL_DATA = baseUrl + "/global";
export const ARTISTS = baseUrl + "/artists";
export const SINGLE_ARTIST = (slug) => `${baseUrl}/artists/${slug}`;
export const SUBMIT = `${baseUrl}/signup`;
export const LOGIN = `${baseUrl}/login`;
export const VERIFY = (email, token) => `${baseUrl}/verify/${email}/${token}`;
export const FORGOT_PASSWORD = `${baseUrl}/forgot-password`;
export const RESET_PASSWORD = `${baseUrl}/reset-password`;
export const ACCESS_RESET_PASSWORD = (email, token) =>
  `${baseUrl}/access-reset-password/${email}/${token}`;
export const CART = `${baseUrl}/cart`;
export const CART_BULK = `${baseUrl}/cart/bulk`;
export const CART_DELETE = (id) => `${baseUrl}/cart/${id}`;
export const UPDATE_CART = `${baseUrl}/cart/update`;
export const TOTAL = `${baseUrl}/total`;
export const COUNTRIES = `${baseUrl}/countries`;
export const PROVINCES = (country_id) => `${baseUrl}/provinces/${country_id}`;
export const SUBMIT_ORDER = `${baseUrl}/order`;
export const VALIDATE_ADDRESS = `${baseUrl}/validate-address`;
export const COUPON = `${baseUrl}/coupon`;
export const CHECK_AVAILABILITY = `${baseUrl}/availability`;
export const INSPIRATION = `${baseUrl}/inspiration`;
export const PAYPAL = `${baseUrl}/paypal`;
export const CONTACTUS = `${baseUrl}/contactus`;
export const PRIVACY_POLICY = `${baseUrl}/privacy-policy`;
export const TERMS_CONDITIONS = `${baseUrl}/terms-conditions`;
export const LEGAL = `${baseUrl}/legal`;
export const SHIPPMENT = `${baseUrl}/shippment`;
export const COOKIES = `${baseUrl}/cookies`;
export const ORDER_HISTORY = `${baseUrl}/order-history`;
export const ME = `${baseUrl}/me`;
export const ADDRESSES = `${baseUrl}/addresses`;
export const SINGLE_ADDRESS = (id) => `${baseUrl}/addresses/${id}`;
export const GET_SIZE = `${baseUrl}/image-size`;
export const PERSONALISATION = `${baseUrl}/personalization`;
export const SEO = `${baseUrl}/seo`;
import { useContext, useEffect } from "react";
import useInView from "react-cool-inview";
import { LANDING_PAGE } from "../api";
import CustomSwiper from "../components/CustomSwiper";
import Instagram from "../components/Instagram";
import OurServices from "../components/LandingPage/OurServices";
import StaffPicks from "../components/LandingPage/StaffPicks";
import WhoWeAreSection from "../components/WhoWeAreSection";
import Layout from "../components/Layout";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";

const LandingPage = () => {
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const commonData = useContext(DataContext);
  const {
    observe: observe2,
    unobserve: unobserve2,
    inView: inView2,
  } = useInView({
    unobserveOnEnter: true,
  });

  useEffect(() => {
    setHookOptions({
      url: LANDING_PAGE,
    });
  }, []);

  return Object.keys(response).length ? (
    <div id="landing-page">
      <div
        ref={observe2}
        className={
          inView2 ? "animate__animated animate__fadeIn home" : "home no-opacity"
        }
      >
        <CustomSwiper
          data={response.swiper}
          video={commonData.fixed.landing_page_titles["swiper-video"]}
          mobile_video={commonData.fixed.landing_page_titles["swiper-video-mobile"]}
        />
      </div>
      <StaffPicks response={response.staff_picks} />
      <div className="custom-container"><OurServices response={response} /></div>

      <Instagram data={response.insta_feed} />
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default LandingPage;

import { useCookies } from "react-cookie";
import { useContext, useEffect } from "react";
import Breadcrumbs from "../Breadcrumbs";
import DataContext from "../../contexts/DataContext";
import { Link } from "react-router-dom";
const Layout = (props) => {
    const commonData = useContext(DataContext);
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const _handleLogout = () => {
        removeCookie("token", { path: '/' });
        commonData.setCartItems([])
    };
    return (
        <div id="account">
            <div className="custom-container">
                <Breadcrumbs data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['account'].title }]} />
            </div>
            <div className="custom-container">
                <div className="row main-row">
                    <div className="col-12 col-lg-3">
                        <h3 className="font-weight-medium f-24">
                            {commonData.fixed.account_titles['my-account'].title}
                        </h3>
                        <div className="row menu-row">
                            <Link to="/cuenta/mis-pedidos" className={`side-menu col-lg-12 col-4 ${props.active == 'order-history' ? 'active' : ''}`}>
                                <img src="/images/search-arrow.svg" />
                                {commonData.fixed.account_titles['my-orders'].title}
                            </Link>
                            <Link to="/cuenta/addresses" className={`side-menu col-lg-12 col-4 ${props.active == 'directions' ? 'active' : ''}`}>
                                <img src="/images/search-arrow.svg" />
                                {commonData.fixed.account_titles['my-addresses'].title}
                            </Link>
                            <Link to="/cuenta/profile" className={`side-menu col-lg-12 col-4 information ${props.active == 'information' ? 'active' : ''}`}>
                                <img src="/images/search-arrow.svg" />
                                {commonData.fixed.account_titles['my-information'].title}
                            </Link>
                            <a
                                className="col-lg-12 d-none d-lg-block logout cursor-pointer"
                                onClick={_handleLogout}
                            >
                                {commonData.fixed.account_titles['logout'].title}
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        {props.children}
                    </div>
                </div>
            </div>
        </div >
    );
};
export default Layout;

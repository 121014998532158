import { useCookies } from "react-cookie";
import { Route, Redirect } from "react-router-dom";
const GuestRoute = (props) => {
  const [cookies, setCookie] = useCookies(["token"]);
  return cookies.token ? (
    <Route>
      <Redirect
        to={{
          pathname: "/cuenta/mis-pedidos",
        }}
      />
    </Route>
  ) : (
    <Route path={props.path}>{props.children}</Route>
  );
};
export default GuestRoute;

import { useContext, useState } from "react";
import { useParams } from "react-router";
import DataContext from "../../contexts/DataContext";
import useAxios from "../../hooks/useAxios";
import Dropdown from "../Products/Dropdown";

const Customization = (props) => {
    let index = 0;
    const commonData = useContext(DataContext);
    const _handleDropdownChange = (name, value) => {
        props.setSelectedDropdownIndexes({
            ...props.selectedDropdownIndexes,
            [name]: value,
        });
    };
    const _handlePasspartoutValueChange = (name, value) => {
        props.setPasspartoutIndex(value);
    }
    const _handleMaterialChange = (name, value) => {
        props.setMaterialIndex(value);
    }
    const _handlePasspartoutColorClick = (id) => {
        props.setSelectedPassPartoutColor(id);
    }
    const _handlePaintingTypeChange = (name, value) => {
        props.setPaintingTypeIndex(value);
    }
    const _handleCanvasWidthChange=(e)=>{
        let value=e.target.value;
        props.setCanvasWidth(value);
        if (props.custom == 1)
        props.setCanvasHeight(Math.floor(value * props.maxHeight / props.maxWidth));
    }
    const enforceMinimumWidth = (e) => {
        let value=e.target.value;
         if(props.custom==1){
            if(value>props.maxWidth){
                value=props.maxWidth;
            }
        }
        if(value&&value<10){
            value=10;
        }
         if(value&&value>100){
            value=100;
        }
        if(value)
        props.setCanvasWidth(value);
        if (props.custom == 1 && value)
            props.setCanvasHeight(Math.floor(value * props.maxHeight / props.maxWidth));
    }
    const _handleCanvasHeightChange=(e)=>{
        let value=e.target.value;
        props.setCanvasHeight(value);
    }
    const enforceMinimumHeight = (e) => {
        let value=e.target.value;
        if(value&& value>100)
            value=100;
        else if(value&& value<10)
            value=10
        if(value)
            props.setCanvasHeight(value)
    }
    const _handleImageClick = () => {
        props.setOpenPicker(true);
    }

    return <div className="customization">
        {props.custom == 1 ?
            <div className="avatar-container">
                <h5 className={props.image == null && props.submitClicked ? "error" : ""}>{++index}. {commonData.fixed.customization_fixed_titles['upload-file'].title}</h5>
                {props.custom == 1 ?
                    <div className="custom-product d-flex align-items-center">
                        {props.image ?
                            <div className="d-flex align-items-center">
                                <img src={props.image} className="avatar cursor-pointer" onClick={_handleImageClick} />
                                <label className="file-name">{props.fileName}</label>
                            </div>
                            :
                            <div className="d-flex align-items-center">
                                <div className="plus-container d-inline-block" onClick={_handleImageClick}>
                                    <img src="/images/upload-plus.svg" />
                                </div>
                                <label className="plus-label m-0">
                                    {commonData.fixed.customization_fixed_titles['supported-restriction'].title}
                                </label>
                            </div>
                        }

                    </div> :
                    <div className="d-flex align-items-center">
                        <img src={props.image} className="avatar" />
                        <label>{(props.custom == 1 || props.custom == 2) ? "" : props.product.name}</label>
                    </div>
                }
            </div>
            : ""}
        <div>
            <h5 className={(props.selectedDropdownIndexes.dimension == null && (!props.canvasWidth || !props.canvasHeight)) && props.submitClicked ? "error" : ""}>{++index}.  {commonData.fixed.customization_fixed_titles['customize-frame-size'].title}</h5>
            <div>
                {(props.custom == 1 || props.custom == 2) ?
                    <>
                        {!isNaN(props.maxWidth) || props.maxWidth>9 ? 
                            (<label className="mb-1">{commonData.fixed.customization_fixed_titles['maximum-size'].title}: {props.maxWidth>100?100:props.maxWidth} cm x {props.maxHeight}  cm</label>):
                        <div>
                            <div>
                                <label className="mb-1">{commonData.fixed.customization_fixed_titles['maximum-size'].title}: 100 cm x 100  cm</label>
                            </div>  
                        </div>
                        }
                        {isNaN(props.maxWidth)  || props.maxWidth>9?
                            <div>
                                <label className="mb-4">{commonData.fixed.customization_fixed_titles['minimum-size'].title}: 10 cm x 10  cm</label>
                            </div>  
                            :
                            <div>
                                <label className="mb-4 text-danger">{commonData.fixed.customization_fixed_titles['image-too-small'].title}</label>
                            </div>  
                        }
                        {
                            props.addToCartError?.canvas_width?
                            <small className="error mb-2 d-inline-block">{props.addToCartError.canvas_width}</small>
                            :""
                        }
                        {
                            props.addToCartError?.canvas_height?
                            <small className="error mb-2 d-inline-block">{props.addToCartError.canvas_height}</small>
                            :""
                        }
                        <div className="d-flex justify-content-between align-items-center">
                            <input onBlur={enforceMinimumWidth} onMouseLeave={enforceMinimumWidth} min="10"  max="100" className="m-0" type="number" value={props.canvasWidth} onChange={_handleCanvasWidthChange} />
                            <span>X</span>
                            <input onBlur={enforceMinimumHeight} onMouseLeave={enforceMinimumHeight} min="10"  max="100" className="m-0" type="number" value={props.canvasHeight} disabled={props.custom == 2 ? false : true} onChange={_handleCanvasHeightChange} />
                        </div>
                    </> :
                    <Dropdown
                        placeholder={commonData.fixed.customization_fixed_titles["dropdown-placeholder-dimension"].title}
                        data={props.product.price}
                        selectedIndex={props.selectedDropdownIndexes.dimension}
                        render={(el) => {
                            return el.width + " x " + el.height;
                        }}
                        name="dimension"
                        onChange={_handleDropdownChange}
                    />
                }
            </div>
        </div>
        <div>
            <h5 className={props.selectedDropdownIndexes.frame == null && props.submitClicked ? "error" : ""}>{++index}. {commonData.fixed.customization_fixed_titles['choose-frame'].title}</h5>
            <div>
                <Dropdown
                    placeholder={commonData.fixed.customization_fixed_titles["dropdown-placeholder-frame"].title}
                    data={props.frames}
                    selectedIndex={props.selectedDropdownIndexes.frame}
                    render={(el) => {
                        return <div className="d-flex align-items-center">
                            <img src={el.formatted_thumbnail} width="40" className="mr-3" />
                            <div>
                                <div className="frame-name-dropdown">{el.name} </div>
                                <div><small>Ancho: {el.thickness} cm</small></div>
                            </div>
                        </div>;
                    }}
                    name="frame"
                    onChange={_handleDropdownChange}
                />
            </div>
        </div>
        <div>
            <h5 className={props.passpartoutIndex == null && props.submitClicked ? "error" : ""}>{++index}.  {commonData.fixed.customization_fixed_titles['add-passpartout'].title}</h5>
            <div className="passpartout-customize">
                <div>
                    <Dropdown
                        placeholder={"Tamano"}
                        data={props.data.passpartout_sizes}
                        selectedIndex={props.passpartoutIndex}
                        name="passpartout_sizes"
                        onChange={_handlePasspartoutValueChange}
                        render={(el) => {
                            return <div>{el.text}</div>
                        }}
                    />
                </div>
              
            </div>
        </div>
        <div>
            <h5 className={props.materialIndex == null && props.submitClicked ? "error" : ""}>{++index}.  {commonData.fixed.customization_fixed_titles["frame-material"].title}</h5>
            <Dropdown
                placeholder={commonData.fixed.customization_fixed_titles["dropdown-placeholder-material"].title}
                data={props.data.materials}
                selectedIndex={props.materialIndex}
                name="material"
                onChange={_handleMaterialChange}
                render={(el) => {
                    if (el)
                        return <div>{el.value} </div>
                }}
            />
        </div>
        {props.custom == 1 && props.data.painting_types.length ? <div>
            <h5 className={props.materialIndex == null && props.submitClicked ? "error" : ""}>{++index}. {commonData.fixed.customization_fixed_titles["painting-type"].title}</h5>
            <Dropdown
                placeholder={"Painting Type"}
                data={props.data.painting_types}
                selectedIndex={props.paintingTypeIndex}
                name="painting_type"
                onChange={_handlePaintingTypeChange}
                render={(el) => {
                    return <div>{el.value} </div>
                }}
            />
        </div> : ""}
    </div >
}
export default Customization;
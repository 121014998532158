import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useInView from "react-cool-inview";
import DataContext from "../../contexts/DataContext.js";
import Footer from "./Footer.js";
import Nav from "./Nav/index.js";
import SearchBar from "./SearchBar.js";
import LayoutContext from "../../contexts/LayoutContext.js";
import MiniCart from "../Cart/MiniCart.js";
import { useCookies } from "react-cookie";
import Newsletter from "../Newsletter.js";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { SEO } from "../../api.js";

const Layout = (props) => {
  const location = useLocation();
  const history = useHistory();
  const topRef = useRef();
  const bannerRef = useRef();
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  const [isSearchBarOpen, setIsSearchBarOpen] = useState();
  const [isCartOpen, setIsCartOpen] = useState();
  const [isOverlayVisible, setIsOverlayVisible] = useState();
  const [preventScroll, setPreventScroll] = useState();
  const [navHeight, setNavHeight] = useState();
  const commonData = useContext(DataContext);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
  const [seoPage, setSeoPage] = useState();


  const _handleOverlayClick = () => {
    setIsOverlayVisible(false);
  };
  useEffect(() => {
    setTimeout(() => {
      if (!cookies.newsletter)
        setIsNewsletterOpen(true);
      setCookie("newsletter", 'opened', { path: "/" });
    }, 5000);
    setNavHeight(topRef.current.clientHeight);
  }, []);
  useEffect(() => {
    if (isOverlayVisible === false) {
      setIsSearchBarOpen(false);
      setIsCartOpen(false);
    }
  }, [isOverlayVisible]);
  useEffect(() => {
    setIsOverlayVisible(isSearchBarOpen);
  }, [isSearchBarOpen]);
  useEffect(() => {
    setIsOverlayVisible(isCartOpen);
  }, [isCartOpen]);
  useEffect(() => {
    setIsSearchBarOpen(false);
    setIsOverlayVisible(false);
  }, [location.pathname]);
  useEffect(() => {
    const unlisten = history.listen(() => {
      axios.post(SEO, {
        path: history.location.pathname
      })
        .then(r => {
          setSeoPage(r.data)
        })
    });
    if (history.location.pathname == "/") {
      axios.post(SEO, {
        path: ""
      })
        .then(r => {
          setSeoPage(r.data)
        })
    }
    return () => {
      unlisten();
    }
  }, [history]);
  return (
    <LayoutContext.Provider
      value={{
        isOverlayVisible,
        setIsOverlayVisible,
        setIsCartOpen,
        isCartOpen,
        setPreventScroll,
        navHeight,
        setCookie,
        cookies
      }}
    >
      {seoPage ?
        <Helmet>
          <title>{seoPage.title}</title>
          <meta name="title" content={seoPage.title} />
          <meta name="description" content={seoPage.description} />

          <meta property="og:title" content={seoPage.title} />
          <meta property="og:description" content={seoPage.description} />
          <meta property="og:image" content={seoPage.image_path} />

          <meta property="twitter:title" content={seoPage.title} />
          <meta property="twitter:description" content={seoPage.description} />
          <meta property="twitter:image" content={seoPage.image_path} />
        </Helmet> : ""}
      <div
        id={props.id}
        className={`${location.pathname.toLowerCase() == "/cesta" ||
          location.pathname.toLowerCase() == "/accesso" ||
          location.pathname.toLowerCase() == "/inscribirse" ||
          location.pathname.toLowerCase() == "/cesta" ||
          location.pathname.toLowerCase() == "/olvidaste-contrasena" ||
          location.pathname.toLowerCase() == "/cuenta/profile" ||
          location.pathname.toLowerCase() == "/cuenta/addresses" ||
          location.pathname.toLowerCase() == "/cuenta/profile"
          ? "bg-grey"
          : ""
          } main-wrapper ${isOverlayVisible ? "overlay-active" : ""} ${location.pathname.toLowerCase() == "/checkout" ? " checkout" : ""
          }
        ${preventScroll ? "prevent-scroll" : ""}
        `}
      >
        <Newsletter isOpen={isNewsletterOpen} setIsOpen={setIsNewsletterOpen} />
        <MiniCart isOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />

        <SearchBar
          isOpen={isSearchBarOpen}
          setIsSearchBarOpen={setIsSearchBarOpen}
        />
        <div className="top-fixed" ref={topRef}>
          <Nav
            data={commonData}
            setIsSearchBarOpen={setIsSearchBarOpen}
            setIsCartOpen={setIsCartOpen}
            bannerHeight={
              bannerRef.current ? bannerRef.current.clientHeight : 0
            }
          />
        </div>
        <div className="overlay" onClick={_handleOverlayClick}></div>
        <div
          className="content"
          style={{
            paddingTop:
              location.pathname !== "/" && location.pathname !== "/landing-page"
                ? navHeight + "px"
                : 0,
          }}
        >
          {props.children}
        </div>
        <Footer />
      </div>
    </LayoutContext.Provider>
  );
};
export default Layout;

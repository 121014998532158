import logo from "./logo.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import { useEffect, useState } from "react";
import useAxios from "./hooks/useAxios";
import { COOKIES, GLOBAL_DATA, LEGAL, PRIVACY_POLICY, SHIPPMENT, TERMS_CONDITIONS } from "./api";
import Layout from "./components/Layout";
import DataContext from "./contexts/DataContext";
import WhoWeAre from "./pages/WhoWeAre";
import Products from "./pages/Products";
import SingleProduct from "./pages/SingleProduct";
import Artists from "./pages/Artists";
import SingleArtist from "./pages/SingleArtist";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import { CookiesProvider } from "react-cookie";
import GuestRoute from "./components/Routes/GuestRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import VerifyEmail from "./pages/VerifyEmail";
import Test from "./pages/Test";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import { useCookies } from "react-cookie";
import Inspirations from "./pages/Inspirations";
import Personalize from "./pages/Personalize";
import ContactUs from "./pages/ContactUs";
import TermsConditions from "./pages/TermsConditions";
import Addresses from "./pages/Addresses";
import Profile from "./pages/Profile";
import OrderHistory from "./pages/OrderHistory";
import CustomizeProduct from "./pages/CustomizeProduct";
import CustomizeRedirectPage from "./pages/CustomizeRedirectPage";
import { getCart, updateCart } from "./helper";
import OrderSuccess from "./pages/OrderSuccess";

function App() {
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const [cartItems, setCartItems] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [guestCookies, guestSetCookie, guestRemoveCookie] = useCookies([
    "guestToken",
  ]);
  const [fixed, setFixed] = useState();

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  if (!guestCookies.guestToken) {
    guestSetCookie("guestToken", "Guest_" + uuidv4());
  }
  useEffect(() => {
    setHookOptions({
      url: GLOBAL_DATA,
    });
  }, []);

  useEffect(() => {
    updateCart(cartItems)
  }, [cartItems])
  window.addEventListener('storage', (e) => {
    if (cartItems != getCart())
      setCartItems(getCart());
  });
  useEffect(() => {
    if (status == 200) {
      setCartItems(response.cart);
      setFixed(response);
    }
  }, [loader]);

  return Object.keys(response).length && fixed ? (
    <Router>
      <CookiesProvider>
        <DataContext.Provider
          value={{
            fixed: fixed,
            setFixed: setFixed,
            cartItems: cartItems,
            setCartItems: setCartItems
          }}
        >
          <Layout data={response}>
            <Switch>
              <Route exact path="/nosotros">
                <WhoWeAre />
              </Route>
              <Route exact path="/frames">
                <CustomizeProduct custom={2} />
              </Route>
              <Route name="custom-product" exact path="/custom-image">
                <CustomizeProduct custom={1} />
              </Route>
              <Route name="product" exact path="/productos/:category/:subcategory/:slug/customize">
                <CustomizeProduct />
              </Route>
              <Route exact path="/productos/:category/:subcategory/:slug">
                <SingleProduct />
              </Route>
              <Route exact path="/productos/:category">
                <Products />
              </Route>
              <Route exact path="/productos">
                <Products />
              </Route>
              <Route exact path="/artistas/:slug">
                <SingleArtist />
              </Route>
              <Route exact path="/verify/:email/:token">
                <VerifyEmail />
              </Route>
              <Route exact path="/artistas">
                <Artists />
              </Route>
              <GuestRoute path="/inscribirse">
                <SignUp />
              </GuestRoute>
              <GuestRoute path="/accesso">
                <Login />
              </GuestRoute>{" "}
              <GuestRoute path="/olvidaste-contrasena">
                <ForgetPassword />
              </GuestRoute>
              <GuestRoute path="/reset-password/:email/:token">
                <ResetPassword />
              </GuestRoute>
              <ProtectedRoute path="/cuenta/mis-pedidos">
                <OrderHistory />
              </ProtectedRoute>
              <ProtectedRoute path="/cuenta/addresses">
                <Addresses />
              </ProtectedRoute>
              <ProtectedRoute path="/cuenta/profile">
                <Profile />
              </ProtectedRoute>
              <Route path="/cesta">
                <Cart />
              </Route>{" "}
              <Route path="/checkout">
                <Checkout />
              </Route>
              <Route path="/inspiracion">
                <Inspirations />
              </Route>{" "}
              <Route path="/personalize">
                <CustomizeRedirectPage />
              </Route>{" "}
              <Route path="/contacta-con-nosotros">
                <ContactUs />
              </Route>
              <Route path="/terminos-y-condiciones">
                <TermsConditions source={TERMS_CONDITIONS} breadcrumbSlug={"terms-conditions"} />
              </Route>
              <Route path="/cookies">
                <TermsConditions source={COOKIES} breadcrumbSlug={"cookies"} />
              </Route>
              <Route path="/aviso-legal">
                <TermsConditions source={LEGAL} breadcrumbSlug={"legal-warning"} />
              </Route>
              <Route path="/politica-de-privacidad">
                <TermsConditions source={PRIVACY_POLICY} breadcrumbSlug={"privacy-policy"} />
              </Route>
              <Route path="/envios">
                <TermsConditions source={SHIPPMENT} breadcrumbSlug={"shippment"} />
              </Route>
              <Route path="/exito">
                <OrderSuccess />
              </Route>
              <Route path="/">
                <LandingPage />
              </Route>{" "}
            </Switch>
          </Layout>
        </DataContext.Provider>
      </CookiesProvider>
    </Router>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default App;

import { useEffect, useState, useContext } from "react";
import { ACCESS_RESET_PASSWORD, LOGIN, RESET_PASSWORD } from "../api";
import Button from "../components/Button";
import useAxios from "../hooks/useAxios";
import PasswordInput from "../components/Input/PasswordInput";
import { useParams, useHistory } from "react-router-dom";
import DataContext from "../contexts/DataContext";

const ResetPassord = () => {
  const params = useParams();
  const history = useHistory();
  const commonData = useContext(DataContext);
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    email: params.email,
    password_recovery_token: params.token,
  });
  const [
    submitResponse,
    submitError,
    submitLoader,
    submitSetHookOptions,
    submitSnackbar,
    submitStatus,
  ] = useAxios();
  const [
    accessPageResponse,
    accessPageError,
    accessPageLoader,
    accessPageSetHookOptions,
    accessPageSnackbar,
    accessPageStatus,
  ] = useAxios();

  const _handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const _onSubmit = (e) => {
    e.preventDefault();
    submitSetHookOptions({
      url: RESET_PASSWORD,
      method: "post",
      data: formData,
    });
  };
  useEffect(() => {
    accessPageSetHookOptions({
      url: ACCESS_RESET_PASSWORD(params.email, params.token),
    });
  }, []);
  useEffect(() => {
    if (submitStatus == 200) {
      history.push("/accesso");
    }
  }, [submitStatus]);
  return accessPageStatus ? (
    <div id="reset-password" className="custom-container">
      {accessPageStatus == 200 ? (
        <form
          onSubmit={_onSubmit}
          className="ml-auto mr-auto d-flex flex-wrap justify-content-center"
        >
          <div className="w-100 row mb-4">
            <div className="col-12 col-md-6 m-auto">
              <PasswordInput
                onChange={_handleChange}
                name="password"
                label={commonData.fixed.account_titles["password"].title}
                placeholder={commonData.fixed.account_titles["password"].title}
                error={submitError}
              />
            </div>
          </div>
          <div className="w-100 row mb-5">
            <div className="col-12 col-md-6 m-auto">
              <PasswordInput
                onChange={_handleChange}
                name="password_confirmation"
                label={
                  commonData.fixed.account_titles["confirm-password"].title
                }
                placeholder={commonData.fixed.account_titles["confirm-password"].title}
                error={submitError}
              />
            </div>
          </div>
          {/* <div className="w-100 mb-4 row">
          <div className="separator w-md-50 m-auto "></div>
        </div>{" "} */}
          <div className="d-md-flex  justify-content-end align-items-center col-12 col-md-6">
            <Button
              text={
                commonData.fixed.account_titles["reset-password-button"].title
              }
              type="submit"
              loading={submitLoader}
              className="ml-md-auto btn-outline-secondary mt-4 mt-md-0"
            />
          </div>
        </form>
      ) : (
        <h2>{commonData.fixed.account_titles["invalid-token"].title}</h2>
      )}
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default ResetPassord;

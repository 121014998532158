import { useContext, useEffect } from "react";
import DataContext from "../../../contexts/DataContext";
import {NavLink, Link, useLocation } from "react-router-dom";
import ShopBar from "../ShopBar";
import { useCookies } from "react-cookie";
import { getCart } from "../../../helper";

const DesktopNav = (props) => {
  const commonData = useContext(DataContext);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);


  const location = useLocation();

  const _handleSearchClick = (e) => {
    e.preventDefault();
    props.setIsSearchBarOpen(true);
  };
  const _handleOpenCart = () => {
    props.setIsCartOpen(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="desktop-nav justify-content-between align-items-center d-none d-xl-flex custom-container">
      <ul className="left-nav d-flex list-unstyled m-0 h-100 align-items-center">
        <li className="shop-nav d-flex align-items-center">
          <span
            className={"cursor-pointer d-flex align-items-center " + ((location.pathname.includes('/productos') || location.pathname === '/personalize') && "active") }
            onMouseEnter={() => {
              props.setIsShopOpen(true);
            }}
          >
            {commonData.fixed.menu_titles.shop.title}
          </span>
          <div
            onMouseLeave={() => {
              props.setIsShopOpen(false);
            }}
          >
            <ShopBar isOpen={props.isShopOpen} />
          </div>
        </li>
        <li>
          <NavLink to="/nosotros" activeClassName="active">
            {commonData.fixed.menu_titles.nosotros.title}
          </NavLink>
        </li>
        <li>
          <NavLink to="/inspiracion" activeClassName="active">
            {commonData.fixed.menu_titles.inspiracion.title}
          </NavLink>
        </li>
        <li className="mr-0">
          <NavLink to="/artistas" activeClassName="active">
            {commonData.fixed.menu_titles.artistas.title}
          </NavLink>
        </li>
      </ul>
      <div className="logo-container">
        <Link to="/">
          <img className="logo" src="/images/logo.svg" />{" "}
        </Link>
      </div>
      <div className="righ-nav ">
        <ul className="m-0  list-unstyled d-flex">
          <li>
            <a href="" onClick={_handleSearchClick}>
              {commonData.fixed.menu_titles.search.title}
            </a>
          </li>
          <li>
            <NavLink to="/contacta-con-nosotros" activeClassName="active">
              {commonData.fixed.menu_titles.contacto.title}
            </NavLink>
          </li>
          <li>
            <NavLink className={(location.pathname === '/accesso' || location.pathname === '/inscribirse') && "active"} to="/cuenta/mis-pedidos">
              {commonData.fixed.menu_titles.cuenta.title}
            </NavLink>
          </li>

          <li>
            {commonData.fixed.social_media.map(el => (
              el.title === 'Blog' ?
                <a href={el.link} target="_blank">
                  {el.title}
                </a>
                : null
            ))}
          </li>
          <li onClick={_handleOpenCart} className="cart cursor-pointer d-flex mr-0">
            <img className="cart-icon mr-1" src="/images/cart.svg" />
            <span>
              {commonData.cartItems && commonData.cartItems.length > 9
                ? "9+"
                : commonData.cartItems
                  ? commonData.cartItems.length
                  : 0}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default DesktopNav;

import { useEffect, useRef, useState } from "react";
import { WHO_WE_ARE } from "../api";
import Breadcrumbs from "../components/Breadcrumbs";
import useAxios from "../hooks/useAxios";
import Instagram from "../components/Instagram";
import Header from "../components/WhoWeAre/Header";
import WhoWeAreSection from "../components/WhoWeAreSection";
import useInView from "react-cool-inview";
import OurServices from "../components/LandingPage/OurServices";
import DataContext from "../contexts/DataContext";
import { useContext } from "react";

const WhoWeAre = () => {
  const ref = useRef();
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const commonData = useContext(DataContext);
  const [headerAnimationDone, setHeaderAnimationDone] = useState();
  useEffect(() => {
    console.log('who we are', WHO_WE_ARE);
    setHookOptions({
      url: WHO_WE_ARE,
    });
  }, []);
  return Object.keys(response).length ? (
    <div id="who-we-are-page">
      <div className="custom-container">
        <Breadcrumbs data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['nosotros'].title }]} />

      </div>
      <Header
        response={response}
        setHeaderAnimationDone={setHeaderAnimationDone}
        firstSectionMarginTop={
          ref.current
            ? window
              .getComputedStyle(ref.current)
              .getPropertyValue("margin-top")
            : ""
        }
      />
      <div
        ref={ref}
        className={`first-section ${headerAnimationDone ? "previous-animation-finished" : ""
          }`}
      >
        <WhoWeAreSection
          bigTitle={response.sections["nosotros"].big_title}
          smallTitle={response.sections["nosotros"].small_title}
          shortText={response.sections["nosotros"].short_text}
          longText={response.sections["nosotros"].long_text}
          image={response.sections["nosotros"].formatted_image}
        />
      </div>
      {/* <div
        className={`small-custom-container second-section ${observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
          }`}
        ref={observable.observe}
      >
        <img
          src={response.sections["second-section"].formatted_image}
          className="img-fluid w-100"
        />
      </div> */}
      {/* <div className="third-section">
        <WhoWeAreSection
          bigTitle={response.sections["compromiso"].big_title}
          smallTitle={response.sections["compromiso"].small_title}
          text={response.sections["compromiso"].text}
          image={response.sections["compromiso"].formatted_image}
          flipped={true}
        />
      </div> */}
      {/* <div className="custom-container">
        <OurServices response={response} />
      </div> */}
      <Instagram data={response.insta_feed} />
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default WhoWeAre;

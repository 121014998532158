export const isExternalLink = (link) => {
    return link.split('http://').length + link.split('https://').length > 2;
}
export const numberWithCommas=(x)=> {
    if(x===undefined)
    return null;
    x = x.toString();
    return x.replace('.',',');
}
export const updateCart=(cart)=>{
    localStorage.setItem('cartItems',JSON.stringify(cart))
}
export const getCart=()=>{
    return JSON.parse(localStorage.getItem('cartItems'));
}
import { useContext } from "react";
import useInView from "react-cool-inview";
import DataContext from "../../contexts/DataContext";
import CustomLink from "../CustomLink";

const Service = (props) => {
  const observeImages = useInView({
    unobserveOnEnter: true,
  });
  const observeText = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div className={`row`}>
      <div
        className={`col-md-6 services-text d-flex align-items-center flex-wrap ${props.index % 2 === 0 ? "order-1" : "order-2"
          } `}
      >
        <div
          ref={observeText.observe}
          className={`${observeText.inView
            ? " animate__animated animate__fadeIn "
            : "no-opacity"
            }`}
        >
          <h3 className="font-weight-bold service-title">
            {props.service.title}
          </h3>
          <div
            className="service-text"
            dangerouslySetInnerHTML={{ __html: props.service.text }}
          />
          {props.service.link_text ?
            <CustomLink link={props.service.link} className="service-link">
              <span>{props.service.link_text}</span>
              <img src="/images/search-arrow.svg" alt="arrow" />
            </CustomLink> : ""}
        </div>
      </div>
      <div
        ref={observeImages.observe}
        className={`${observeImages.inView
          ? " animate__animated animate__fadeIn "
          : "no-opacity"
          } services-img col-md-6 ${props.index % 2 === 0 ? "order-2" : "order-1"
          }`}
        style={{ background: props.service.background_color }}
      >
        {props.service.link_text ?
          <CustomLink link={props.service.link}
            className='img-container hoverable d-inline-block'
            style={{
              backgroundImage: `url('${props.service.formatted_image}')`,
            }}
          >
          </CustomLink>
          :
          <div
            className='img-container'
            style={{
              backgroundImage: `url('${props.service.formatted_image}')`,
            }}
          ></div>}
      </div>
    </div >
  );
};
const OurServices = (props) => {
  const commonData = useContext(DataContext);

  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div id="our-services">
      <div
        className={`small-heading subheading text-center font-weight-medium  ${inView ? "animate__animated animate__fadeIn " : "no-opacity"
          }`}
        ref={observe}
      >
        {/* {commonData.fixed.landing_page_titles["our-services-heading"]} */}
      </div>
      <h2
        className={`text-center d-md-block d-none font-weight-medium  ${inView ? "animate__animated animate__fadeIn " : "no-opacity"
          }`}
      >
        {commonData.fixed.landing_page_titles["our-services"].title}
      </h2>
      <h2
        className={`text-center d-block d-md-none  ${inView ? "animate__animated animate__fadeIn " : "no-opacity"
          }`}
      >
        {commonData.fixed.landing_page_titles["our-services-mobile"].title}
      </h2>
      <div className="our-services-content">
        {props.response.services.map((service, index) => (
          <Service service={service} index={index} key={Math.random()} />
        ))}
      </div>
    </div>
  );
};
export default OurServices;

import { useContext, useEffect, useState } from "react";
import useInView from "react-cool-inview";
import { useParams } from "react-router-dom";
import { SINGLE_ARTIST } from "../api";
import Info from "../components/Artists/Info";
import SimilarArtists from "../components/Artists/SimilarArtists";
import Works from "../components/Artists/Works";
import Breadcrumbs from "../components/Breadcrumbs";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";

const SingleArtist = () => {
  const routeParams = useParams();
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const [areAllWorkImagesLoaded, setAreAllWorkImagesLoaded] = useState(false);
  const [areAllRelatedImagesLoaded, setAreAllRelatedImagesLoaded] =
    useState(false);
  const commonData = useContext(DataContext);
  useEffect(() => {
    setHookOptions({
      url: SINGLE_ARTIST(routeParams.slug),
    });
  }, []);

  useEffect(() => {
    setHookOptions({
      url: SINGLE_ARTIST(routeParams.slug),
    });
  }, [routeParams]);

  const _handleOnAllLoadedRelated = () => {
    setAreAllRelatedImagesLoaded(true);
  };
  const _handleOnAllLoadedWorks = () => {
    setAreAllWorkImagesLoaded(true);
  };

  return Object.keys(response).length ? (
    <div id="single-artist" className="overflow-hidden">
      <div className="custom-container">
        <Breadcrumbs
          data={[
            {
              name: commonData.fixed.breadcrumbs['home'].title,
              url: "/",
            },
            {
              name: commonData.fixed.breadcrumbs['artistas'].title,
              url: "/artistas",
            },
            { name: response.artist.name },
          ]}
        />
      </div>
      <Info data={response.artist} />
      <Works
        data={response.artist.products}
        onAllLoaded={_handleOnAllLoadedWorks}
      />
      {response.artist.similar_artists.length ?
        <SimilarArtists
          data={response.artist.similar_artists}
          onAllLoaded={_handleOnAllLoadedRelated}
        /> : ""}
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default SingleArtist;

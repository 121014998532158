import Breadcrumbs from "../components/Breadcrumbs";

import useAxios from "../hooks/useAxios";
import { useState, useEffect } from "react";
// import Dropdown from "../components/Products/Dropdown";
import Button from "../components/Button";
import { useCookies } from "react-cookie";
import {
  ADDRESSES,
  CHECK_AVAILABILITY,
  COUNTRIES,
  COUPON,
  TOTAL,
  UPDATE_CART,
  SUBMIT_ORDER
} from "../api";
import { useContext } from "react";
import DataContext from "../contexts/DataContext";
import ProductRow from "../components/Cart/ProductRow";
import AddressSection from "../components/Checkout/AddressSection";
import Input from "../components/Input/Input";
import Checkbox from "../components/Input/Checkbox";
import Gateways from "../components/Checkout/Gateways";
import { numberWithCommas } from "../helper";

const Checkout = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const commonData = useContext(DataContext);

  const [
    availabilityResponse,
    availabilityError,
    availabilityLoader,
    availabilitySetHookOptions,
    availabilitySnackbar,
    availabilityStatus,
  ] = useAxios();
  const [
    updateCartResponse,
    updateCartError,
    updateCartLoader,
    updateCartSetHookOptions,
    updateCartSnackbar,
    updateCartStatus,
  ] = useAxios();
  const [
    couponResponse,
    couponError,
    couponLoader,
    couponSetHookOptions,
    couponSnackbar,
    couponStatus,
  ] = useAxios();
  const [
    checkoutResponse,
    checkoutError,
    checkoutLoader,
    checkoutSetHookOptions,
    checkoutSnackbar,
    checkoutStatus,
  ] = useAxios();
  const [countriesResponse, countriesError, countriesLoader, countriesSetHookOptions, countriesSnackbar, countriesStatus] =
    useAxios();
  const [addressesResponse, addressesError, addressesLoader, addressesSetHookOptions, addressesSnackbar, addressesStatus] =
    useAxios();
  const [gatewayResponse, gatewayError, gatewayLoader, gatewaySetHookOptions, gatewaySnackbar, gatewayStatus] =
    useAxios();

  const [formError, setFormError] = useState({});
  const [countries, setCountries] = useState();
  const [outOfStock, setOutOfStock] = useState();
  const [coupon, setCoupon] = useState();
  const [couponValue, setCouponValue] = useState();
  const [couponAppliedValue, setCouponAppliedValue] = useState();
  const [couponApplied, setCouponApplied] = useState();
  const [extraFormData, setExtraFormData] = useState({
    email: cookies.token ? commonData.fixed.user.email : "",
    email_offers: ""
  });
  const [shippingIsBilling, setShippingIsBilling] = useState(true);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const _handleOrderAvailable = () => {
    const _cartItems = [...commonData.cartItems];
    const temp = availabilityResponse.items_exceeded.map((el) => {
      const index = _cartItems.findIndex((el2) => el2.id == el.id);
      _cartItems[index].quantity = el.remaining_quantity;
    });
    commonData.setCartItems(_cartItems);
    commonData.setCartItemsSetHookOptions({
      method: "POST",
      url: UPDATE_CART,
      data: { items: _cartItems },
    });
  };
  const _handleShippingAddressChange = (index) => {
    if (index == selectedShippingAddress)
      setSelectedShippingAddress(null);
    else
      setSelectedShippingAddress(index);
  }
  const _handleBillingAddressChange = (index) => {
    if (index == selectedBillingAddress)
      setSelectedBillingAddress(null);
    else
      setSelectedBillingAddress(index);
  }
  const addNewBillingAddress = (data, countries, provinces) => {
    data.country = countries.find(el => el.id == data.country_id);
    data.province = provinces.find(el => el.id == data.province_id);
    setBillingAddresses([data, ...billingAddresses]);
    setSelectedBillingAddress(0)
  }
  const addNewShippingAddress = (data, countries, provinces) => {
    data.country = countries.find(el => el.id == data.country_id);
    data.province = provinces.find(el => el.id == data.province_id);
    setShippingAddresses([data, ...shippingAddresses]);
  }
  useEffect(() => {
    if (shippingAddresses.length)
      setSelectedShippingAddress(0);
  }, [shippingAddresses])
  const _handleChange = (e) => {
    setExtraFormData({ ...extraFormData, [e.target.name]: e.target.value });
  };
  const _handleCheck = (name) => {
    setExtraFormData({ ...extraFormData, [name]: !extraFormData[name] });
  };
  const _toggleBilling = () => {
    setShippingIsBilling(!shippingIsBilling)
  }
  const _handleCoupon = () => {
    if (coupon) {
      couponSetHookOptions({
        url: COUPON,
        data: { coupon: coupon },
        method: "POST",
      });
      setCouponApplied(true);
    }
  };
  const _handleChangeCoupon = (e) => {
    setCoupon(e.target.value);
  };
  const order = () => {
    const shippingAddress = shippingAddresses[selectedShippingAddress];
    let billingAddress = billingAddresses[selectedBillingAddress];
    if (!selectedBillingAddress) {
      billingAddress = shippingAddress
    }
    gatewaySetHookOptions({
      url: SUBMIT_ORDER,
      data: {
        'email': cookies.token ? commonData.fixed.user.email : extraFormData.email,
        'shipping_name': shippingAddress.name,
        'shipping_directions': shippingAddress.directions,
        'shipping_business': shippingAddress.business,
        'shipping_address': shippingAddress.address,
        'shipping_postal_code': shippingAddress.postal_code,
        'shipping_city': shippingAddress.city,
        'shipping_province_id': shippingAddress.province_id,
        'shipping_country_id': shippingAddress.country_id,
        'shipping_phone_number': shippingAddress.phone_number,
        'billing_name': billingAddress.name,
        'billing_directions': billingAddress.directions,
        'billing_business': billingAddress.business,
        'billing_address': billingAddress.address,
        'billing_postal_code': billingAddress.postal_code,
        'billing_city': billingAddress.city,
        'billing_province_id': billingAddress.province_id,
        'billing_country_id': billingAddress.country_id,
        'billing_phone_number': billingAddress.phone_number,
        'coupon': couponAppliedValue,
        'terms': extraFormData.terms
      },
      method: "POST"
    })
  }

  useEffect(() => {
    if (couponStatus == 200) {
      setCouponValue(couponResponse.amount)
    };
  }, [couponLoader]);
  useEffect(() => {
    setCouponAppliedValue(coupon);
    setCoupon("");
  }, [couponResponse.amount]);
  useEffect(() => {
    checkoutSetHookOptions({
      url: TOTAL,
    });
    availabilitySetHookOptions({
      url: CHECK_AVAILABILITY,
    });
  }, []);
  useEffect(() => {
    countriesSetHookOptions({
      url: COUNTRIES
    })
  }, [])
  useEffect(() => {
    if (countriesStatus == 200)
      setCountries(countriesResponse.countries);
  }, [countriesLoader])
  useEffect(() => {
    if (
      availabilityStatus == 200 &&
      availabilityResponse.items_exceeded &&
      availabilityResponse.items_exceeded.length
    ) {
      setOutOfStock(true);
    }
  }, [availabilityLoader]);
  useEffect(() => {
    if (cookies.token)
      addressesSetHookOptions({
        url: ADDRESSES
      })
  }, []);
  useEffect(() => {
    if (addressesStatus == 200) {
      setShippingAddresses(addressesResponse.shipping_addresses);
      setBillingAddresses(addressesResponse.billing_addresses);
      setSelectedBillingAddress(0);
      setSelectedShippingAddress(0);
    }
  }, [addressesStatus]);
  useEffect(() => {
    if (commonData.setCartItemsStatus == 200) setOutOfStock(false);
  }, [commonData.setCartItemsLoader]);
  return (addressesStatus == 200 || !cookies.token) && availabilityStatus == 200 ? (
    <div id="checkout">
      {outOfStock ? (
        <div className="overlay-2">
          <div className="out-of-stock p-5">
            <p>
              The following items are not available in the quantity selected:
            </p>
            {availabilityResponse.items_exceeded.map((el) => (
              <div className="d-flex justify-content-between">
                <div>{el.product_price.product.name}</div>
                <div>Remaining quantity {el.remaining_quantity}</div>
              </div>
            ))}
            <div className=" buttons-container-2  m-auto">
              <div className="text-center ">
                <Button text="Back To Cart" to="cesta" />
              </div>
              <div className="text-center">
                <Button
                  text="Order Available"
                  className="btn-outline-secondary"
                  onClick={_handleOrderAvailable}
                  loader={commonData.setCartItemsLoader}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className=" custom-container row">
        <div className="col-xl-7 pl-0">
          <div className="payment-container-parent">
            <div className="payment-container">
              <Breadcrumbs
                data={[
                  {
                    name: commonData.fixed.breadcrumbs['cart'].title,
                    url: "/cesta",
                  },
                  {
                    name: commonData.fixed.breadcrumbs['pay'].title,
                  },
                ]}
              />
              {gatewayStatus !== 200 ? (
                <>
                  <div className={`email ${cookies.token ? "d-none" : ''}`}>
                    <h3 className="f-24 font-weight-medium">
                      {commonData.fixed.checkout_fixed_titles['contact-information'].title}
                    </h3>
                    <Input
                      placeholder={commonData.fixed.checkout_fixed_titles['email-address'].title}
                      name="email"
                      error={gatewayError}
                      onChange={_handleChange}
                      type="text"
                      label=""
                      value={extraFormData.email}
                    />
                    <div className="email-offers-container mt-3">
                      <Checkbox
                        checked={extraFormData.email_offers}
                        name="email_offers"
                        label={commonData.fixed.checkout_fixed_titles['email-offers'].title}
                        onCheck={() => {
                          _handleCheck("email_offers");
                        }}
                        error={formError}
                      />
                    </div>
                  </div>
                  <h3 className="mb-3">{commonData.fixed.checkout_fixed_titles['shipping-address-title'].title}</h3>
                  <small className="subtitle mb-0">{commonData.fixed.checkout_fixed_titles['shipping-address-subtitle'].title}</small>
                  <AddressSection
                    disableKeepInformation={cookies.token == undefined}
                    newAddress={commonData.fixed.checkout_fixed_titles['new-shipping-address'].title}
                    submitText={commonData.fixed.checkout_fixed_titles['add-address'].title}
                    setFormError={setFormError}
                    selectedAddressIndex={selectedShippingAddress}
                    data={shippingAddresses}
                    onSubmit={addNewShippingAddress}
                    onChange={_handleShippingAddressChange}
                    extraData={{
                      is_billing: false
                    }}
                  />
                  <h3 className="mb-3 mt-5">{commonData.fixed.checkout_fixed_titles['billing-address-title'].title}</h3>
                  <small className="subtitle mb-0">
                    {commonData.fixed.checkout_fixed_titles['billing-address-subtitle'].title}

                  </small>
                  <div className="form-check mt-5">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="same" checked={shippingIsBilling == true} onChange={_toggleBilling} />
                    <label className="form-check-label" htmlFor="same">
                      La misma dirección de envío
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="different" checked={shippingIsBilling == false} onChange={_toggleBilling} />
                    <label className="form-check-label" htmlFor="different">
                      Usar una dirección de facturación distinta
                    </label>
                  </div>
                  {!shippingIsBilling ?
                    <AddressSection
                      newAddress={commonData.fixed.checkout_fixed_titles['new-billing-address'].title}
                      submitText={commonData.fixed.checkout_fixed_titles['add-address'].title}
                      setFormError={setFormError}
                      selectedAddressIndex={selectedBillingAddress}
                      extraData={{
                        is_billing: true
                      }}
                      data={billingAddresses}
                      onSubmit={addNewBillingAddress}
                      onChange={_handleBillingAddressChange} />
                    : ""}
                  {selectedShippingAddress !== null && (selectedBillingAddress !== null || shippingIsBilling == true) ?
                    <div className='mt-5'>
                      <div className="terms mb-3">
                        <Checkbox
                          checked={extraFormData.terms}
                          name="terms"
                          html={commonData.fixed.checkout_fixed_titles['terms'].text}
                          onCheck={() => {
                            _handleCheck("terms");
                          }}
                          error={gatewayError}
                        />
                      </div>
                      <Button
                        text={commonData.fixed.checkout_fixed_titles['pay-using-credit-card'].title}
                        className="btn-outline-secondary "
                        loading={gatewayLoader}
                        type="submit"
                        onClick={order}
                      />
                    </div> : ""}
                </>
              ) : (
                <Gateways
                  total={gatewayResponse.amount ? gatewayResponse.amount : (couponResponse.amount || checkoutResponse.total)}
                  gatewayData={gatewayResponse}
                  shippingAddress={shippingAddresses[selectedShippingAddress]}
                  billingAddress={selectedBillingAddress ? billingAddresses[selectedBillingAddress] : shippingAddresses[selectedShippingAddress]}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-5 bg-white text-center position-relative coupons-container">
          <div className="position-absolute fake-bg"></div>
          <div
            className="position-relative coupons-sub-container"
            style={{ zIndex: 2 }}
          >
            <h3 className="font-weight-medium f-24 font-weight-medium">
              {commonData.fixed.checkout_fixed_titles['este-o-este'].title}
            </h3>
            <div className="coupons-products">
              {commonData.cartItems.map((el, index) => (
                <ProductRow {...el} layout="checkout" key={index} />
              ))}
            </div>
            {Object.keys(gatewayResponse).length == 0 ?
              <div className="coupon-input">
                <div className="d-flex ">
                  <input
                    placeholder={commonData.fixed.checkout_fixed_titles['discount'].title}
                    onChange={_handleChangeCoupon}
                    value={coupon}
                  />
                  <Button
                    text={commonData.fixed.checkout_fixed_titles['apply-discount'].title}
                    className="btn-outline-secondary"
                    onClick={_handleCoupon}
                    value={coupon}
                    loading={couponLoader}
                    disabled={!coupon}
                  />
                </div>
                <div className="mt-2 text-left">
                  {couponError.messages ? couponError.messages[0] : ""}
                </div>
              </div> : ""}
            <div className="mt-4 pb-4 subtotal-1">
              <div className="d-flex justify-content-between mb-2">
                <span>{commonData.fixed.checkout_fixed_titles['subtotal'].title}:</span>
                <span className="font-weight-medium">
                  {numberWithCommas(checkoutResponse.total)}
                  <span className="font-family-lucida">€</span>
                </span>
              </div>
              {couponValue ?
                <div className="d-flex justify-content-between">
                  <span>{commonData.fixed.checkout_fixed_titles['coupon-code-applied'].title}: </span>
                  <span>{numberWithCommas(couponAppliedValue)}</span>
                </div>
                : ""}
              {couponValue ?
                <div className="d-flex justify-content-between">
                  <span>{commonData.fixed.checkout_fixed_titles['value-after-coupon'].title}: </span>
                  <span>{numberWithCommas(couponValue)}€</span>
                </div>
                : ""}
              <div className="d-flex justify-content-between">
                <span>Envios:</span>
                <span className="second-currency">
                  {numberWithCommas(gatewayResponse?.transportation)}
                  {gatewayResponse.transportation ? "€" : ""}
                </span>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-between pb-4">
              <span className="font-weight-medium">{commonData.fixed.checkout_fixed_titles['total'].title}</span>
              <h3 className="font-weight-medium m-0">
                {numberWithCommas(gatewayResponse.amount ? gatewayResponse.amount : (couponResponse.amount || checkoutResponse.total))}
                <span className="font-family-lucida m-0">€</span>{" "}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="global-spinner">{checkoutLoader}</div>
  );
};
export default Checkout;

import { useContext, useState, useEffect } from "react";
import useInView from "react-cool-inview";
import { SUBSCRIBE } from "../../api";
import DataContext from "../../contexts/DataContext";
import useAxios from "../../hooks/useAxios";
import { useLocation, Link, NavLink } from "react-router-dom";
import { isExternalLink } from "../../helper";
import CustomLink from "../CustomLink";
const Footer = () => {
  const [formdata, setFormdata] = useState({
    email: "",
  });
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const location = useLocation();
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  const commonData = useContext(DataContext);
  useEffect(() => {
    setFormdata({ email: "" });
  }, [response]);
  const _handleSubmit = (e) => {
    e.preventDefault();
    setHookOptions({
      url: SUBSCRIBE,
      data: formdata,
      method: "POST",
    });
  };
  const _handleChange = (e) => {
    setFormdata({ email: e.target.value });
  };
  const _handleMoveUp = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <footer
      ref={observe}
      className={`${location.pathname == "/checkout" ? "checkout" : ""} ${inView ? " animate__animated animate__fadeIn" : "no-opacity"
        }`}
    >
      <div className="footer-container position-relative">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              <div className="sitemap-container">
                <small className="font-weight-bold">
                  {commonData.fixed.titles["footer-sitemap-title"]}
                </small>
                <ul className="list-unstyled">
                  {commonData.fixed.sitemap.map((el) => (
                    <li key={Math.random()}>
                      <NavLink to={el.link}>{el.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <small className="font-weight-bold">
                  {commonData.fixed.titles["footer-social-media"]}
                </small>
                <ul className="list-unstyled">
                  {commonData.fixed.social_media.map((el) => (
                    <li key={Math.random()}>
                      <a target="_blank" href={el.link}>
                        {el.title}
                        <img
                          className="external-link-icon"
                          src="/images/external-link.svg"
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 subscribe-col">
            <small className="font-weight-bold">
              {commonData.fixed.titles["footer-subscribe-title"]}
            </small>
            <form className="subscribe-container" onSubmit={_handleSubmit}>
              <input
                value={formdata.email}
                onChange={_handleChange}
                placeholder={
                  commonData.fixed.titles["footer-input-placeholder"]
                }
              />
              <button
                type="submit"
                className="subscribe-button position-absolute "
              >
                {loader ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <small className="font-weight-bold">
                    {commonData.fixed.titles["footer-subscribe-button"]}
                  </small>
                )}
              </button>
            </form>
            {Object.keys(response) && !error.messages ? (
              <div className="animate__animated animate__slideInDown">
                <small>{Object.keys(response).length ? "success" : ""}</small>
              </div>
            ) : error.messages.length ? (
              <div className="animate__animated animate__slideInDown">
                <small>{error.messages[0]}</small>
              </div>
            ) : (
              ""
            )}
            <div className="contact-email">
              <small className="font-weight-bold">
                {commonData.fixed.titles["footer-contact-title"]}
              </small>
              <div>
                <a href={`mailto:${commonData.fixed.titles["footer-contact-email"]}`}>{commonData.fixed.titles["footer-contact-email"]}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row copyrights">
          <div className="col-lg-2 order-2 order-lg-1">
            <small className="font-weight-bold ">
              {commonData.fixed.titles["footer-copyrights"].replace("%%year%%",
                new Date().getFullYear())}
            </small>
          </div>
          <div className="col-lg-10 terms-col order-1 order-lg-2">
            <div className="row justify-content-end">
              <div className="col-lg-auto p-0">
                <Link to={"/cookies"}>
                  <small className="font-weight-bold">
                    {commonData.fixed.fixed_links["privacy-cookies"].title}
                  </small>
                </Link>
              </div>
              <div className="col-lg-auto p-0">
                <Link to={"/terminos-y-condiciones"}>
                  <small className="font-weight-bold">
                    {commonData.fixed.fixed_links["terms-conditions"].title}
                  </small>
                </Link>
              </div>
              {/* <div className="col-lg-auto p-0">
                <Link to={"/envios"}>
                  <small className="font-weight-bold">
                    {commonData.fixed.fixed_links["shipment"].title}
                  </small>
                </Link>
              </div> */}
              <div className="col-lg-auto p-0">
                <Link to={"/politica-de-privacidad"}>
                  <small className="font-weight-bold">
                    {commonData.fixed.fixed_links["privacy-policy"].title}
                  </small>
                </Link>
              </div>
              <div className="col-lg-auto p-0">
                <Link to={"/aviso-legal"}>
                  <small className="font-weight-bold">
                    {commonData.fixed.fixed_links["legal-advice"].title}
                  </small>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <a className="move-up position-absolute" onClick={_handleMoveUp}>
          <small className="font-weight-bold">
            {commonData.fixed.titles["volver"]}
          </small>{" "}
          <img src="/images/volver-arrow.svg" />
        </a>
      </div>
    </footer>
  );
};
export default Footer;

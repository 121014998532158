import Layout from "../components/Accounts/Layout";
import { useState, useContext, useEffect } from "react";
import AddressForm from "../components/AddressForm";
import DataContext from "../contexts/DataContext";
import Checkbox from "../components/Input/Checkbox";
import { Link } from "react-router-dom"
import Button from "../components/Button";
import { SINGLE_ADDRESS, ADDRESSES } from "../api";
import useAxios from "../hooks/useAxios";
import React from "react";
import AddressBox from "../components/AddressBox";
import axios from "axios";

const Addresses = () => {
    const [active, setActive] = useState();
    const [formResponse, setFormResponse] = useState();
    const [formError, setFormError] = useState();
    const [formLoader, setFormLoader] = useState();
    const [formStatus, setFormStatus] = useState();
    const [extraData, setExtraData] = useState({
        duplication: false,
        is_billing: false
    });
    const [response, error, loader, setHookOptions, snackbar, status] =
        useAxios();
    const [deleteResponse, deleteError, deleteLoader, deleteSetHookOptions, deleteSnackbar, deleteStatus] =
        useAxios();
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [billingAddresses, setBillingAddresses] = useState([]);
    const commonData = useContext(DataContext)
    const _toggleAddressType = (is_billing) => {
        setExtraData({ ...extraData, is_billing: is_billing });
    }
    const _handleDelete = async (id, isBilling) => {
        // deleteSetHookOptions({
        //     method: "DELETE",
        //     url: SINGLE_ADDRESS(id)
        // })

        const res = await axios.delete(SINGLE_ADDRESS(id));

        console.log(res);

        if (isBilling)
            setBillingAddresses(billingAddresses.filter(el => el.id != id))
        else
            setShippingAddresses(shippingAddresses.filter(el => el.id != id))
    }
    const _handleEdit = (id, isBilling) => {
        setExtraData({
            ...extraData, is_billing: isBilling
        })
        setActive(id);
        window.scrollTo({
            top: document.getElementById('form').getBoundingClientRect().top - 250,
            left: 0,
            behavior: "smooth",
        });

    }
    const renderFooter = (setFormData) => <>
        <div className="col-12 keep-information pl-0">
            <Checkbox
                name="duplication"
                label={commonData.fixed.account_titles['use-as-billing-address'].title}
                onCheck={() => {
                    setExtraData({ ...extraData, 'duplication': !extraData.duplication })
                }}
                checked={extraData.duplication}
                error={formError}
            />
        </div>

        <div className="d-flex align-items-center flex-wrap">
            <Button
                text={commonData.fixed.account_titles['address-save'].title}
                className="btn-outline-secondary w-auto pl-5 pr-5"
                loading={formLoader}
                type="submit"
            />
            <a className="color-unset ml-4 cursor-pointer" onClick={() => setFormData({
                email: "",
                email_offers: "",
                name: "",
                business: "",
                directions: "",
                address: "",
                postal_code: "",
                city: "",
                province_id: null,
                country_id: null,
                phone_number: "",
                keep_information: false,
            })}>
                {commonData.fixed.account_titles['address-cancel'].title}
            </a>
        </div>
    </>

    useEffect(() => {
        setHookOptions({
            url: ADDRESSES
        })
    }, []);
    useEffect(() => {
        if (formStatus == 200) {
            setShippingAddresses(formResponse.shipping_addresses);
            setBillingAddresses(formResponse.billing_addresses);
        }
    }, [formLoader]);
    useEffect(() => {
        if (status == 200) {
            setShippingAddresses(response.shipping_addresses);
            setBillingAddresses(response.billing_addresses);
            setExtraData({
                ...extraData, duplication: false
            })
        }
    }, [loader]);
    return <Layout active="directions">
        {status == 200 ?
            <div id="addresses">
                <div className="row">
                    <div className="col-lg-6">
                        <div className='boxes-title'>
                            <small>
                                {commonData.fixed.account_titles['shipping-address'].title}
                            </small>
                        </div>
                        {
                            shippingAddresses.map(el => <AddressBox
                                key={el.id}
                                onEdit={() => { _handleEdit(el.id, false) }} {...el}
                                onDelete={() => { _handleDelete(el.id, false) }}
                            />)
                        }
                        <a className='new-address d-flex cursor-pointer' onClick={() => _toggleAddressType(0)}>
                            <img src="/images/plus-accordion.svg" className="plus mr-2" />
                            <span className="text-underline">{commonData.fixed.account_titles['new-shipping-address'].title}</span>
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <div className='boxes-title'>
                            <small>
                                {commonData.fixed.account_titles['billing-address'].title}
                            </small>
                        </div>
                        {
                            billingAddresses.map(el => <AddressBox
                                key={el.id}
                                onEdit={() => { _handleEdit(el.id, true) }} {...el}
                                onDelete={() => { _handleDelete(el.id, true) }}
                            />)
                        }
                        <a className='new-address d-flex cursor-pointer' onClick={() => _toggleAddressType(1)}>
                            <img src="/images/plus-accordion.svg" className="plus mr-2" />
                            <span className="text-underline">{commonData.fixed.account_titles['new-billing-address'].title}</span>
                        </a>
                    </div>

                </div>
                <div className="row form-container ml-0 mr-0">
                    <AddressForm
                        data={active ? (extraData.is_billing ? billingAddresses.find(el => el.id == active) : shippingAddresses.find(el => el.id == active)) : null}
                        extraData={extraData}
                        setFormLoader={setFormLoader}
                        setFormStatus={setFormStatus}
                        submitURL={active ? SINGLE_ADDRESS(active) : ADDRESSES}
                        setFormResponse={setFormResponse}
                        setFormError={setFormError}
                        title={commonData.fixed.account_titles[extraData.is_billing ? 'new-billing-address' : 'new-shipping-address'].title}
                        disableKeepInformation={true}
                        footer={renderFooter}
                    />
                </div>
            </div> :
            (
                <div className="d-flex align-items-center justify-content-center"> {loader}</div>

            )}
    </Layout >
}
export default Addresses;
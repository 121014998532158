import { Link } from "react-router-dom";
import { useContext } from "react";
import DataContext from "../contexts/DataContext";
const RegistrationLayout = (props) => {
  const commonData = useContext(DataContext);
  return (
    <div className="registration custom-container" id="registration">
      <div className="row">
        <div className="col-12 col-md-2">
          <h3 className="f-24 my-account text-nowrap">
            {commonData.fixed.account_titles["my_account"].title}
          </h3>
          <div
            className={`registration-toggle ${
              props.active == "login" ? "active" : ""
            }`}
          >
            <img src="/images/search-arrow.svg" />
            <span className="link-container">
              <Link to="/accesso">
                {commonData.fixed.account_titles["sidebar-login"].title}
              </Link>
            </span>
          </div>{" "}
          <div
            className={`registration-toggle ${
              props.active == "signup" ? "active" : ""
            }`}
          >
            <img src="/images/search-arrow.svg" />{" "}
            <span className="link-container">
              <Link to="/inscribirse">
                {commonData.fixed.account_titles["sidebar-signup"].title}
              </Link>
            </span>
          </div>
        </div>
        <div className="col-12 col-md-9">{props.children}</div>
      </div>
    </div>
  );
};
export default RegistrationLayout;

import { useEffect, useState } from "react";
import useInView from "react-cool-inview";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { numberWithCommas } from "../../helper";

const ProductGridElement = (props) => {
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const findMinPrice = () => {
    let minIndex = -1;
    let min = 999999999999999;
    props.price.map((price, index) => {
      if (price.price < min) {
        min = price.price;
        minIndex = index;
      }
    });
    return min;
  };
  return (
    <LazyLoad once offset={100}>
      <Link

        ref={observable.observe}
        className={`product ${observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
          }`}
        to={{ pathname: `/productos/${props.category}/${props.subcategory}/${props.slug}`, state: { from: props.from } }}
      >
        <div
          className={`img-container w-100 d-flex align-items-center justify-content-center ${observable.inView
            ? "animate__animated animate__fadeIn"
            : "no-opacity"
            }`}
        >
          <img
            src={props.formatted_image}
            className="product-image"
            onLoad={props.onLoad}
          />
          <img
            className="hovered-image no-opacity"
            src={props.formatted_hover_image}
          />
        </div>
        <div className="name">{props.name}</div>
        <div className="price">
          <span className="font-weight-bold">
            {numberWithCommas(props.min_price)}
          </span>
          <span className="currency font-family-lucida">€</span>
        </div>
      </Link>
    </LazyLoad >
  );
};
export default ProductGridElement;

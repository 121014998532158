import { useContext, useEffect, useState } from "react";
import DataContext from "../../contexts/DataContext";
import { useHistory, useLocation } from "react-router-dom";
import useInView from "react-cool-inview";
import Button from "../Button";
import Row from "./Row";
const Grid = (props) => {
  const commonData = useContext(DataContext);
  const observableGridFilters = useInView({
    unobserveOnEnter: true,
  });
  const history = useHistory();
  const location = useLocation();

  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [data, setData] = useState([]);
  const [isSmallGrid, setIsSmallGrid] = useState(
    props.isSmallGrid != null ? props.isSmallGrid : false
  );
  const [dropdown, setDropdown] = useState(props.sortingDropdown);
  const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(0);

  const _handleDropdownOpen = () => {
    setIsDropdownShown(!isDropdownShown);
  };
  const _toggleGrid = () => {
    setIsSmallGrid(!isSmallGrid);
  };
  const _toggleDropdown = (index) => {
    setSelectedDropdownIndex(index);
    setIsDropdownShown(false);
  };

  useEffect(() => {
    if (props.data.current_page > 1) setData([...data, ...props.data.data]);
    else setData(props.data.data);
  }, [props.data]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("sortBy", dropdown[selectedDropdownIndex].slug);
    history.push({ search: params.toString() });
  }, [selectedDropdownIndex]);

  return (
    <div className="shop-grid custom-container">
      <div
        className={`filtering d-flex flex-wrap justify-content-between justify-content-md-end align-items-center ${observableGridFilters.inView
          ? "animate__animated animate__fadeIn"
          : "no-opacity"
          }`}
        ref={observableGridFilters.observe}
      >
        <span className="products-total">
          {props.titles["products-count"]} ({props.data.total})
        </span>
        <div className="col-display ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            className={`d-none d-md-inline-block display-two-col ${isSmallGrid ? "active" : ""
              }`}
            onClick={_toggleGrid}
          >
            <path
              id="View_x2"
              d="M6,10V6h4v4ZM0,10V6H4v4ZM6,4V0h4V4ZM0,4V0H4V4Z"
            />
          </svg>
          <svg
            className={`d-inline-block d-md-none display-two-col ${isSmallGrid ? "active" : ""
              }`}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            onClick={_toggleGrid}
          >
            <rect id="Rectangle" width="16" height="16" fill="#c1c1c1" />
          </svg>
          <img className="separator" src="/images/separator.svg" />
          <svg
            className={`d-none d-md-inline-block display-four-col ${isSmallGrid ? "" : "active"
              }`}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            onClick={_toggleGrid}
          >
            <path
              id="View_x4"
              d="M8,10V8h2v2ZM4,10V8H6v2ZM0,10V8H2v2ZM8,6V4h2V6ZM4,6V4H6V6ZM0,6V4H2V6ZM8,2V0h2V2ZM4,2V0H6V2ZM0,2V0H2V2Z"
            />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            className={`d-md-none d-inline-block display-two-col ${isSmallGrid ? "" : "active"
              }`}
            onClick={_toggleGrid}
          >
            <path
              id="View_x2"
              d="M6,10V6h4v4ZM0,10V6H4v4ZM6,4V0h4V4ZM0,4V0H4V4Z"
            />
          </svg>
        </div>
        <div className="dropdown">
          {
            dropdown && dropdown.length ?
              <>
                {props.titles["order-with"]}
                <span className="dropdown-toggle " onClick={_handleDropdownOpen}>
                  <span className="d-none d-md-inline">
                    : {dropdown[selectedDropdownIndex].title}
                  </span>
                </span>
                <div
                  className={`dropdown-menu  ${isDropdownShown ? "show" : ""}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  {dropdown
                    .map((el, index) => (
                      <a
                        key={Math.random()}
                        className="dropdown-item"
                        onClick={() => _toggleDropdown(index)}
                      >
                        {el.title}
                      </a>
                    ))
                    .filter((el, index) => index != selectedDropdownIndex)}
                </div>
              </> : ""
          }

        </div>
      </div>

      {
        props.reloadingData && props.loading ? (
          <div className="text-center mt-5 mb-5">
            <div className={`spinner-border `} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : props.row ? (
          props.row(data)
        ) : (
          <Row
            data={data}
            isSmallGrid={isSmallGrid}
            renderElement={props.renderElement}
            direction={props.direction}
            colClasses={props.colClasses}
          />
        )
      }

      {
        props.data.current_page != props.data.last_page &&
          !(props.loading && props.reloadingData) ? (
          <Button
            onClick={() => {
              props.loadMore(props.data.current_page + 1);
            }}
            loading={props.loading && !props.reloadingData}
            text={commonData.fixed.shop_titles["load-more"]}
            className="m-auto "
          />
        ) : (
          ""
        )
      }
    </div >
  );
};
export default Grid;

import { useRef } from "react";

const Header = (props) => {
  const ref = useRef();
  const _handleArrowClick = () => {
    window.scrollTo({
      top: ref.current.clientHeight + parseFloat(props.firstSectionMarginTop),
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <header ref={ref}>
      <h1 className="text-center font-weight-medium animate__animated animate__fadeInDown">
        {props.response.header.title}
      </h1>
      <h1 className="text-center font-weight-medium animate__animated animate__fadeInDown">
        {props.response.header.subtitle}
      </h1>
      <div className="d-flex imgs-container position-relative small-custom-container ">
        {/* <div
          className="small-img-container animate__animated animate__fadeInLeft"
          style={{
            backgroundImage: `url('${props.response.header.formatted_small_image}')`,
          }}
        ></div> */}
        <div
          className="animate__animated animate__fadeInRight w-100"
          style={{
            backgroundImage: `url('${props.response.header.formatted_big_image}')`,
          }}
        >
          <img src={`${props.response.header.formatted_big_image}`} className="w-100" />
        </div>
      </div>
      <div
        className="fancy-arrow-container text-center animate__animated animate__fadeInUp"
        onAnimationEnd={() => {
          props.setHeaderAnimationDone(true);
        }}
      >
        <img src="images/fancy-down-arrow.svg" onClick={_handleArrowClick} />
      </div>
    </header>
  );
};
export default Header;

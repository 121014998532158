import LayoutContext from "../../contexts/LayoutContext";
import { useContext, useState, useEffect } from "react";
import Button from "../Button";
import Product from "./Product";
import useAxios from "../../hooks/useAxios";
import { CART_BULK } from "../../api";
import DataContext from "../../contexts/DataContext";
import { getCart } from "../../helper";

const Popup = (props) => {
  const { setIsCartOpen } = useContext(LayoutContext);
  const commonData = useContext(DataContext);
  const [selectedPriceIds, setSelectedPriceIds] = useState(
    props.products.map((el) => el.product_price_id)
  );
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();

  useEffect(() => {
    if (status == 200) {
      const cartItems = commonData.cartItems.filter(
        (el) => !Boolean(response.find((el2) => el2.id == el.id))
      );
      commonData.setCartItems([...response,commonData.cartItems]);
      setIsCartOpen(true);
    }
  }, [loader]);
  const _handleChange = (value, index) => {
    let priceIds = [...selectedPriceIds];
    priceIds[index] = value;
    setSelectedPriceIds(priceIds);
  };
  const _handleAddToCart = () => {
    setHookOptions({
      url: CART_BULK,
      data: { price_ids: selectedPriceIds },
      method: "POST",
    });
  };
  const _handlePopupClick = (e) => {
    e.stopPropagation();
  };
  const _handleClose = () => {
    props.setActiveSet(null);
  };
  return (
    <>
      <div
        className={`banner text-center padding-medium font-primary d-md-none`}
      >
        <small>{commonData.fixed.titles.banner}</small>{" "}
      </div>
      <div className="set-popup custom-container" onClick={_handlePopupClick}>
        <div className="logo-container  d-flex justify-content-center align-items-center">
          <img
            src="/images/close.svg"
            className="close"
            onClick={_handleClose}
          />
          <img src="/images/logo.svg" className="logo d-md-none" />
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img src={props.formatted_image} className="img-fluid" />
          </div>
          <div className="col-lg-6 description-col d-flex align-items-center">
            <div>
              <div className="font-weight-medium f-24">{props.name}</div>
              <div className="price">
                <span className="font-family-lucida">
                  {props.products
                    .reduce((accumulator, currentValue, index) => {
                      let price = currentValue.product.price.find(
                        (el) => el.id == [selectedPriceIds[index]]
                      );
                      let priceWithDiscount =
                        price && currentValue.product.discount
                          ? price.price -
                          (price.price * currentValue.product.discount) / 100
                          : price;
                      return (
                        accumulator +
                        (price
                          ? currentValue.product.discount
                            ? priceWithDiscount
                            : price.price
                          : 0)
                      );
                    }, 0)
                    .toFixed(2)}€
                </span>
              </div>
              <div
                className="rich-text-wrapper"
                dangerouslySetInnerHTML={{ __html: props.description }}
              ></div>
              <Button
                text="Add Set to Cart"
                className="btn-outline-secondary w-100"
                onClick={_handleAddToCart}
                loading={loader}
              />
            </div>
          </div>
        </div>
        <div className="separator"></div>
        <h3 className="f-24 font-weight-medium laminas">
          Láminas individuales
        </h3>
        <div className="row variation-row">
          {props.products.map((el, index) => {
            return (
              <div className="col-md-6 variation-col" key={index}>
                <Product
                  {...el}
                  priceId={selectedPriceIds[index]}
                  index={index}
                  onChange={_handleChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Popup;

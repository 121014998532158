import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useState, useEffect } from "react";
import React from "react";
import DataContext from "../../contexts/DataContext";
import Category from "./Category";
import useInView from "react-cool-inview";
import { useHistory, useLocation, useParams } from "react-router-dom";

const Categories = (props) => {
  const commonData = useContext(DataContext);
  const observableTitle = useInView({
    unobserveOnEnter: true,
  });
  const routeParams = useParams();
  const observableAll = useInView({
    unobserveOnEnter: true,
  });
  const history = useHistory();
  const location = useLocation();

  // const [shouldAnimate, setShouldAnimate] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (selectedIndex != -1) {
      params.set("subcategory", props.data[selectedIndex].name);
      history.push({ search: params.toString() });
    } else params.delete("subcategory");
    history.push({ search: params.toString() });
  }, [selectedIndex]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("subcategory")) {
      const index = props.data.findIndex((el) => {
        return el.name == params.get("subcategory");
      });
      if (index != selectedIndex) setSelectedIndex(index);
    }
  }, []);
  useEffect(() => {
    setSelectedIndex(-1);
  }, [routeParams.category]);
  return (
    <div className="categories custom-container">
      <h2
        className={`f-24 font-weight-bold font-weight-xs-medium ${observableTitle.inView
          ? "animate__animated animate__fadeIn"
          : "no-opacity"
          }`}
        ref={observableTitle.observe}
      >
        {commonData.fixed.shop_titles["categories"]}
      </h2>
      <div className="d-flex">
        <Swiper
          breakpoints={{
            768: {
              spaceBetween: 42,
            },
            0: {
              spaceBetween: 32,
            },
          }}
          updateOnWindowResize={true}
          freeMode={true}
          slidesPerView="auto"
        >
          <SwiperSlide>
            <div
              ref={observableAll.observe}
              className={`category`}
              onClick={() => {
                setSelectedIndex("-1");
              }}
            >
              <div
                className={`m-0 all-categories  name d-flex align-items-center justify-content-center f-24 font-weight-medium ${observableAll.inView
                  ? "animate__animated animate__rollIn"
                  : "no-opacity"
                  }`}
              >
                {commonData.fixed.shop_titles["all"]}
              </div>
              <div
                // style={{
                //   animationDelay: (props.data.length + 1) * 0.2 + "s",
                // }}
                className={`name ${observableAll.inView
                  ? "animate__animated animate__fadeInUp text-center "
                  : "no-opacity"
                  }`}
              >
                {props.total} {commonData.fixed.shop_titles["all-text"]}
              </div>
            </div>
          </SwiperSlide>
          {props.data.map((category, index) => (
            <SwiperSlide key={"category-" + category.id}>
              <Category
                {...category}
                index={index + 1}
                onClick={(category) => {
                  setSelectedIndex(index);
                }}
                selected={index == selectedIndex}
                total={props.data.length}
              // shouldAnimate={shouldAnimate}
              // setShouldAnimate={setShouldAnimate}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default Categories;

import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input/Input";
import useAxios from "../hooks/useAxios";
import { SUBMIT } from "../api";
import RegistrationLayout from "../components/RegistrationLayout";
import PasswordInput from "../components/Input/PasswordInput";
import Checkbox from "../components/Input/Checkbox";
import DataContext from "../contexts/DataContext";

const SignUp = () => {
  const commonData = useContext(DataContext);
  const [formData, setFormData] = useState({
    terms: false,
    newsletter: false,
    name: "",
    password: "",
    password_confirmation: "",
    email: "",
  });
  const [
    submitResponse,
    submitError,
    submitLoader,
    submitSetHookOptions,
    submitSnackbar,
    status,
  ] = useAxios();
  const history = useHistory();
  const _handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const _handleCheck = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  };
  const _onSubmit = (e) => {
    e.preventDefault();
    submitSetHookOptions({
      url: SUBMIT,
      method: "post",
      data: formData,
    });
  };
  useEffect(() => {
    if (status == 200) history.push("/accesso?v=1");
  }, [status]);
  return (
    <div id="signup">
      <RegistrationLayout active="signup">
        <form onSubmit={_onSubmit} className="row">
          <div className="col-12 col-lg-6">
            <Input
              onChange={_handleChange}
              name="name"
              placeholder={commonData.fixed.account_titles["name"].title}
              type="text"
              error={submitError}
              value={formData.name}
            />
          </div>
          <div className="col-12 col-lg-6">
            <Input
              onChange={_handleChange}
              name="email"
              type="text"
              placeholder={commonData.fixed.account_titles["email"].title}
              error={submitError}
              value={formData.email}
            />
          </div>
          <div className="col-12 col-lg-6">
            <PasswordInput
              onChange={_handleChange}
              name="password"
              placeholder={commonData.fixed.account_titles["password"].title}
              error={submitError}
              value={formData.password}
            />
          </div>
          <div className="col-12 col-lg-6">
            <PasswordInput
              onChange={_handleChange}
              name="password_confirmation"
              placeholder={
                commonData.fixed.account_titles["confirm-password"].title
              }
              error={submitError}
              value={formData.password_confirmation}
            />
          </div>
          <div className="col-12 col-lg-6 offset-lg-6 terms">
            <Checkbox
              checked={formData.terms}
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: commonData.fixed.account_titles["terms"].content,
                  }}
                ></span>
              }
              name="terms"
              error={submitError}
              onCheck={() => {
                _handleCheck("terms");
              }}
            />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <Checkbox
              checked={formData.newsletter}
              label={commonData.fixed.account_titles["newsletter"].title}
              error={submitError}
              name="newsletter"
              onCheck={() => {
                _handleCheck("newsletter");
              }}
            />
          </div>
          <div className="col-12 col-lg-6">
            <Button
              text={commonData.fixed.account_titles["create-account"].title}
              type="submit"
              className="btn-outline-secondary w-100 mt-4 mt-lg-0"
              loading={submitLoader}
            />
          </div>
        </form>
      </RegistrationLayout>
    </div>
  );
};
export default SignUp;

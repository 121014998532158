import { useContext } from "react";
import useInView from "react-cool-inview";
import { Link } from "react-router-dom";
import DataContext from "../../contexts/DataContext";
import { numberWithCommas } from "../../helper";

const Col = (props) => {
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div
      ref={observe}
      className={`mt-5 col-6 col-lg-3 d-flex justify-content-center justify-content-xl-start align-items-center ${inView ? "animate__animated animate__fadeIn " : "no-opacity"
        }`}
    >
      <div className="w-100 h-100 d-flex align-items-center position-relative flex-wrap justify-content-between flex-column">
        <Link to={`/productos/${props.product.subcategories[0].category.slug}/${props.product.subcategories[0].slug}/${props.product.slug}`}>
          <img src={props.product.formatted_image} className="img-fluid w-100" />
          <img src={props.product.formatted_hover_image} className="img-fluid hovered-img w-100" />
        </Link>
        <div className="info mt-5">
          <div className="title text-center">
            <Link style={{ color: 'black', textDecoration: 'none' }} to={`/productos/${props.product.subcategories[0].category.slug}/${props.product.subcategories[0].slug}/${props.product.slug}`}>
              {props.product.name}
            </Link>
          </div>
          <div className="price text-center">
            <span className="number f-24 font-weight-bold">{numberWithCommas(props.product.min_price)}</span>
            <span className="currency f-24 font-family-lunacy font-bold">
              €
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const StaffPicks = (props) => {
  const commonData = useContext(DataContext);
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div ref={observe} className={`staff-picks custom-container`}>
      <div
        className={`small-heading subheading text-center font-weight-medium   ${inView ? "animate__animated animate__fadeIn " : ""
          }`}
      >
        {/* {commonData.fixed.landing_page_titles["staff-picks-heading"]} */}
      </div>
      <h2
        className={`text-center font-weight-medium ${inView ? "animate__animated animate__fadeIn " : "no-opacity"
          }`}
      >
        {commonData.fixed.landing_page_titles["staff-picks"].title}
      </h2>
      <div className="row">
        {props.response.map(el => {
          return <Col key={el.id} product={el} />
        })}
      </div>
      {/* <div
        className={`text-center button-container ${
          inView ? "animate__animated animate__fadeIn " : "no-opacity"
        }`}
      >
        <a className="btn btn-outline-primary m-auto">
          {commonData.fixed.landing_page_titles["staff-picks-button"]}
        </a>
      </div> */}
    </div>
  );
};
export default StaffPicks;

import { useState } from "react";
import useInView from "react-cool-inview";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

const GallerySwiper = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [bigSwiper, setBigSwiper] = useState();
  const [smallSwiper, setSmallSwiper] = useState();
  const observeGallery = useInView({
    unobserveOnEnter: true,
  });
  const _handleClick = (index) => {
    setActiveIndex(index);
    bigSwiper.slideTo(index);
  };
  return (
    <div
      className={`d-flex gallery-swiper flex-wrap ${observeGallery.inView
        ? "animate__animated animate__fadeIn"
        : "no-opacity"
        }`}
      ref={observeGallery.observe}
    >
      <div className="big-swiper d-flex justify-content-md-start justify-content-center">
        <Swiper
          updateOnWindowResize={true}
          slidesPerView={1}
          onSwiper={(swiper) => {
            setBigSwiper(swiper);
          }}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
            smallSwiper.slideTo(swiper.activeIndex);
          }}
          breakpoints={{
            768: {
              direction: "vertical",
            },
            0: {
              direction: "horizontal",
            },
          }}
        >
          {props.data.map((el, index) => (
            <SwiperSlide key={`big-${index}`}>
              <div className="img-container">
                <img
                  src={el}
                  alt="Instagram"
                  className="img-fluid  position-absolute"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="small-swiper">
        <Swiper
          threshold={5}
          breakpoints={{
            768: {
              direction: "vertical",
              slidesPerView: 4

            },
            0: {
              direction: "horizontal",
              slidesPerView: 3,
            },
          }}
          updateOnWindowResize={true}
          // centeredSlides={true}
          spaceBetween={24}
          onSwiper={(swiper) => setSmallSwiper(swiper)}
        >
          {props.data.map((el, index) => (
            <SwiperSlide
              key={`small-${index}`}
              onClick={() => {
                _handleClick(index);
              }}
            >
              <div
                className={`img-container w-100 h-100 ${activeIndex == index ? "active" : ""
                  }`}
              >
                <img src={el} alt="Instagram" className={`img-fluid  `} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default GallerySwiper;

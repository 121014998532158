import { useContext, useState, useEffect } from "react";
import DataContext from "../../contexts/DataContext.js";
import ProductGridElement from "../Products/ProductGridElement";
const Works = (props) => {
  const commonData = useContext(DataContext);
  const [allImagesLoaded, setAllImagesLoaded] = useState(0);
  const _handleOnLoad = () => {
    setAllImagesLoaded(allImagesLoaded + 1);
  };
  useEffect(() => {
    if (allImagesLoaded == props.data.length) props.onAllLoaded();
  }, [allImagesLoaded]);
  return (
    <div className="works container">
      <h3 className="f-24 font-weight-bold title">
        {commonData.fixed.artists_titles["works"].title} ({props.data.length})
      </h3>
      <div className="row">
        {props.data.map((el) => {
          return (
            <div className="col-md-3 col-6" key={el.id}>
              <ProductGridElement  {...el} onLoad={_handleOnLoad} from="artist"
                subcategory={el.subcategories[0]?.slug}
                category={el.subcategories[0]?.category.slug} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Works;

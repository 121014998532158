import { useEffect, useState } from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import useInView from "react-cool-inview";
import { useLocation } from "react-router-dom";
const Nav = (props) => {
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  const location = useLocation();
  const [isShopOpen, setIsShopOpen] = useState(false);
  useEffect(() => {
    setIsShopOpen(false);
  }, [location]);
  return (
    <nav
      ref={observe}
      className={
        inView ? "animate__animated animate__slideInDown" : "no-opacity"
      }
    >
      <DesktopNav
        {...props}
        isShopOpen={isShopOpen}
        setIsShopOpen={setIsShopOpen}
      />
      <MobileNav
        {...props}
        isShopOpen={isShopOpen}
        setIsShopOpen={setIsShopOpen}
        bannerHeight={props.bannerHeight}
      />
    </nav>
  );
};
export default Nav;

import { useContext, useEffect, useState } from "react";
import { SUBSCRIBE } from "../api";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";

const Newsletter = (props) => {
    const [response, error, loader, setHookOptions, snackbar] = useAxios();
    const commonData = useContext(DataContext);
    const [email, setEmail] = useState();
    const _handleSubmit = (e) => {
        e.preventDefault();
        setHookOptions({
            url: SUBSCRIBE,
            data: { email: email },
            method: "POST",
        });
    };
    const _handleChange = (e) => {
        setEmail(e.target.value);
    }
    const _toggleNewsletter = (e) => {
        if (e.target.id == "overlay")
            props.setIsOpen(false);
    }

    useEffect(() => {
        document.querySelector('body').style.overflow = props.isOpen ? 'hidden' : '';
        document.querySelector('html').style.overflow = props.isOpen ? 'hidden' : '';
    }, [props.isOpen]);
    return (

        <div className={`newsletter-popup d-none d-md-flex ${props.isOpen ? 'open' : ''}`} id="overlay" onClick={_toggleNewsletter}>
            <div className="internal-container" >
                <div className="close" onClick={() => props.setIsOpen(false)}>&times;</div>
                <div className="row  ">
                    <div className="col-12 col-md-6" >
                        <img src={commonData.fixed.newsletter_popup['image'].formatted_image} className="w-100" />
                    </div>
                    <div className="col-12 col-md-6 right-column d-flex align-items-center">
                        <div className="wrapper">
                            <h3>{commonData.fixed.newsletter_popup['newsletter'].title}</h3>
                            <div className="details">
                                {commonData.fixed.newsletter_popup['discount'].title}
                            </div>
                            <form className="text-right" onSubmit={_handleSubmit}>
                                <input onChange={_handleChange} type="email" placeholder="Introducer tu email" className="w-100" />
                                {loader ? (
                                    <div className="mini-loader">{loader}</div>) : <button type="submit">{commonData.fixed.newsletter_popup['send'].title}</button>
                                }
                            </form>
                            {Object.keys(response) && !error.messages ? (
                                <div className="animate__animated animate__slideInDown">
                                    <small>{Object.keys(response).length ? commonData.fixed.newsletter_popup['success'].title : ""}</small>
                                </div>
                            ) : error.messages.length ? (
                                <div className="animate__animated animate__slideInDown">
                                    <small>{error.messages[0]}</small>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default Newsletter;
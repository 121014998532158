import { Link } from "react-router-dom";
import LayoutContext from "../../contexts/LayoutContext";
import { useContext } from "react";
const Inspiration = (props) => {
  const _handleClick = () => {
    props.setActiveSet(props);
  };
  return (
    <div className={`img-container position-relative ${props.colClasses}`}>
      <img className="img-fluid w-100" src={props.formatted_image} />
      <div className="inspiration-overlay position-absolute ">
        {props.products.map((el) => (
          <Link
            key={el.id}
            className="marker position-absolute d-flex justify-content-center align-items-center"
            to={{ state: { from: "inspiration" }, pathname: `/productos/${el.product.subcategories[0]?.category.slug}/${el.product.subcategories[0]?.slug}/${el.product.slug}` }}
            style={{
              top: el.y + "%",
              left: el.x + "%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <span className="inner-marker"></span>
          </Link>
        ))}
        {/* <button className="position-absolute btn" onClick={_handleClick}>
          Comprar Set
        </button> */}
      </div>
    </div>
  );
};
export default Inspiration;

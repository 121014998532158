import { useContext, useEffect, useState } from "react";
import useInView from "react-cool-inview";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataContext from "../../contexts/DataContext";

const FancySection = (props) => {
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const commonData = useContext(DataContext);

  const _handleOnLoad = () => {
    setLoadedImagesCount(loadedImagesCount + 1);
  };
  const _handleArrowClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      left: 0,
      behavior: "smooth",
    });
  };
  const _updateScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", _updateScrollPosition);
    return () => {
      window.removeEventListener("scroll", _updateScrollPosition);
    };
  }, []);
  useEffect(() => {
    if (loadedImagesCount == 6) props.setAreFancyImagesLoaded(true);
  }, [loadedImagesCount]);

  return (
    <div
      className={`mb-5 fancy-section position-relative ${observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
        }`} style={{ backgroundImage: `url(${props.formatted_background_image})` }}
      ref={observable.observe}
      onScroll={_updateScrollPosition}
    >
      <div className="custom-container pt-5">
        <Breadcrumbs
          data={[
            {
              name: commonData.fixed.breadcrumbs['home'].title,
              url: "/",
            },
            {
              name: commonData.fixed.breadcrumbs['artistas'].title,
            },
          ]}
        />
      </div>
      {/* <div className="right">
        <img
          className={`top-right-image `}
          src={props.formatted_top_right_image}
          onLoad={_handleOnLoad}
          x
        />
        <img
          className={`bottom-right-image d-none d-xl-inline-block `}
          src={props.formatted_bottom_right_image}
          onLoad={_handleOnLoad}
        />
      </div>
      <div className="left">
        <img
          className={`top-left-image  `}
          src={props.formatted_top_left_image}
        />
        <img
          className={`bottom-left-image d-none d-xl-inline-block `}
          src={props.formatted_bottom_left_image}
          onLoad={_handleOnLoad}
        />
      </div> */}
      <div className="text" >
        <h3 className={`header text-center `}>{props.header}</h3>
        <h1
          className={`font-weight-medium`}
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></h1>
        <hr />
        <div className={`text-center section-text custom-container`}>{props.text}</div>
        <div className="section-bottom text-xl-center d-flex d-xl-block align-items-start justify-content-between">
          <img
            className={`bottom-left-image d-inline-block d-xl-none `}
            src={props.formatted_bottom_left_image}
            onLoad={_handleOnLoad}
          />
          {/* <img
            src="/images/fancy-down-arrow.svg"
            className={`fancy-arrow cursor-pointer`}
            onClick={_handleArrowClick}
            onLoad={_handleOnLoad}
          /> */}
          <img
            className={`bottom-right-image d-inline-block d-xl-none `}
            src={props.formatted_bottom_right_image}
            onLoad={_handleOnLoad}
          />
        </div>
      </div>
    </div >
  );
};
export default FancySection;

import { useContext, useEffect, useState } from "react";
import { ARTISTS } from "../api";
import FancySection from "../components/Artists/FancySection";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios";
import Grid from "../components/SortableGrid/Grid";
import Artist from "../components/Artists/Artist";
import { useLocation } from "react-router-dom";
import useInView from "react-cool-inview";

const Artists = () => {
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const [reloadingData, setReloadingData] = useState();
  const [areFancyImagesLoaded, setAreFancyImagesLoaded] = useState(false);
  const location = useLocation();
  const [params, setParams] = useState(new URLSearchParams(location.search));
  const commonData = useContext(DataContext);
  const observable = useInView({
    unobserveOnEnter: true,
  });

  const fetchArtists = (page, sortBy) => {
    if (page == 1) setReloadingData(true);
    else setReloadingData(false);
    setHookOptions({
      url: ARTISTS,
      data: {
        page: page,
        sortBy: params.get('sortBy'),
      },
    });
  };

  useEffect(() => {
    fetchArtists(1);
  }, []);
  useEffect(() => {
    const newParams = new URLSearchParams(location.search);
    setParams(newParams);
  }, [location.search]);
  useEffect(() => {
    fetchArtists(
      params.get("page") ? params.get("page") : 1,
      params.get("sortBy")
    );
  }, [params]);

  return Object.keys(response).length ? (
    <>
      <div id="artists" >

        <div>
          <FancySection
            {...response.fancy_section}
            setAreFancyImagesLoaded={setAreFancyImagesLoaded}
          />
        </div>
        <Grid
          isSmallGrid={false}
          data={response.artists}
          loading={loader}
          renderElement={(artist) => {
            return <Artist {...artist} />;
          }}
          reloadingData={reloadingData}
          loadMore={(page) => {
            fetchArtists(page);
          }}
          sortingDropdown={[
            {
              title: commonData.fixed.artists_titles["alphabetical"].title,
              slug: "alphabetical",
            },
            {
              title: commonData.fixed.artists_titles["new"].title,
              slug: "new",
            },
            {
              title: commonData.fixed.artists_titles["highlighted"].title,
              slug: "highlighted",
            },
          ]}
          titles={{
            "products-count": commonData.fixed.artists_titles["artists"].title,
            "order-with": commonData.fixed.artists_titles["order-with"].title,
          }}
        />
        <div
          ref={observable.observe}
          className={`footer ${observable.inView
            ? "animate__animated animate__fadeIn"
            : "no-opacity"
            }`}
          dangerouslySetInnerHTML={{
            __html: commonData.fixed.artists_titles["footer"].text,
          }}
        ></div>
      </div>
    </>
  ) : (
    <div className={`global-spinner ${areFancyImagesLoaded ? "d-none" : ""}`}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default Artists;

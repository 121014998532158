import { Link } from "react-router-dom";
import Input from "../components/Input/Input";
import Checkbox from "../components/Input/Checkbox";
import Dropdown from "../components/Input/MUDropdown";
import useAxios from "../hooks/useAxios";
import { useState, useEffect, useContext } from "react";
import Button from "./Button";
import ReactDOM from "react-dom";
import {
    ADDRESSES,
    COUNTRIES,
    PROVINCES,
} from "../api";
import React from "react";
import DataContext from "../contexts/DataContext";
const AddressForm = (props) => {
    const [response, error, loader, setHookOptions, snackbar, status] =
        useAxios();
    const [countriesResponse, countriesError, countriesLoader, countriesSetHookOptions, countriesSnackbakr, countriesStatus] = useAxios();
    const [
        provincesResponse,
        provincesError,
        provincesLoader,
        provincesSetHookOptions,
        provincesSnackbar,
        provincesStatus,
    ] = useAxios();
    const [formData, setFormData] = useState({
        email: props.data ? props.data.email : "",
        email_offers: props.data ? props.data.email_offers : false,
        name: props.data ? props.data.name : "",
        business: "",
        directions: "",
        address: "",
        postal_code: "",
        city: "",
        province_id: null,
        country_id: null,
        phone_number: "",
        keep_information: 0,
    });
    const commonData = useContext(DataContext);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const _handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const _handleCheck = (name) => {
        setFormData({ ...formData, [name]:! formData[name] });
    }
  
    const _handleDropdownChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const _handleSubmit = (e) => {
        e.preventDefault();
        let data = { ...formData };
        if (props.extraData)
            data = { ...data, ...props.extraData };
        if (props.submitURL)
            setHookOptions({
                url: formData.keep_information?ADDRESSES: props.submitURL,
                data: data,
                method: "post",
            });

    };
    useEffect(() => {
        if (provincesStatus == 200) {
            setProvinces(provincesResponse.provinces);
        }
    }, [provincesLoader]);
    useEffect(() => {
        if (formData.country_id)
            provincesSetHookOptions({
                url: PROVINCES(formData.country_id),
            });
    }, [formData.country_id]);
    useEffect(() => {
        if (countriesStatus == 200) {
            setCountries(countriesResponse.countries);
        }
    }, [countriesLoader]);
    useEffect(() => {
        countriesSetHookOptions({
            url: COUNTRIES
        })
    }, []);
    useEffect(() => {
        if (status == 200) {
            ReactDOM.unstable_batchedUpdates(() => {
                if (props.setFormLoader)
                    props.setFormLoader(loader)
                if (props.setFormStatus)
                    props.setFormStatus(status);
                setFormData({
                    email: "",
                    email_offers: false,
                    name: "",
                    business: "",
                    directions: "",
                    address: "",
                    postal_code: "",
                    city: "",
                    province_id: null,
                    country_id: null,
                    phone_number: "",
                    keep_information: false,
                })
                if (props.setFormResponse)
                    props.setFormResponse(response);
            });
            if (props.submitCallback)
                props.submitCallback({ ...formData }, countries, provinces);

        }
        else if (status == 422) {
            props.setFormError(error);
        }
    }, [loader])
    useEffect(() => {
        setFormData({
            email: props.data ? props.data.email : "",
            email_offers: props.data ? props.data.email_offers : false,
            name: props.data ? props.data.name : "",
            business: props.data ? props.data.business : "",
            directions: props.data ? props.data.directions : "",
            address: props.data ? props.data.address : "",
            postal_code: props.data ? props.data.postal_code : "",
            city: props.data ? props.data.postal_code : "",
            province_id: props.data ? props.data.province_id : "",
            country_id: props.data ? props.data.country_id : "",
            phone_number: props.data ? props.data.phone_number : "",
            keep_information: props.data ? props.data.keep_information : false,
        })
    }, [props.data])
    return <form onSubmit={_handleSubmit} id="form" className={`${props.visible == false ? 'd-none' : ''}`}>

        <h3 className="f-24 font-weight-medium">{props.title}</h3>
        <div className="row">
            <div className="col-12">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-name'].title}
                    name="name"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.name}
                />
            </div>
            <div className="col-12 ">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-office'].title}
                    name="business"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.business}
                />
            </div>
            <div className="col-12">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-direction'].title}
                    name="directions"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.directions}
                />
            </div>
            <div className="col-12">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-address-line-two'].title}
                    name="address"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.address}
                />
            </div>{" "}
            <div className="col-12 col-lg-6">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-postal-code'].title}
                    name="postal_code"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.postal_code}
                />
            </div>{" "}
            <div className="col-12 col-lg-6">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-city'].title}
                    name="city"
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    value={formData.city}
                />
            </div>{" "}
            <div className="col-12 col-lg-6">
                <Dropdown
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-country'].title}
                    data={countries}
                    value={formData.country_id}
                    error={error}
                    valueKey="id"
                    render={(el) => el.name}
                    name="country_id"
                    onChange={(name, value) => {
                        _handleDropdownChange(name, value, countries);
                    }}
                />
            </div>
            <div className="col-12 col-lg-6">
                <Dropdown
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-province'].title}
                    data={provinces}
                    loading={provincesLoader}
                    value={formData.province_id}
                    error={error}
                    valueKey="id"
                    render={(el) => el.name}
                    name="province_id"
                    onChange={(name, value) => {
                        _handleDropdownChange(name, value, countries);
                    }}
                />
            </div>{" "}
            <div className="col-12 telephone-col">
                <Input
                    placeholder={commonData.fixed.account_titles['address-form-placeholder-phone'].title}
                    error={error}
                    onChange={_handleChange}
                    type="text"
                    label=""
                    name="phone_number"
                    value={formData.phone_number}
                />
            </div>
            {props.disableKeepInformation ? "" : <div className="col-12 keep-information">
                <Checkbox
                    name="keep_information"
                    label={commonData.fixed.account_titles['address-form-keep-information'].title}
                    onCheck={()=>_handleCheck('keep_information')}
                    checked={formData.keep_information}
                    error={error}
                    
                />
            </div>
            }

        </div>
        {props.footer ? props.footer(setFormData) : <div className="d-flex align-items-center flex-wrap">
            <Button
                text={
                    props.submitText ? props.submitText :
                        commonData.fixed.account_titles['address-form-continue-checkout'].title}
                className="btn-outline-secondary"
                loading={loader}
                type="submit"
            />
            <Link className="color-unset ml-4 go-to-cart" to="/Cesta">
                {commonData.fixed.account_titles['address-form-continue-cart'].title}
            </Link>
        </div>}

    </form>

}
export default AddressForm
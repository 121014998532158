import { Link } from "react-router-dom";
import { isExternalLink } from "../helper"

const CustomLink = (props) => {
    {
        return isExternalLink(props.link) ? <a style={props.style} className={props.className} target="_blank" href={props.link}>{props.children}</a> :
            <Link style={props.style} className={props.className} to={props.link}>
                {props.children}
            </Link>
    }
}
export default CustomLink;
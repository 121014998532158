import { useEffect, useLayoutEffect, useState } from "react";
import { GLOBAL_DATA, LOGIN } from "../api";
import Button from "../components/Button";
import Input from "../components/Input/Input";
import useAxios from "../hooks/useAxios";
import { useCookies } from "react-cookie";
import RegistrationLayout from "../components/RegistrationLayout";
import PasswordInput from "../components/Input/PasswordInput";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import Checkbox from "../components/Input/Checkbox";
import DataContext from "../contexts/DataContext";
import LayoutContext from "../contexts/LayoutContext";
import { common } from "@material-ui/core/colors";

const Login = () => {
  const commonData = useContext(DataContext);
  const [formData, setFormData] = useState({
    checked: false,
  });
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [search, setSearch] = useState();
  const [
    submitResponse,
    submitError,
    submitLoader,
    submitSetHookOptions,
    submitSnackbar,
    submitStatus
  ] = useAxios();
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();

  const _handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const _handleCheck = () => {
    setFormData({
      ...formData,
      checked: !formData["checked"],
    });
  };
  const _onSubmit = (e) => {
    e.preventDefault();
    submitSetHookOptions({
      url: LOGIN,
      method: "post",
      data: formData,
    });
  };

  useEffect(() => {
    if (submitStatus == 200) {
      removeCookie("guestToken", { path: '/' });
      if (submitResponse.user?.token)
        setHookOptions({
          url: GLOBAL_DATA,
          token: submitResponse.user.token,
          test: 'test'
        });
    }
  }, [submitLoader]);
  useEffect(() => {
    if (status == 200) {
      commonData.setFixed(response);
      commonData.setCartItems(response.cart);

    }
  }, [loader]);
  useEffect(() => {
    if (submitResponse.user && submitResponse.user.token) {
      setCookie("token", submitResponse.user.token, { path: "/" });
    }
  }, [commonData])

  return (
    loader? <div className="global-spinner">{loader}</div>: <div id="login">
      <RegistrationLayout active="login">
        <form onSubmit={_onSubmit} autoComplete="false">
          <div className="row">
            <div className="col-12 mb-2">
              {search && search.get("v") == 1 ? (
                <small className="text-success font-weight-bold">
                  {commonData.fixed.account_titles["please-verify-email"].title}
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-lg-6">
              <Input
                onChange={_handleChange}
                name="email"
                type="text"
                placeholder={commonData.fixed.account_titles["email"].title}
                error={submitError}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <PasswordInput
                onChange={_handleChange}
                name="password"
                placeholder={commonData.fixed.account_titles["password"].title}
                error={submitError}
              />
            </div>
          </div>
          <div className="forget-password">
            <Link to="/olvidaste-contrasena">
              {commonData.fixed.account_titles["forgot-password"].title}
            </Link>
          </div>
          <div className="separator"></div>
          <div className="d-md-flex  justify-content-between align-items-center">
            <div className="mr-3">
              <Checkbox
                checked={formData.checked}
                onCheck={_handleCheck}
                label={commonData.fixed.account_titles["remember-me"].title}
                name="checked"
                error={submitError}
              />
            </div>
            <Button
              text={commonData.fixed.account_titles["login-button"].title}
              type="submit"
              loading={submitLoader}
              className="ml-md-auto btn-outline-secondary mt-4 mt-md-0"
            />
          </div>
        </form>
      </RegistrationLayout>
    </div>

  );
};
export default Login;

import { useState, useEffect, useContext } from "react";
import AddressBox from "../../components/AddressBox";
import AddressForm from "../AddressForm";
import { VALIDATE_ADDRESS } from "../../api";

const AddressSection = (props) => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const _toggleForm = () => {
        setIsFormVisible(!isFormVisible)
    }
    return <div className="form-container">
        {props.data.map((el, index) => <AddressBox key={index} checked={index == props.selectedAddressIndex} onCheck={() => props.onChange(index)} key={el.id} {...el} />)}
        {
            !isFormVisible && props.data.length > 0 ?
                <a onClick={_toggleForm} className="add-new-address">+ <div>{props.newAddress}</div></a>
                : ""
        }
        <AddressForm
            setFormError={props.setFormError}
            visible={isFormVisible || !props.data.length}
            submitURL={VALIDATE_ADDRESS}
            submitCallback={props.onSubmit}
            submitText={props.submitText}
            disableKeepInformation={props.disableKeepInformation}
            extraData={props.extraData}
        />
    </div>
}
export default AddressSection
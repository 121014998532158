import { useContext, useEffect } from "react";
import useInView from "react-cool-inview";
import { Link } from "react-router-dom";
import { PERSONALIZE_REDIRECT } from "../api"
import Breadcrumbs from "../components/Breadcrumbs";
import CustomLink from "../components/CustomLink";
import DataContext from "../contexts/DataContext";
import useAxios from "../hooks/useAxios"

const CustomizeRedirectPage = () => {
    const [
        response,
        error,
        loader,
        setHookOptions,
        snackbar,
        status,
    ] = useAxios();
    const commonData = useContext(DataContext);
    const { observe, unobserve, inView, scrollDirection, entry } = useInView({
        unobserveOnEnter: true,
    });
    const { observe2, unobserve2, inView2, scrollDirection2, entry2 } = useInView({
        unobserveOnEnter: true,
    });
    useEffect(() => {
        setHookOptions({ url: PERSONALIZE_REDIRECT })
    }, []);
    return status == 200 ? <div id="customize-redirect-page">
        <div className="custom-container">
            <Breadcrumbs
                data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['customize'].title }]}
            />
        </div>
        <div className="custom-container">
            <div ref={observe} className={`${inView ? "animate__animated animate__fadeIn" : "no-opacity"}`}>
                <h3 className="title text-center font-weight-medium mb-4">{response.data['title'].title}</h3>
                <p className="subtitle text-center ml-auto mr-auto">{response.data['subtitle'].title}</p>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="img-container ml-md-auto ml-auto mr-auto mr-md-0 mb-5 text-center">
                        <div>
                            <CustomLink link={response.data['without-photo'].href} >
                                <img src={response.data['without-photo'].formatted_image} className="w-100" />
                            </CustomLink>
                        </div>
                        <CustomLink link={response.data['without-photo-link'].href} className="d-inline-block mt-4">
                            {response.data['without-photo-link'].title}
                            <img src="images/search-arrow.svg" className="ml-2" />
                        </CustomLink>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="img-container text-center mr-md-0 ml-md-0 mr-auto ml-auto">
                        <div>
                            <CustomLink link={response.data['with-photo-link'].href}>
                                <img src={response.data['with-photo-image'].formatted_image} className="w-100" />
                            </CustomLink>
                        </div>
                        <CustomLink link={response.data['with-photo-link'].href} className="d-inline-block mt-4">
                            {response.data['with-photo-link'].title}
                            <img src="images/search-arrow.svg" className="ml-2" />
                        </CustomLink>

                    </div>
                </div>

            </div>
        </div>
    </div> : <div className="global-spinner">
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
}
export default CustomizeRedirectPage
import { useContext, useState } from "react";
import useInView from "react-cool-inview";
import { Swiper, SwiperSlide } from "swiper/react";
import DataContext from "../contexts/DataContext";
import SwiperCore, { EffectFade, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);
const Instagram = (props) => {
  const [isHovered, setIsHovered] = useState('not-hovered');
  const observeTitle = useInView({
    unobserveOnEnter: true,
  });
  const observeInstagram = useInView({
    unobserveOnEnter: true,
  });
  const commonData = useContext(DataContext);

  return (
    <div id="instagram">
      <div
        className="custom-container mt-5"
      >
        <div className="row">
          <div className="top-line"></div>
          <h2 className="insta-handle" ref={observeTitle.observe}>
            {commonData.fixed.titles["instagram-handle"]}
          </h2>
        </div>
      </div>
      <div
        onMouseOver={() => setIsHovered('hovered')}
        onMouseOut={() => setIsHovered('not-hovered')}
        ref={observeInstagram.observe}
        className=""
      >
        {/* <Swiper
          spaceBetween={24}
          updateOnWindowResize={true}
          // freeMode={true}
          initialSlide={3}
          slidesPerView={6}
          centeredSlides={true}
          // o\nSwiper={(swiper) => swiper.setTranslate(-120)}
        ></Swiper> */}
        <Swiper
          modules={[Navigation]}
          spaceBetween={24}
          updateOnWindowResize={true}
          centeredSlides={true}
          freeMode={true}
          navigation={true}
          slidesPerView="auto"
          breakpoints={{
            576: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            991: {
              slidesPerView: 4
            }
          }}
        // onSwiper={(swiper) => swiper.setTranslate(-120)}
        >
          {props.data.map((el) => (
            <SwiperSlide key={el.id}>
              <a href={el.permalink} target="_blank" rel="noreferrer">
                <img
                  src={el.thumbnail_url ? el.thumbnail_url : el.media_url}
                  alt="Instagram"
                  className="img-fluid"

                />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div >
  );
};
export default Instagram;

import React, { useContext } from "react";
import DataContext from "../../contexts/DataContext";

const ProductsRow = (props) => {
  const commonData = useContext(DataContext);
  return (
    <div className={`row ${props.direction ? props.direction : ""}`}>
      {props.data.length ? (
        props.data.map((element) => {
          return (
            <div
              className={`${props.isSmallGrid ? "col-12 col-md-6" : "col-6 col-md-3"
                }`}
              key={"product-" + element.id}
            >
              {props.renderElement(element)}
            </div>
          );
        })
      ) : (
        <h2 className="text-center font-weight-medium w-100 mt-3">
          {commonData.fixed.shop_titles["no-results"]}
        </h2>
      )}
    </div>
  );
};
export default ProductsRow;

import { useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../contexts/DataContext";

const Painting = (props) => {
    const commonData = useContext(DataContext);
    const [aspectRatio, setAspectRatio] = useState("286 / 425");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [framePx, setFramePx] = useState(0);
    const [frameImages,setFrameImages]=useState({
        top_frame_formatted:false,
        left_frame_formatted:false,
        top_right_triangle_formatted:false,
        top_left_triangle_formatted:false,
        bottom_triangle_formatted:false,
    })
    const paintingRef = useRef();
    const handleImageLoad = (e) => {
        setImageLoaded(true);
        setAspectRatio(e.target.clientWidth / e.target.clientHeight);
    }
    const _handleClick = () => {
        props.setOpenPicker(true);
    }

    useEffect(() => {
        if (props.frame) {
            if (props.passpartoutSize == 0) {
                setFramePx((props.frame.thickness * parseFloat(window.getComputedStyle(paintingRef.current).width)) / parseFloat(Math.max(props.canvasWidth||10,10)));
            }
            else {
                setFramePx((props.frame.thickness * parseFloat(window.getComputedStyle(paintingRef.current).paddingLeft)) / parseFloat(props.passpartoutSize));
            }
        }
    }, [props.passpartoutSize, props.canvasWidth, props.canvasHeight])
    useEffect(()=>{
        if(props.frame){
            if (props.passpartoutSize == 0) {
                setFramePx((props.frame.thickness * parseFloat(window.getComputedStyle(paintingRef.current).width)) / parseFloat(Math.max(props.canvasWidth||10,10)));
            }
            else {
                setFramePx((props.frame.thickness * parseFloat(window.getComputedStyle(paintingRef.current).paddingLeft)) / parseFloat(props.passpartoutSize));
            }
            setFrameImages({
                top_frame_formatted:null,
                top_right_triangle_formatted:null,
                top_left_triangle_formatted:null,
                left_frame_formatted:null,
                bottom_triangle_formatted:null,
                top_triangle_formatted:null,
            });
            setImageLoaded(false);
            let top_frame_formatted=null;
            let top_right_triangle_formatted=null;
            let top_left_triangle_formatted=null;
            let left_frame_formatted=null;
            let bottom_triangle_formatted=null;
            let top_triangle_formatted=null;
            // let top_right_triangle_formatted=null;
            load(props.frame.top_frame_formatted).then(()=>{
                top_frame_formatted=props.frame.top_frame_formatted;
                load(props.frame.top_right_triangle_formatted).then(()=>{
                    top_right_triangle_formatted=props.frame.top_right_triangle_formatted
                    load(props.frame.top_left_triangle_formatted).then(()=>{
                        top_left_triangle_formatted=props.frame.top_left_triangle_formatted
                        load(props.frame.left_frame_formatted).then(()=>{
                            left_frame_formatted=props.frame.left_frame_formatted
                            load(props.frame.bottom_triangle_formatted).then(()=>{
                                bottom_triangle_formatted=props.frame.bottom_triangle_formatted
                                load(props.frame.top_triangle_formatted).then(()=>{
                                    top_triangle_formatted=props.frame.top_triangle_formatted
                                    setFrameImages({
                                        top_frame_formatted:top_frame_formatted,
                                        top_right_triangle_formatted:top_right_triangle_formatted,
                                        top_left_triangle_formatted:top_left_triangle_formatted,
                                        left_frame_formatted:left_frame_formatted,
                                        bottom_triangle_formatted:bottom_triangle_formatted,
                                        top_triangle_formatted:top_triangle_formatted
                                    });
                                });
                                setImageLoaded(true);

                            })
                        })
                    })
                })
            })
        }
    },[props.frame])
    function load(src) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', resolve);
            image.addEventListener('error', reject);
            image.src = src;
        });
    }
    return <div className={`painting position-relative ml-auto mr-auto d-flex align-items-center justify-content-center  mt-xl-0 mb-xl-0 ${props.canvasWidth<20?"zoom-out-half":""}`} style={{marginTop:framePx+20+"px",marginBottom:framePx+20+"px"}} >
       <div className={`position-relative frame-container d-flex align-items-center justify-content-center cursor-pointer ${props.custom == 2 ? "position-relative" : ""}`} onClick={_handleClick}
            style={{
                width: `calc(356px - ${props.maxPasspartoutSize - props.passpartoutSize}px)`
            }
            } >
            <div className="w-100 passpartout-container d-flex align-items-center justify-content-center position-relative"
                style={{
                    backgroundColor: props.passpartoutColor,
                }}>
                {props.frame != undefined ?
                    <>
                        <div className="top-frame position-absolute frame-side" style={{ height: framePx + "px", background: `url(${frameImages.top_frame_formatted})` }}>
                            <div className="position-relative w-100" >
                                <img className="top-right-triangle triangle" style={{ width: framePx }} src={frameImages.top_right_triangle_formatted} />
                                <img className="top-left-triangle triangle" style={{ width: framePx }} src={frameImages.top_left_triangle_formatted} />
                            </div>
                        </div>
                        <div className="left-frame position-absolute frame-side" style={{ width: framePx + "px", background: `url(${frameImages.left_frame_formatted})` }}>
                            <div
                                className="position-relative w-100 h-100">
                                <img className="right-top-triangle triangle" style={{ width: framePx }} src={frameImages.top_triangle_formatted} />
                                <img className="right-bottom-triangle triangle" style={{ width: framePx }} src={frameImages.bottom_triangle_formatted} />
                            </div>
                        </div>
                        <div className="bottom-frame position-absolute frame-side" style={{ height: framePx + "px", background: `url(${frameImages.top_frame_formatted})` }}>
                            <div className="position-relative w-100" >
                                <img className="top-right-triangle triangle" style={{ width: framePx }} src={frameImages.top_right_triangle_formatted} />
                                <img className="top-left-triangle triangle" style={{ width: framePx }} src={frameImages.top_left_triangle_formatted} />
                            </div>
                        </div>
                        <div className="right-frame position-absolute frame-side" style={{ width: framePx + "px", background: `url(${frameImages.left_frame_formatted})` }}>
                            <div
                                className="position-relative w-100 h-100">
                                <img className="right-top-triangle triangle" style={{ width: framePx }} src={frameImages.top_triangle_formatted} />
                                <img className="right-bottom-triangle triangle" style={{ width: framePx }} src={frameImages.bottom_triangle_formatted} />
                            </div>
                        </div>
                    </> : ""}
                {props.image && props.image !== undefined ?
                    <>
                        {!imageLoaded ? <div className="loader painting-loader">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> : ""}
                        <img className="painting-img"
                            ref={paintingRef}
                            src={props.image}
                            style={{
                                width: `calc(100%)`,
                                padding: `calc(${props.passpartoutSize / (2 * props.passpartoutSize + parseFloat(props.canvasWidth)) * 100}%)`,

                            }}
                            onLoad={handleImageLoad} />
                    </>
                    :
                    <div className="w-100 d-flex justify-content-center align-items-center"
                        ref={paintingRef}
                        style={{
                            padding: `calc(${props.passpartoutSize / (2 * props.passpartoutSize + parseFloat(props.canvasWidth)) * 100}%)`,
                        }}>
                        {!imageLoaded && props.frame? <div className="loader painting-loader translate-0">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> : ""}
                        <div className="position-relative custom-placeholder d-flex justify-content-center align-items-center"
                            style={{
                                width: `calc(100%)`,
                                height: props.custom==2?"0":"500px",
                                paddingTop: (props.custom == 2 ? `${(props.canvasWidth || 1) / (props.canvasHeight || 1)}` : aspectRatio)*100+"%",
                            }}
                            onClick={() => {
                                props.setOpenPicker(true);
                                // console.log("helloooooo");
                            }}>
                            {props.custom == 1 ?
                                <div className="text-center w-100">
                                    <img src="/images/upload-icon.svg" className="upload-icon" />
                                    <div className="info w-100 pl-5 pr-5">
                                        {commonData.fixed.customization_fixed_titles['upload-file-center'].title}
                                    </div>
                                    {props.openPicker?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>:
                                    ""}
                                </div> : ""}
                        </div>
                    </div>
                }
            </div>
        </div>
    </div >
}
export default Painting;
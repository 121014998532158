import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
    PAYPAL,
} from "../../api";
import { useHistory } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect } from "react";
const Gateways = (props) => {
    const [response, error, loader, setHookOptions, snackbar, status] =
        useAxios();
    const history = useHistory();
    useEffect(() => {
        if (status == 200) {
            history.push('/exito')
        }
    }, [status]);
    return <>
        {/* <div className="gateway-container text-center">
            <div className="gateway-container text-center">
                <PayPalScriptProvider
                    options={{
                        currency: "EUR",
                        "client-id":
                            "ASrvvXW1qGcmW-B6_wtkGjMTOweBZXxZkhogOFpwABezss-niUD874JLHQwVibyUi7xMXZxAZQzkDx3-",
                    }}
                >
                    <PayPalButtons
                        style={{ layout: "horizontal", shape: "pill" }}
                        forceReRender={[props.total]}
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            value: props.total,
                                        },

                                    },
                                ],
                                application_context: {
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then(function (details) {
                                setHookOptions({
                                    url: PAYPAL,
                                    data: {
                                        order_id: props.gatewayData.order_id
                                    },
                                    method: "post",
                                });
                            });
                        }}
                    />
                </PayPalScriptProvider>
            </div>
        </div>
        <div className="fancy-separator row align-items-center">
            <div className="col"></div>
            <div className="center font-weight-bold">O</div>
            <div className="col"></div>
        </div>*/}
        <iframe
            title="titulo"
            src={`https://api.paycomet.com/gateway/ifr-bankstore?MERCHANT_MERCHANTCODE=${props.gatewayData.merchant_code}&MERCHANT_TERMINAL=${props.gatewayData.merchant_terminal}&OPERATION=${props.gatewayData.operation}&MERCHANT_AMOUNT=${props.gatewayData.merchant_amount}&MERCHANT_CURRENCY=${props.gatewayData.merchant_currency}&MERCHANT_ORDER=${props.gatewayData.order_number}&MERCHANT_MERCHANTSIGNATURE=${props.gatewayData.merchant_signature}&3DSECURE=1`}
            width="100%"
            height="1400px"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            style={{
                border: "0px solid #000000",
                padding: "0px",
                margin: "0px",
            }}
        ></iframe>
    </>
}
export default Gateways
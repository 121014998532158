import Dropdown from "../Products/Dropdown";
import DataContext from "../../contexts/DataContext";
import Checkbox from "../Input/Checkbox";
import { useContext, useState, useEffect } from "react";
import Button from "../Button";
import { CART } from "../../api";
import useAxios from "../../hooks/useAxios";
import LayoutContext from "../../contexts/LayoutContext";
import { getCart } from "../../helper";

const Product = (props) => {
  const commonData = useContext(DataContext);
  const { setIsCartOpen } = useContext(LayoutContext);
  const [selectedDropdownIndexes, setSelectedDropdownIndexes] = useState({
    frame: props.product.price.findIndex((el) => el.id == props.priceId),
    dimension: props.product.price.findIndex((el) => el.id == props.priceId),
  });
  const [passepartout, setPassPartout] = useState(false);
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const _handleDropdownChange = (name, value) => {
    setSelectedDropdownIndexes({
      ...selectedDropdownIndexes,
      [name]: value,
    });
  };
  let price =
    props.priceId > 0
      ? props.product.price.find((el) => el.id == props.priceId).price
      : null;
  let priceWithDiscount =
    price && props.product.discount
      ? (price - (price * props.product.discount) / 100)
          .toFixed(2)
          .toString()
          .replace(".", ",")
      : price;
  useEffect(() => {
    let priceObject = props.product.price.find(
      (el) =>
        el.frame == props.product.price[selectedDropdownIndexes.frame].frame &&
        el.width ==
          props.product.price[selectedDropdownIndexes.dimension].width &&
        el.height ==
          props.product.price[selectedDropdownIndexes.dimension].height
    );
    props.onChange(priceObject ? priceObject.id : -1, props.index);
  }, [selectedDropdownIndexes]);
  useEffect(() => {
    if (status == 200) {
      const cartItems = commonData.cartItems.filter(
        (el) => response.id !== el.id
      );
      commonData.setCartItems([response,...commonData.cartItems]);
      setIsCartOpen(true);
    }
  }, [loader]);
  const _handleCheck = () => {
    setPassPartout(!passepartout);
  };
  const _handleAddToCart = () => {
    setHookOptions({
      url: CART,
      method: "post",
      data: {
        product_slug: props.product.slug,
        price_id: props.product.price.find((el) => el.id == props.priceId).id,
      },
    });
  };

  return (
    <div className="variation row">
      <div className="col-xl-6">
        <img src={props.product.formatted_image} className="img-fluid w-100" />
      </div>
      <div className="col-xl-6">
        <div className="row">
          <div className="col-12 name">{props.product.name}</div>
          <div className="col-12 price">
            <span className="font-weight-bold">
              {props.priceId > 0 ? priceWithDiscount : "Out Of Stock"}
            </span>
            {props.priceId > 0 ? (
              <span className="font-family-lucida">€</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-xl-12 col-6">
            <Dropdown
              placeholder={commonData.fixed.shop_titles["frame"]}
              data={props.product.price}
              selectedIndex={selectedDropdownIndexes.frame}
              render={(el) => {
                return el.frame;
              }}
              name="frame"
              onChange={_handleDropdownChange}
            />
          </div>
          <div className="col-xl-12 col-6">
            <Dropdown
              placeholder={commonData.fixed.shop_titles["dimension"]}
              data={props.product.price}
              selectedIndex={selectedDropdownIndexes.dimension}
              render={(el) => {
                return el.width + "x" + el.height;
              }}
              name="dimension"
              onChange={_handleDropdownChange}
            />
          </div>
          <div className="col-12 passepartout">
            <Checkbox
              label="Passepartout"
              checked={passepartout}
              name="newsletter"
              onCheck={_handleCheck}
            />
          </div>
          <div className="col-12">
            <Button
              className="btn-outline-secondary w-100"
              text="Add to cart"
              onClick={_handleAddToCart}
              loading={loader}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Product;

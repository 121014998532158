
import useAxios from "../hooks/useAxios";
import { useEffect, useContext, useState } from "react";
import { INSPIRATION } from "../api";
import Breadcrumbs from "../components/Breadcrumbs";
import Grid from "../components/SortableGrid/Grid";
import DataContext from "../contexts/DataContext";
import Row from "../components/Inspirations/Row";
import Popup from "../components/Inspirations/Popup";
import LayoutContext from "../contexts/LayoutContext";
const Inspirations = () => {
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const [colClasses, setColClasses] = useState("col-6 col-lg-3");
  const [activeSet, setActiveSet] = useState();
  const commonData = useContext(DataContext);
  const { setPreventScroll } = useContext(LayoutContext);
  const _toggleGridSize = () => { };
  const fetchInspirations = (page) => {
    setHookOptions({
      url: INSPIRATION,
      data: {
        page: page,
      },
    });
  };

  useEffect(() => {
    fetchInspirations(1);
  }, []);
  useEffect(() => {
    if (activeSet) setPreventScroll(true);
    else setPreventScroll(false);
  }, [activeSet]);
  // useEffect(() => {
  //   if (!isOverlayVisible) setActiveSet(null);
  // }, [isOverlayVisible]);
  const _handleOverlayClick = () => {
    setActiveSet(null);
  };
  return status == 200 ? (
    <div id="inspiration">
      {activeSet ? (
        <div className="inspiration-overlay" onClick={_handleOverlayClick}>
          <div className="popup-container">
            <Popup {...activeSet} setActiveSet={setActiveSet} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        <div className="d-none d-lg-block position-relative custom-container">
          <Breadcrumbs
            data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['inspiration'].title }]}
          />
          <small className="position-absolute productos-breadcrumbs">
            {commonData.fixed.inspiration_fixed_titles['productos'].title}({response.inspiration.total})
          </small>
        </div>
        <div className="mb-3 align-items-center custom-container d-flex justify-content-between d-block d-lg-none">
          <h3 className="f-24 mb-0 inspiracion-title font-weight-medium">
            {commonData.fixed.inspiration_fixed_titles['inspiration'].title}
          </h3>
          <small className=" productos-breadcrumbs">
            {commonData.fixed.inspiration_fixed_titles['mobile-productos'].title}({response.inspiration.total})
          </small>
        </div>
        <div className="mt-5 intro text-center">{commonData.fixed.inspiration_fixed_titles['introduction'].title}</div>
        <div className="inspiration-grid">
          <Grid
            colClasses={colClasses}
            changeGridSize={_toggleGridSize}
            data={response.inspiration}
            direction={"flex-column"}
            loading={loader}
            reloadingData={false}
            loadMore={(page) => {
              fetchInspirations(page);
            }}
            row={(data) => (
              <Row
                colClasses={colClasses}
                changeGridSize={_toggleGridSize}
                data={data}
                setActiveSet={setActiveSet}
              />
            )}
            sortingDropdown={[
              {
                title: commonData.fixed.artists_titles["new"].title,
                slug: "new",
              },
              {
                title: commonData.fixed.artists_titles["highlighted"].title,
                slug: "highlighted",
              },
            ]}
            titles={{
              "products-count":
                commonData.fixed.artists_titles["artists"].title,
              "order-with": commonData.fixed.artists_titles["order-with"].title,
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default Inspirations;

import { useContext, useEffect, useState } from "react";
import DataContext from "../../../contexts/DataContext";
import { Link, useLocation, useHistory, NavLink } from "react-router-dom";
import ShopBar from "../ShopBar";
import LayoutContext from "../../../contexts/LayoutContext";
import { getCart } from "../../../helper";

const MobileNav = (props) => {
  const commonData = useContext(DataContext);
  const { isOverlayVisible, setIsOverlayVisible } = useContext(LayoutContext);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let history = useHistory();

  const _handleOpenCart = () => {
    props.setIsCartOpen(true);
  };
  const _handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue) {
      setSearchValue("");
      history.push("/productos?search=" + searchValue);
    }
  };
  const _handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const _handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const _handleShopClick = () => {
    props.setIsShopOpen(!props.isShopOpen);
  };
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);
  useEffect(() => {
    // setIsOverlayVisible(isMenuOpen);
  }, [isMenuOpen]);
  return (
    <div className="mobile-nav f-24 pr-4 pl-4 d-flex justify-content-between align-items-center d-xl-none position-relative">
      <ShopBar isOpen={props.isShopOpen} onClose={_handleShopClick} />
      <div className="left-nav">
        <img
          className="hamburger"
          src="/images/hamburger.svg"
          onClick={_handleHamburgerClick}
        />
      </div>
      <div className="logo-container">
        <Link to="/">
          <img className="logo" src="/images/logo.svg" />
        </Link>
      </div>
      <div className="cart cursor-pointer d-flex" onClick={_handleOpenCart}>
        <img className="cart-icon mr-1" src="/images/cart.svg" />
        <span>
          {commonData.cartItems && commonData.cartItems.length > 9
            ? "9+"
            : commonData.cartItems
              ? commonData.cartItems.length
              : 0}
        </span>
      </div>
      <div
        className={`mobile-menu position-absolute ${isMenuOpen ? "active" : ""
          }`}
        style={{ maxHeight: "calc(100vh - " + props.bannerHeight + "px)" }}
      >
        <div className="menu-header d-flex align-items-center">
          <div className="">
            <img
              className="close-icon cursor-pointer"
              src="/images/close.svg"
              onClick={_handleHamburgerClick}
            />
          </div>
          <div className="logo-container justify-self-center">
            <img className="logo" src="/images/logo.svg" />
          </div>
        </div>
        <form
          className="search-container  pt-4 pr-4 pl-4 text-center"
          onSubmit={_handleSubmit}
        >
          <input
            placeholder={commonData.fixed.menu_titles.search_placeholder.title}
            value={searchValue}
            onChange={_handleChange}
          />
          <img
            className="search-arrow"
            src="/images/search-arrow.svg"
            onClick={_handleSubmit}
          />
        </form>
        <ul className="top-nav list-unstyled m-0 text-center cursor-pointer">
          <li onClick={_handleShopClick}>
            {commonData.fixed.menu_titles.shop.title}
          </li>

          <li>
            <NavLink to="/nosotros" activeClassName="active">
              {commonData.fixed.menu_titles.nosotros.title}
            </NavLink>
          </li>
          <li>
            <NavLink to="/inspiracion" activeClassName="active">
              {commonData.fixed.menu_titles.inspiracion.title}
            </NavLink>
          </li>
          <li className="mr-0">
            <NavLink to="/artistas" activeClassName="active">
              {commonData.fixed.menu_titles.artistas.title}
            </NavLink>
          </li>
        </ul>
        <div className="bottom-nav ">
          <ul className="m-0  list-unstyled text-center">
            <li>
              <NavLink to="/contacta-con-nosotros" activeClassName="active">
                {commonData.fixed.menu_titles.contacto.title}
              </NavLink>
            </li>
            <li>
              <Link className={(location.pathname === '/accesso' || location.pathname === '/inscribirse') && "active"} to="/cuenta/mis-pedidos">
                {commonData.fixed.menu_titles.cuenta.title}
              </Link>
            </li>
            <li>
              {commonData.fixed.social_media.map(el => (
                el.title === 'Blog' ?
                  <a href={el.link} target="_blank">
                    {el.title}
                  </a>
                  : null
              ))}
            </li>
            {/* <li>{commonData.fixed.menu_titles.faq.title}</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default MobileNav;

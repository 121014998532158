import { useContext } from "react";
import DataContext from "../../contexts/DataContext";
import { Link } from "react-router-dom";
const ShopBar = (props) => {
  const commonData = useContext(DataContext);
  return (
    <div className={`shop-bar ${props.isOpen ? "show-shop" : ""}`}>
      <div className="custom-container">
        <header className="d-flex align-items-center d-xl-none">
          <div onClick={props.onClose}>
            <img
              src="/images/search-arrow.svg"
              className="arrow cursor-pointer"
            />
          </div>
          <h2 className="f-24 justify-self-center">
            {commonData.fixed.menu_titles.shop.title}
          </h2>
        </header>
        <div className="row">
          <div className="col-6 col-lg-3 personalization-col">
            <div className="img-container">
              <Link to="/personalize">
                <img
                  src={
                    commonData.fixed.menu_titles.personalization.formatted_image
                  }
                />
              </Link>
            </div>
            <small className="font-weight-bold category-name">
              {commonData.fixed.menu_titles.personalization.title}
            </small>
          </div>
          {commonData.fixed.categories.map((category) => {
            return (
              <Link
                className="col-6 col-lg-3"
                to={`/productos/${category.slug}`}
                key={category.id}
              >
                <div className="img-container">
                  <img src={category.formatted_image} />
                </div>
                <small className="font-weight-bold category-name">
                  {category.name}
                </small>
              </Link>
            );
          })}

        </div>
      </div>
    </div>
  );
};
export default ShopBar;

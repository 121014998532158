import Breadcrumbs from "../components/Breadcrumbs";
import { useContext } from "react";
import DataContext from "../contexts/DataContext";
import Input from "../components/Input/Input";
import { useState, useEffect } from "react";
import useAxios from "../hooks/useAxios";
import Button from "../components/Button";
import Instagram from "../components/Instagram";
import { CONTACTUS } from "../api";
import Map from "../components/Map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Marker from "../components/Marker";

const ContactUs = () => {

  const commonData = useContext(DataContext);
  const [formData, setFormData] = useState({
    email: "",
    phone_number: "",
    message: "",
  });
  const [
    submitResponse,
    submitError,
    submitLoader,
    submitSetHookOptions,
    submitSnackbar,
    submitStatus,
  ] = useAxios();

  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();

  const _handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setHookOptions({
      url: CONTACTUS,
    });
  }, []);
  useEffect(() => {
    if (submitStatus == 200) {
      setFormData({ email: "", phone_number: "", message: "" });
    }
  }, [submitLoader]);
  const _handleSubmit = (e) => {
    e.preventDefault();
    submitSetHookOptions({
      method: "POST",
      url: CONTACTUS,
      data: formData,
    });
  };
  return status == 200 ? (
    <div id="contact-us">
      <div className="custom-container">
        <Breadcrumbs
          data={[{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['contact-us'].title }]}
        />
      </div>
      <div className="custom-container">
        <div className="row form-row">
          <div className="col-md-6 text-right">
            <div className="img-container d-inline-block">
              <img
                src={
                  commonData.fixed.contact_us_titles["happy-to-help-you"]
                    .formatted_image
                }
              />
              <h1 className="font-weight-medium">
                {/* {commonData.fixed.contact_us_titles["happy-to-help-you"].title} */}
              </h1>
            </div>
            <div className="text-left">
              {/* <img
                className="smiley"
                src={
                  commonData.fixed.contact_us_titles["smiley"].formatted_image
                }
              /> */}
            </div>
          </div>
          <div className="col-md-6">
            <form onSubmit={_handleSubmit}>
              <div className="description">
                {commonData.fixed.contact_us_titles["form-text"].title}
              </div>
              <div className="input-container">
                <Input
                  placeholder={
                    commonData.fixed.contact_us_titles[
                      "phone-number-placeholder"
                    ].title
                  }
                  name="phone_number"
                  error={submitError}
                  onChange={_handleChange}
                  type="text"
                  label=""
                  value={formData.phone_number}
                />
              </div>
              <div className="input-container">
                <Input
                  placeholder={
                    commonData.fixed.contact_us_titles["email-placeholder"]
                      .title
                  }
                  name="email"
                  error={submitError}
                  onChange={_handleChange}
                  type="text"
                  label=""
                  value={formData.email}
                />
              </div>
              <div className="input-container message-container">
                <Input
                  placeholder={
                    commonData.fixed.contact_us_titles["message-placeholder"]
                      .title
                  }
                  name="message"
                  error={submitError}
                  onChange={_handleChange}
                  type="text"
                  label=""
                  value={formData.message}
                />
              </div>
              <div className="submit-form text-right">
                <Button
                  type="submit"
                  className="ml-auto"
                  loading={submitLoader}
                  text={
                    <span>
                      {commonData.fixed.contact_us_titles["send-button"].title}
                      <img src="/images/fancy-down-arrow.svg" />
                    </span>
                  }
                />
                {!submitLoader && submitStatus == 200 ? (
                  <div>
                    <small className="text-success">
                      {
                        commonData.fixed.contact_us_titles["success-message"]
                          .title
                      }
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="row map-row">
          <div className="col-md-3 d-flex align-items-center order-2 order-md-1 justify-content-md-left justify-content-center text-center text-md-left">
            <div>
              <div className="locale font-weight-medium">
                {commonData.fixed.contact_us_titles["locale"].title}
              </div>
              <address className="street">
                {commonData.fixed.contact_us_titles["street"].title}
              </address>
              <address className="phone">
                {commonData.fixed.contact_us_titles["phone-1"].title}
              </address>
              {/* <address className="phone-2">
                {commonData.fixed.contact_us_titles["phone-2"].title}
              </address> */}
              <address className="email">
                {commonData.fixed.contact_us_titles["address-email"].title}
              </address>
            </div>
          </div>
          <div className="col-md-9 order-1 order-md-2">
            <a target="_blank" href={`https://maps.google.com/?q=${commonData.fixed.contact_us_titles['latitude'].title},${commonData.fixed.contact_us_titles['longitude'].title}`} rel="noreferrer">
            <img class="w-100 h-auto" src={`${commonData.fixed.contact_us_titles['google-maps-image'].formatted_image}`}/>
            </a>
            {/* <Wrapper apiKey={"AIzaSyA8b_yK6lDCdjhzzU7xropcKzMBDKOYAU8"} >
              <Map >
                <Marker position={{ lat: 41.3987495, lng: 2.1420867 }} />
              </Map>
            </Wrapper> */}
          </div>
        </div>
        <div
          className="rich-textbox"
          dangerouslySetInnerHTML={{
            __html: commonData.fixed.contact_us_titles["contact-us-rich"].text,
          }}
        ></div>
      </div>
      <Instagram data={response.insta_feed} />
    </div>
  ) : (
    <div className="global-spinner">{loader}</div>
  );
};
export default ContactUs;

import { useState, useEffect } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

const Dropdown = (props) => {
  const _handleDropdownOpen = () => {
    setIsDropdownShown(!isDropdownShown);
  };
  const _handleMenuClick = (index) => {
    props.onChange(props.name, index);
    _handleDropdownOpen(false);
  };
  const ref = useOnclickOutside(
    () => {
      setIsDropdownShown(false);
    },
    {
      excludeScrollbar: true,
    }
  );
  const renderTitle = (el) => {
    return props.render ? props.render(el) : el.title;
  };
  const [isDropdownShown, setIsDropdownShown] = useState();
  const [data, setData] = useState(props.data.map((el) => renderTitle(el)));
  useEffect(() => {
    setData(props.data.map((el) => renderTitle(el)));
  }, [props.data]);
  return (
    <div
      ref={ref}
      className={`dropdown ${isDropdownShown ? "active" : ""}`}
      onClick={_handleDropdownOpen}
    >
      {props.selectedIndex != null
        ? renderTitle(props.data[props.selectedIndex])
        : props.placeholder}
      <span className="dropdown-toggle ">
        <span className="d-none d-md-inline"></span>
      </span>
      <div
        className={`dropdown-menu w-100  ${isDropdownShown ? "show" : ""}`}
        aria-labelledby="dropdownMenuButton"
      >
        {data
          .filter((el, index) => data.indexOf(el) === index)
          // .filter((el, index) => {
          //   return el != renderTitle(props.data[props.selectedIndex]);
          // })
          .map((el, index) => (
            <a
              key={"dropdown-menu-" + index}
              className="dropdown-item w-100"
              onClick={() => _handleMenuClick(index)}
            >
              {el}
            </a>
          ))}
      </div>
    </div>
  );
};
export default Dropdown;

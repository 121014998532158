import { Link } from "react-router-dom";
import useInView from "react-cool-inview";

const Breadcrumbs = (props) => {
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div ref={observe}
      className={`breadcrumbs d-none d-md-flex color-tertiary  ${inView ? "animate__animated animate__fadeIn" : "no-opacity"}`}>
      {props.data.map((breadcrumb, index) => (
        <div className="breadcrumb-container" key={"breadcrumb-" + index}>
          {breadcrumb.url ? (
            <Link
              className="color-tertiary custom-breadcrumb"
              to={breadcrumb.url}
            >
              {breadcrumb.name}
            </Link>
          ) : (
            <span className="color-tertiary custom-breadcrumb">
              {breadcrumb.name}
            </span>
          )}

          {index < props.data.length - 1 ? (
            <span className="breadcrumbs-arrow font-family-lucida">&gt;</span>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};
export default Breadcrumbs;

import useInView from "react-cool-inview";
import Error from "./Error";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { InputAdornment } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const PasswordInput = (props) => {
  const [type, setType] = useState("password");
  const _toggleCensorship = () => {
    setType(type == "password" ? "text" : "password");
  };
  const classes = useStyles();
  const observable = useInView({
    unobserveOnEnter: true,
  });
  return (
    <div
      ref={observable.observe}
      className={` ${
        observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
      } `}
    >
      <TextField
        error={Boolean(
          Object.keys(props.error).length && props.error.keyed[props.name]
        )}
        type={type}
        id="standard-error"
        label={
          Object.keys(props.error).length && props.error.keyed[props.name]
            ? props.error.keyed[props.name][0]
            : props.placeholder
        }
        name={props.name}
        className="w-100"
        onChange={props.onChange}
        value={props.value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {type == "password" ? (
                <img
                  src="/images/eye-open.svg"
                  className="cursor-pointer"
                  onClick={_toggleCensorship}
                />
              ) : (
                <img
                  src="/images/eye-closed.svg"
                  className="cursor-pointer"
                  onClick={_toggleCensorship}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
export default PasswordInput;

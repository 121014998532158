import React, { useContext } from "react";
import DataContext from "../../contexts/DataContext";
import Inspiration from "./Inspiration";

const Row = (props) => {
  const commonData = useContext(DataContext);
  const renderElements = () => {
    return props.data.map((el) => {
      return <Inspiration {...el} {...props} />;
    });
  };
  // className={`row ${props.direction ? props.direction : ""}`}>
  return (
    <div className={`inspiration-col row`}>
      {props.data.length ? (
        renderElements()
      ) : (
        <h2 className="text-center font-weight-medium w-100 mt-3">
          {commonData.fixed.shop_titles["no-results"]}
        </h2>
      )}
    </div>
  );
};
export default Row;

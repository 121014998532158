import { useContext, useEffect, useState } from "react";
import { ALL_PRODUCTS, PRODUCTS } from "../api";
import Breadcrumbs from "../components/Breadcrumbs";
import Categories from "../components/Categories";
import useAxios from "../hooks/useAxios";
import useInView from "react-cool-inview";
import Grid from "../components/SortableGrid/Grid";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProductGridElement from "../components/Products/ProductGridElement";
import DataContext from "../contexts/DataContext";
const Products = () => {
  const [response, error, loader, setHookOptions, snackbar] = useAxios();
  const [subcategory, setSubcategory] = useState();
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const routeParams = useParams();
  //used to tell whether to apply loader to button only (in case we're using load more) or to replace the content with a loader for first load, sorting, category filter
  const [reloadingData, setReloadingData] = useState();

  const location = useLocation();
  const commonData = useContext(DataContext);
  const fetchProducts = (page, category, sortBy, search) => {
    if (page == 1) setReloadingData(true);
    else setReloadingData(false);
    const params = new URLSearchParams(location.search);
    setHookOptions({
      url: routeParams.category ? PRODUCTS(routeParams.category) : ALL_PRODUCTS,
      data: {
        page: page,
        subcategory: category || params.get("subcategory"),
        sortBy: sortBy || params.get("sortBy"),
        search: search || params.get("search"),
      },
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    fetchProducts(
      params.get("page") ? params.get("page") : 1,
      params.get("subcategory"),
      params.get("sortBy"),
      params.get("search")
    );
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    fetchProducts(
      params.get("page") ? params.get("page") : 1,
      params.get("subcategory"),
      params.get("sortBy"),
      params.get("search")
    );
    setSubcategory(params.get("subcategory"));
  }, [location.search]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    fetchProducts(
      params.get("page") ? params.get("page") : 1,
      params.get("subcategory"),
      params.get("sortBy"),
      params.get("search")
    );
    setSubcategory(params.get("subcategory"));
  }, [routeParams]);
  return Object.keys(response).length ? (
    <div id="products">
      <div className="custom-container">
        <Breadcrumbs
          data={
            response.category
              ? [
                { name: commonData.fixed.breadcrumbs['home'].title, url: "/" },
                { name: commonData.fixed.breadcrumbs['product'].title },
                {
                  name: response.category.name,
                },
              ]
              : [{ name: commonData.fixed.breadcrumbs['home'].title, url: "/" }, { name: commonData.fixed.breadcrumbs['product'].title }]
          }
        />
      </div>
      {response.category ? (
        <Categories
          data={response.subcategories}
          filterData={(subcategory) => {
            fetchProducts(1, subcategory);
          }}
          total={response.total}
        />
      ) : (
        ""
      )}
      <Grid
        loadMore={(page) => {
          fetchProducts(page);
        }}
        loading={loader}
        data={response.products}
        reloadingData={reloadingData}
        renderElement={(product) => {
          let category = response.category ? response.category.slug : product.subcategories[0].category.slug;
          let _subcategory = response.subcategories.length ? response.subcategories.find(el => product.subcategories.map(el2 => el.name).includes(subcategory ? subcategory : el.name)).slug : product.subcategories[0].slug;

          return <ProductGridElement from={"product"} {...product} category={category}
            subcategory={_subcategory} />;
        }}
        sortingDropdown={[
          {
            title: commonData.fixed.shop_titles["new"],
            slug: "new",
          },
          {
            title: commonData.fixed.shop_titles["highlighted"],
            slug: "highlighted",
          },
        ]}
        titles={{
          "products-count": commonData.fixed.shop_titles["products-count"],
          "order-with": commonData.fixed.shop_titles["order-with"],
        }}
      />
    </div>
  ) : (
    <div className="global-spinner">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
export default Products;

import useInView from "react-cool-inview";
import { useState, useEffect } from "react";
const Category = (props) => {
  const observableSwiper = useInView({
    unobserveOnEnter: true,
  });

  return (
    <div
      ref={observableSwiper.observe}
      className={`category ${props.selected ? "selected" : ""}`}
      onClick={() => {
        props.onClick(props.name);
      }}
    >
      <div
        className={`img-container
        ${observableSwiper.inView
            ? "animate__animated animate__rollIn"
            : "no-opacity"
          }`}
        style={{
          backgroundImage: `url(${props.formatted_image})`,
          animationDelay: props.index * 0.2 + "s",
        }}
      />
      <div
        className={`name text-center  ${observableSwiper.inView
            ? "animate__animated animate__fadeInLeft"
            : "no-opacity"
          }`}
        style={{
          animationDelay: props.index * 0.2 + "s",
        }}
      // onAnimationEnd={() => {
      //   if (props.index == props.total) props.setShouldAnimate(false);
      // }}
      >
        {props.name}
      </div>
    </div>
  );
};
export default Category;

import useAxios from "../../hooks/useAxios";
import { useEffect, useContext, useState } from "react";
import { CART_DELETE } from "../../api";
import DataContext from "../../contexts/DataContext";
import { getCart, numberWithCommas } from "../../helper";
import LazyLoad from "react-lazyload";


const DesktopProductRow = (props) => {
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const commonData = useContext(DataContext);
  const [imageLoaded,setImageLoaded]=useState();
  const _handleDelete = () => {
    setHookOptions({
      method: "DELETE",
      url: CART_DELETE(props.id),
    });
  };

  useEffect(() => {
    if (status == 200 && !loader)
    commonData.setCartItems(commonData.cartItems.filter((el) => {
      return el.id !== props.id;
    }));
  }, [loader]);
  return (
    <>
      <div className="col-3 product-row font-weight-regular p-lg-0">
        {loader ? (
          <div width="13">{loader}</div>
        ) : (
          <svg
            onClick={_handleDelete}
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="16"
            viewBox="0 0 13 16"
            className="cursor-pointer trash"
          >
            <path
              id="Shape"
              d="M3.5,16A2.5,2.5,0,0,1,1,13.5V4H2v9.5A1.5,1.5,0,0,0,3.5,15h6A1.5,1.5,0,0,0,11,13.5V4h1v9.5A2.5,2.5,0,0,1,9.5,16Zm5-2.5V5h1v8.5ZM6,13.5V5H7v8.5Zm-2.5,0V5h1v8.5ZM0,3V2H4V0H9V2h4V3Z"
            />
          </svg>
        )}
          <div className="position-relative d-flex justify-content-center align-items-center">
            {!imageLoaded?
            <div className="loader position-absolute">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>:""}
            <img
            onLoad={()=>setImageLoaded(true)}
            src={
              props.image || props.formatted_image || props.product_price?.product.formatted_image
            }
          />        
      </div>
        <span className="ml-4 name font-weight-regular">
          {props.name || props.product_price?.product.name}
        </span>
      </div>
      <div className="col-1 offset-1 product-row font-weight-regular p-lg-0">
        {props.frame.name}
      </div>
      <div className="col-1 offset-1 product-row font-weight-regular p-lg-0">
        {props.width} x {props.height} cm
      </div>
      <div className="col-1 offset-1 product-row quantity font-weight-regular p-lg-0">
        {props.material.value}
      </div>
      <div className="col-1 offset-1 product-row quantity font-weight-regular p-lg-0">
        {props.passpartout_size.value}cm
      </div>

      <div className=" col-1 product-row font-weight-regular p-lg-0 justify-content-end">
        <div className="font-weight-medium text-right price">
          {numberWithCommas(props.total_price)}
          <span className="currency font-family-lucida">€</span>
        </div>
      </div>
      <div className="col-12 separator"></div>
    </>
  );
};
export default DesktopProductRow;

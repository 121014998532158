import useInView from "react-cool-inview";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Artist = (props) => {
  const observable = useInView({
    unobserveOnEnter: true,
  });

  return (
    <Link
      to={`/artistas/${props.slug}`}
      className={`artist ${
        observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
      }`}
      ref={observable.observe}
    >
      <div className="img-container">
        <LazyLoad once offset={100}>
          <img src={props.formatted_image} />
        </LazyLoad>
      </div>
      <div className="text-center font-weight-medium name"> {props.name} </div>
    </Link>
  );
};
export default Artist;

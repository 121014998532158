import { useState } from "react";
import useInView from "react-cool-inview";
import Error from "./Error";

const Checkbox = (props) => {
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const _handleClick = (e) => {
    if (e.target.tagName.toLowerCase() != "a") {
      if (props.onCheck) props.onCheck();
    }
  };
  return (
    <div
      className={` flex-wrap d-flex checkbox-container align-items-center ${observable.inView ? "animate__animated animate__fadeIn" : "no-opacity"
        }`}
      ref={observable.observe}
    >
      <div className="d-flex flex-nowrap">
        <div
          className="checkbox d-flex justify-content-center align-items-center"
          onClick={props.onCheck}
        >
          {props.checked ? <div className="check"></div> : ""}
        </div>
        {
          props.html ?
            <label className="m-0 ml-2 cursor-pointer" onClick={_handleClick} dangerouslySetInnerHTML={{ __html: props.html }}>
            </label> :
            <label className="m-0 ml-2 cursor-pointer" onClick={_handleClick}>
              {props.label}
            </label>
        }

      </div>
      <div>
        {props.error &&
          Object.keys(props.error).length &&
          props.error.keyed[props.name] ? (
          <Error data={props.error.keyed[props.name]} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Checkbox;

import { Link } from "react-router-dom";
import { CART } from "../../api";
import Button from "../../components/Button";
import useAxios from "../../hooks/useAxios";
import { useParams } from "react-router";
import { useContext } from "react";
import DataContext from "../../contexts/DataContext";
import LayoutContext from "../../contexts/LayoutContext";
import { useEffect } from "react";
import { getCart, numberWithCommas } from "../../helper";
const Bill = (props) => {
    const [
        addToCartResponse,
        addToCartError,
        addToCartLoader,
        addToCartSetHookOptions,
        addToCartSnackbar,
        addToCartStatus,
    ] = useAxios();
    const commonData = useContext(DataContext);
    const params = useParams();
    const { isOverlayVisible, setIsOverlayVisible, setIsCartOpen } =
        useContext(LayoutContext);

    const renderFinalDimension = () => {
        if (props.canvasWidth)
            return ` (${props.totalWidth}
            x  ${props.totalHeight}  cm)`
        else
            return " 0 x 0 cm";
    }
    const renderPosterDimension = () => {
        if (props.data.product?.price[props.selectedDropdownIndexes.dimension])
            return ` ${props.canvasWidth}
        x  ${props.canvasHeight}  cm`
        else
            return " 0 x 0 cm";
    }
    const frameCost = () => {
        if (props.frame) {
            return parseFloat((paintingTypeCost() + backcoverCost() + parseFloat(((props.totalWidth * 2 + props.totalHeight * 2) * props.frame.price_per_cm).toFixed(2)) + materialCost() + passpartouCost()).toFixed(2));
        }
        return 0;
    }
    const passpartouCost = () => {
        if (props.data.passpartout_sizes[props.passpartoutIndex].value) {
            return parseFloat(((parseFloat(props.canvasWidth || 0) + props.data.passpartout_sizes[props.passpartoutIndex].value * 2) * (parseFloat(props.canvasHeight || 0) + props.data.passpartout_sizes[props.passpartoutIndex].value * 2) * props.selectedPassPartoutColor.price_per_cm2).toFixed(2))
        }
        return 0;
    }
    const materialCost = () => {
        if (props.material && props.frame)
            return parseFloat((props.totalWidth * props.totalHeight * props.material.price_per_cm2).toFixed(2))
        return 0;
    }
    const backcoverCost = () => {
        return parseFloat((props.totalWidth * props.totalHeight * props.data.wood_cover_price_per_cm2).toFixed(2))
    }
    const paintingTypeCost = () => {
        if (props.paintingTypeIndex >= 0 && props.custom === 1)
            return parseFloat(((props.canvasWidth || 0) * (props.canvasHeight || 0) * props.data.painting_types[props.paintingTypeIndex]?.price_per_cm2).toFixed(2))
        else
            return 0;
    }

    const _handleAddToCart = () => {
        if (props.canvasWidth && props.canvasHeight && props.frame && props.frame.id && props.material && props.material.id)
            addToCartSetHookOptions({
                url: CART,
                method: "post",
                data: {
                    product_slug: (props.custom == 1 || props.custom == 2) ? null : params.slug,
                    price_id: (props.custom == 1 || props.custom == 2) ? null : props.data.product.price.find(el => el.width == props.canvasWidth && el.height == props.canvasHeight).id,
                    passpartout_size_id: props.data.passpartout_sizes[props.passpartoutIndex].id,
                    passpartout_color_id: props.selectedPassPartoutColor.id,
                    frame_id: props.frame.id,
                    material_id: props.material.id,
                    width: props.totalWidth,
                    height: props.totalHeight,
                    canvas_width: props.canvasWidth,
                    canvas_height: props.canvasHeight,
                    image: props.custom == 1 ? props.image : null,
                    painting_type_id: props.custom == 1 ?( props.paintingTypeIndex?props.data.painting_types[props.paintingTypeIndex].id:null) : null
                },
            });
        else
            props.setSubmitClicked(true);


    }

    useEffect(() => {
        if (addToCartStatus == 200 && !addToCartLoader) {
            commonData.setCartItems([addToCartResponse, ...commonData.cartItems]);
            setIsCartOpen(true);
            props.setAddToCartError(null);
        }
        if(addToCartStatus==422){
            props.setAddToCartError(addToCartError.keyed);
        }
    }, [addToCartLoader])
    const total = parseFloat((parseFloat(frameCost()) + (props.data.product?.price[props.selectedDropdownIndexes.dimension]?.discounted_price || 0)).toFixed(2));
    const taxValue = parseFloat((total * (props.tax / 100)).toFixed(2));
    const totalAfterTax = (total + taxValue).toFixed(2);
    return <div className="bill">
        <div className="bill-container">
            <div className="mi-producto">{commonData.fixed.customization_fixed_titles['bill-my-product'].title}</div>
            <label>
                {commonData.fixed.customization_fixed_titles['final-dimension'].title}
                {numberWithCommas(renderFinalDimension())}
            </label>
        </div>
        {/* {props.custom == 1 && paintingTypeCost() ?
            <div className="bill-container">
                <div className="d-flex justify-content-between">
                    <span>{commonData.fixed.customization_fixed_titles['bill-printing-cost'].title}</span>
                    <span>{numberWithCommas(paintingTypeCost())}€</span>
                </div>
                <small>{props.data.painting_types[props.paintingTypeIndex]?.value}</small>
            </div> : ""} */}
        {props.custom == null ?
            <div className="bill-container">
                <div className="d-flex justify-content-between">
                    <span>{commonData.fixed.customization_fixed_titles['bill-poster'].title}</span>
                    <span>{numberWithCommas(props.data.product?.price[props.selectedDropdownIndexes.dimension]?.discounted_price || 0)}€</span>
                </div>
                <small>{commonData.fixed.customization_fixed_titles['bill-dimension'].title} {numberWithCommas(renderPosterDimension())}</small>
            </div> : ""}

        <div className="bill-container">
            <div className="d-flex justify-content-between">
                <span>{commonData.fixed.customization_fixed_titles['bill-frame'].title} </span>
                <span>{numberWithCommas(frameCost())}€</span>
            </div>
            <small>{props.frame?.name}</small>
        </div>
        {/* <div className="bill-container">
            <div className="d-flex justify-content-between">
                <span>{commonData.fixed.customization_fixed_titles['bill-passpartout'].title} {props.passpartouValue}cm</span>
                <span>{passpartouCost()}€</span>
            </div>
            <small>{commonData.fixed.customization_fixed_titles['bill-color'].title}  {props.selectedPassPartoutColor?.value}</small>
        </div>
        <div className="bill-container">
            <div className="d-flex justify-content-between">
                <span>
                    {commonData.fixed.customization_fixed_titles['bill-material'].title}
                </span>
                <span>{materialCost()}€</span>
            </div>
            <small>{props.material?.value}</small>
        </div> */}
        {/* <div className="bill-container">
            <div className="d-flex justify-content-between">
                <span>{commonData.fixed.customization_fixed_titles['bill-cover'].title}</span>
                <span>{backcoverCost()}€</span>
            </div>
        </div> */}
        <div className="bill-container subtotal">
            <div className="d-flex justify-content-between ">
                <span>{commonData.fixed.customization_fixed_titles['bill-subtotal'].title}:</span>
                <span>{numberWithCommas(total)}€</span>
            </div>
            <div className="d-flex justify-content-between">
                <small>
                    {commonData.fixed.customization_fixed_titles['bill-iva'].title} {props.tax}%
                </small>
                <small>{numberWithCommas(taxValue)}€</small>
            </div>
            {/* <small>
                {commonData.fixed.customization_fixed_titles['bill-shipping-costs-apart'].title}
            </small> */}
        </div>
        <div className="d-flex justify-content-between total">
            <div>{commonData.fixed.customization_fixed_titles['bill-total'].title}</div>
            <div>{numberWithCommas(totalAfterTax)}€</div>
        </div>
        <div>
            <Button
                text={commonData.fixed.customization_fixed_titles['bill-add-to-cart'].title}
                className="btn-outline-secondary w-100"
                loading={addToCartLoader}
                onClick={_handleAddToCart}
            />
            <div className="text-center ver-cesta">
                <Link to="/cesta">
                    {commonData.fixed.customization_fixed_titles['bill-ver-cesta'].title}
                </Link>
            </div>
        </div>
    </div>
}
export default Bill
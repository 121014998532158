import { useContext, useState } from "react";
import useInView from "react-cool-inview";
import DataContext from "../contexts/DataContext";

const WhoWeAreSection = (props) => {
  const commonData = useContext(DataContext);
  const [isReadMoreVisible, setIsReadMoreVisible] = useState();
  const observeTitle = useInView({
    unobserveOnEnter: true,
  });
  const { observe, unobserve, inView, scrollDirection, entry } = useInView({
    unobserveOnEnter: true,
  });
  const observable = useInView({
    unobserveOnEnter: true,
  });
  const _toggleReadMore = () => {
    setIsReadMoreVisible(!isReadMoreVisible);
  }
  return (
    <div id="who-we-are">
      <div
        ref={observeTitle.observe}
        className={`small-heading subheading text-center font-weight-medium ${observeTitle.inView
          ? "animate__animated animate__fadeIn"
          : "no-opacity"
          }`}
      >
        {props.bigTitle}
      </div>
      <h2
        className={`text-center ${observeTitle.inView
          ? "animate__animated animate__fadeIn"
          : "no-opacity"
          }`}
        ref={observeTitle.observe}
      >
        {props.smallTitle}
      </h2>
      <div className="row">

        <div
          className={`col-md-6 order-2 ${props.flipped ? "flipped order-md-2" : "order-md-1"
            } text ${inView ? "animate__animated animate__fadeIn" : ""}`}
          ref={observe}
        >
          <div
            className="short-description"
            dangerouslySetInnerHTML={{ __html: props.shortText }}
          ></div>

          {props.longText && !isReadMoreVisible ? (
            <div>
              <a
                onClick={_toggleReadMore}
                className="btn btn-outline-primary font-weight-regular mt-3"
              >
                {commonData.fixed.artists_titles['read-more'].title}
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`col-md-6 
          ${props.flipped ? "order-md-1 flipped" : "order-md-2"}
           order-1 ${observable.inView
              ? "animate__animated animate__fadeIn"
              : "no-opacity"
            }`}
          ref={observable.observe}
        >
          <img src={props.image} className="img-fluid w-100" />
        </div>
      </div>
      <div
        className={`mr-4 ml-4 mr-md-0 ml-md-0 long-description ${isReadMoreVisible ? "expand" : ""}`}
        dangerouslySetInnerHTML={{ __html: props.longText }}
      ></div>
      {props.button}
    </div>
  );
};
export default WhoWeAreSection;

import Breadcrumbs from "../components/Breadcrumbs";
import DataContext from "../contexts/DataContext.js";
import { useContext, useState, useEffect } from "react";
import DesktopProductRow from "../components/Cart/DesktopProductRow";
import ProductRow from "../components/Cart/ProductRow";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import useAxios from "../hooks/useAxios";
import { UPDATE_CART } from "../api";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getCart, numberWithCommas } from "../helper";
const Cart = (props) => {
  //the purpose of this duplication is to allow the temporary change to quantity which won't be committed to the backend and context unless user proceeds to checkout
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  const commonData = useContext(DataContext);
  const history = useHistory();
  useEffect(() => {
    if (status == 200) {
      history.push("/checkout");
    }
  }, [loader]);
  return (
    <div id="cart">
      <div className="custom-container mt-2">
        <Breadcrumbs
          data={[
            {
              name: commonData.fixed.breadcrumbs['home'].title,
              url: "/",
            },
            {
              name: commonData.fixed.breadcrumbs['cart'].title,
            },
          ]}
        />
      </div>
      <div className="custom-container">

        <div className="row">
          <div className="col-lg-2">
            <h3 className="mb-2 f-24 mi-cesta font-weight-medium">{commonData.fixed.cart_fixed_titles['my-cart'].title}</h3>
            <div>{commonData.cartItems.length} {commonData.fixed.cart_fixed_titles['products'].title}</div>
          </div>
          <div className="col-lg-10 cart-table d-none d-xl-block  ">
            <div className="row titles m-0">
              <div className="col-3 font-weight-bold p-lg-0">{commonData.fixed.cart_fixed_titles['product'].title}</div>
              <div className="offset-1 col-1 font-weight-bold p-lg-0">
                {commonData.fixed.cart_fixed_titles['frame'].title}
              </div>
              <div className="offset-1 col-1 font-weight-bold p-lg-0">
                {commonData.fixed.cart_fixed_titles['dimension'].title}
              </div>
              <div className="offset-1 col-1 font-weight-bold p-lg-0">
                {commonData.fixed.cart_fixed_titles['material'].title}
              </div>
              <div className="offset-1 col-1 font-weight-bold p-lg-0">
                {commonData.fixed.cart_fixed_titles['passpartout'].title}
              </div>
              {/* <div className=" col-1 font-weight-bold p-lg-0"></div> */}
              <div className=" col-1 font-weight-bold p-lg-0 text-right">
                {commonData.fixed.cart_fixed_titles['total'].title}
              </div>
              <div className="col-12 titles separator"></div>
              {commonData.cartItems.map((el) => (
                <DesktopProductRow
                  key={el.id}
                  {...el}
                />
              ))}
            </div>
            <div className="mt-4 row m-0 justify-content-between">
              <div>
                <span className="d-inline-block mr-2">
                  <img src="/images/paypal.svg" />
                </span>
                <span className="d-inline-block mr-2">
                  <img src="/images/visa.svg" />
                </span>
                <span className="d-inline-block mr-2">
                  <img src="/images/mastercard.svg" />
                </span>
              </div>
              <div className="">
                <div className="d-flex justify-content-between subtotal">
                  <span>{commonData.fixed.cart_fixed_titles['subtotal'].title}:</span>
                  <span className="font-weight-medium">
                    {numberWithCommas(commonData.cartItems.reduce((accumulator, currentValue) => {
                      let val = currentValue.total_price;

                      return accumulator + val;
                    }, 0))}
                   <span className="currency font-family-lucida">€</span>
                  </span>
                </div>{" "}
                {commonData.cartItems.length ? (
                  <Button
                    className="btn-outline-secondary font-size-regular"
                    to={`/checkout`}
                    text={commonData.fixed.cart_fixed_titles['finalize-purchase'].title}
                    loading={loader}
                  ></Button>
                ) : (
                  <Button
                    className="btn-outline-secondary font-family-regular  mt-4 f-16"
                    to={`/productos/${commonData.fixed.categories[0].slug}`}
                    text={commonData.fixed.cart_fixed_titles['go-to-shop'].title}
                  ></Button>
                )}
              </div>
            </div>
          </div>
          <div className="mobile-cart w-100 d-block d-xl-none">
            {commonData.cartItems.map((el) => (
              <ProductRow
                key={el.id}
                {...el}
              />
            ))}
            <div className=" row m-0 justify-content-between">
              <div className="mt-4">
                <span className="d-inline-block mr-2">
                  <img src="/images/paypal.svg" />
                </span>
                <span className="d-inline-block mr-2">
                  <img src="/images/visa.svg" />
                </span>
                <span className="d-inline-block mr-2">
                  <img src="/images/mastercard.svg" />
                </span>
              </div>
              <div className="mt-4 subtotal-container">
                <div className="border-0 d-flex justify-content-between subtotal">
                  <span>{commonData.fixed.cart_fixed_titles['subtotal'].title}:</span>
                  <span className="font-weight-medium">
                    {numberWithCommas(commonData.cartItems.reduce((accumulator, currentValue) => {
                      let val = currentValue.total_price;

                      return accumulator + val;
                    }, 0))}
                  </span>
                </div>{" "}
                <Button
                  className="btn-outline-secondary font-size-regular"
                  loading={loader}
                  to={`/checkout`}
                  text={commonData.fixed.cart_fixed_titles['finalize-purchase'].title}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;

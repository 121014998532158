import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { VERIFY } from "../api";
import useAxios from "../hooks/useAxios";

const VerifyEmail = () => {
  const { email, token } = useParams();
  const history = useHistory();
  const [response, error, loader, setHookOptions, snackbar, status] =
    useAxios();
  useEffect(() => {
    setHookOptions({
      url: VERIFY(email, token),
    });
  }, []);
  useEffect(() => {
    if (status) {
      if (status == 200) {
        setTimeout(() => {
          history.push("/accesso");
        }, 3000);
      }
    }
  }, [status]);
  return (
    <div className="d-flex w-100 h-100 justify-content-center">
      {!status
        ? "Verifying ..."
        : status == 200
        ? "Verification successful. You will be redirected shortly."
        : "Verification failed"}
    </div>
  );
};
export default VerifyEmail;

import { useContext } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import DataContext from "../contexts/DataContext";


const OrderSuccess=()=>{
    const commonData=useContext(DataContext);
    return <div className="custom-container text-center">
         <Breadcrumbs
                data={[
                  {
                    name: commonData.fixed.breadcrumbs['cart'].title,
                    url: "/cesta",
                  },
                  {
                    name: commonData.fixed.breadcrumbs['pay'].title,
                  },
                  {
                    name: commonData.fixed.breadcrumbs['confirmation'].title,
                  },
                ]}
            />
            <div className="mt-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="83" height="83" viewBox="0 0 83 83">
                    <g id="Group_1" data-name="Group 1" transform="translate(-378.5 -174.5)">
                        <circle id="Oval" cx="40" cy="40" r="40" transform="translate(380 176)" fill="none" stroke="#62c872" strokeMiterlimit="10" strokeWidth="3"/>
                        <path id="Path_Copy_2" data-name="Path Copy 2" d="M35.5,0,34.066,1.636,12.906,25.782l-8.763-8L2.549,16.327,0,19.964l1.593,1.455,10.2,9.309L13.225,32,14.5,30.545l22.435-25.6L38.4,3.309Z" transform="translate(401 200)" fill="#62c872"/>
                    </g>
                </svg>
                <h3 className="mt-4">{commonData.fixed.checkout_fixed_titles['order-success'].title}</h3>
            </div>
        </div>
}
export default OrderSuccess;
const TermsPolicy = (props) => {
  const getLastUpdatedAt = () => {
    let lastUpdated = new Date(
      Math.max(
        new Date(props.intro.updated_at),
        props.data.reduce((a, b) => {
          return new Date(a.updated_at) > new Date(b.updated_at)
            ? new Date(a.updated_at)
            : new Date(b.updated_at);
        })
      )
    );
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      lastUpdated
    );
    let mo = new Intl.DateTimeFormat("en", { month: "long" }).format(
      lastUpdated
    );
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      lastUpdated
    );
    return mo + " " + da + " " + ye;
  };
  return (
    <div id={props.id} className="terms-policy-container">
      <h2>{props.title}</h2>
      <div className="last-updated-on">
        {props.lastUpdated}: {getLastUpdatedAt()}
      </div>
      <div
        className="intro"
        dangerouslySetInnerHTML={{ __html: props.intro.text }}
      ></div>
      <h3 className="contents-title f-24">{props.contentsTitle}</h3>
      {props.data.map((el) => (
        <div className="contents">
          <span className="plus">+</span>
          <a href={`#${el.slug}`} className="single-title">
            {el.title}
          </a>
        </div>
      ))}
      <div className="separator"></div>
      {props.data.map((el, index) => (
        <div>
          <h3 className="f-24 single-content-title" id={el.slug}>
            {index + 1}. {el.title}
          </h3>
          <div
            className="single-content"
            dangerouslySetInnerHTML={{ __html: el.text }}
          />
        </div>
      ))}
    </div>
  );
};
export default TermsPolicy;

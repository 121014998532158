import { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataContext from "../../contexts/DataContext";
import ProductRow from "./ProductRow";
import { Scrollbar } from "react-scrollbars-custom";
import useAxios from "../../hooks/useAxios";
import { UPDATE_CART } from "../../api";
import Button from "../Button";
import { useCookies } from "react-cookie";
import { getCart, numberWithCommas } from "../../helper";

const MiniCart = (props) => {
  const commonData = useContext(DataContext);
  const history = useHistory();
  
  const [
    goToCheckoutResponse,
    goToCheckoutError,
    goToCheckoutLoader,
    goToCheckoutSetHookOptions,
    goToCheckoutSnackbar,
    goToCheckoutStatus,
  ] = useAxios();

  const [
    cartResponse,
    cartError,
    cartLoader,
    cartSetHookOptions,
    cartSnackbar,
    cartStatus,
  ] = useAxios();


  useEffect(() => {
    if (goToCheckoutStatus == 200) history.push("/checkout");
  }, [goToCheckoutLoader]);
  useEffect(() => {
    if (cartStatus == 200) history.push("/cesta");
  }, [cartLoader]);

  const _handleClose = () => {
    props.setIsCartOpen(false);
  };
  const _goToCart = () => {
    history.push("/cesta")
  };
  const _goToCheckout = () => {
    history.push("/checkout")

  };

  return (
    <div className={`p-4 mini-cart ${props.isOpen ? "active" : ""}`}>
      <header className="d-flex justify-content-between align-items-center">
        <div>{commonData.fixed.cart_fixed_titles['my-cart'].title}</div>
        <div>
          <img
            src="/images/close.svg"
            className="cursor-pointer"
            onClick={_handleClose}
          />
        </div>
      </header>
      <div className="products">
        <h3 className="f-24">{commonData.cartItems.length} {commonData.fixed.cart_fixed_titles['products'].title}</h3>
        <div className="products-container">
          <Scrollbar noScrollX>
            {commonData.cartItems.map((product, index) => {
              return (
                <ProductRow
                  key={product.id}
                  {...product}
                />
              );
            })}
          </Scrollbar>
        </div>
      </div>
      <div className="subtotal d-flex pb-4 pt-4 align-items-center justify-content-between">
        <div className="">{commonData.fixed.cart_fixed_titles['subtotal'].title}</div>
        <div className="price d-flex ">
          <div className="price">
            {numberWithCommas(commonData.cartItems.reduce((accumulator, currentValue) => {
              let val = currentValue.total_price;
              return accumulator + val;
            }, 0).toFixed(2))}
            <span className="font-family-lucida">€</span>
          </div>
        </div>
      </div>
      <div className="actions d-flex justify-content-between flex-wrap align-items-center pb-md-0 pb-5">
        <Button
          className="font-family-regular w-100"
          onClick={_goToCart}
          loading={cartLoader}
          text={"Ver Cesta"}
        ></Button>
        {commonData.cartItems.length ? (
          <Button
            className="btn-outline-secondary font-family-regular w-100 mt-4"
            onClick={_goToCheckout}
            loading={goToCheckoutLoader}
            text={"Tramitar el pedido"}
          ></Button>
        ) : (
          <Button
            className="btn-outline-secondary font-family-regular w-100 mt-4 f-16 pb-md-0 pb-3"
            to={`/productos/${commonData.fixed.categories[0].slug}`}
            text="Go to Shop"
          ></Button>
        )}
      </div>
    </div>
  );
};
export default MiniCart;

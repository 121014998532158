import Layout from "../components/Accounts/Layout";
import useAxios from "../hooks/useAxios";
import { useContext, useEffect } from "react";
import { GLOBAL_DATA, ORDER_HISTORY } from "../api"
import { useCookies } from "react-cookie";
import DataContext from "../contexts/DataContext";
import { numberWithCommas } from "../helper";
const OrderHistory = () => {
    const [response, error, loader, setHookOptions, snackbar, status] =
        useAxios();
    const [responseGlobal, errorGlobal, loaderGlobal, setHookOptionsGlobal, snackbarGlobal, statusGlobal] =
        useAxios();
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const commonData = useContext(DataContext);
    useEffect(() => {
        if (cookies.token)
            setHookOptions({
                url: ORDER_HISTORY,
                token: cookies.token
            })
    }, [cookies.token]);
    return <Layout active='order-history'>
        {status == 200 ?
            <>
            {response.orders.length?
            <>
                <div className="row table-head">
                    <div className="col-4">{commonData.fixed.account_titles['order-history-product'].title}</div>
                    <div className="col-3">{commonData.fixed.account_titles['order-history-condition'].title}</div>
                    <div className="col-3">{commonData.fixed.account_titles['order-history-date'].title}</div>
                    <div className="col-2 text-right">{commonData.fixed.account_titles['order-history-price'].title}</div>
                </div>
                {response.orders.map(el => el.order_items.map(el2 => {
                    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
                        new Date(el2.created_at)
                    );
                    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
                        new Date(el2.created_at)
                    );
                    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                        new Date(el2.created_at)
                    );
                    return <div className="row mt-3 product" key={el2.id}>
                        <div className="col-md-4 pl-0">
                            <div className="d-flex product-information align-items-md-center align-items-start">
                                <div className="img-container mr-4">
                                    <img src={el2.product?.formatted_image || el2.custom_image || el2.frame?.formatted_thumbnail} className="img-fluid " />
                                    <div className="text-center mt-2 d-md-none" style={{ color: el2.item_order_status?.color }}> {el2.item_order_status?.title}</div>
                                </div>
                                <div>
                                    <div className="product-name">
                                        {el2.custom_image ? commonData.fixed.account_titles['order-history-custom-image'].title : (el2.product_id ? el2.product.name : commonData.fixed.account_titles['order-history-frame'].title)}
                                        <div className="d-block d-md-none">
                                            {el2.price}
                                            <span className="font-family-lucida currency">
                                                €
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <label>{commonData.fixed.account_titles['order-history-frame'].title}:</label> {el2.frame_name}
                                    </div>
                                    <div>
                                        <label>{commonData.fixed.account_titles['order-history-passpartout'].title}:</label> {el.passpartout_size ?? 0} cm
                                    </div>
                                    <div>
                                        <label>{commonData.fixed.account_titles['order-history-material'].title}:</label> {el2.material}
                                    </div>
                                    <div>
                                        <label>{commonData.fixed.account_titles['order-history-dimension'].title}:</label> {el2.canvas_width}x{el2.canvas_height}cm
                                    </div>
                                    <div className="d-md-none">
                                        <label>{commonData.fixed.account_titles['order-history-date'].title}:</label> {da}.{mo}.{ye}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex d-none col-3 align-items-center" style={{ color: el2.item_order_status?.color }}> {el2.item_order_status?.title}</div>
                        <div className="d-md-flex d-none col-3 align-items-center">{da}.{mo}.{ye}</div>
                        <div className="d-md-flex d-none col-2 align-items-center text-right justify-content-end">
                            {numberWithCommas(el2.price)}
                            <span className="font-family-lucida currency">
                                €
                            </span>
                        </div>
                    </div>
                }
                ))}
                </>
                :<div className="text-center h-100 d-flex justify-content-center align-items-center"><h4>{commonData.fixed.account_titles['no-orders-yet'].title}</h4></div>
            }
            </> : <div className="d-flex align-items-center justify-content-center"> {loader}</div>
        }
    </Layout >
}
export default OrderHistory;